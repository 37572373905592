import React, { Component, useState } from 'react'
import { withRouter } from "react-router-dom";

import { Button, Modal, Upload, Select, Radio, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import UploadImgs from './uploadImgs';
import UploadImg from './uploadImg';
import request from '../../../../../../../../js/http';
import cookie from 'react-cookies';



let data = {
    hasSpec: 0,
    recomXq: 0,
    goodsProp: '精品',
    goodsProduct: {},
    productList: [
        { productDefault: 0 },
        // { productDefault: 0 },
    ],
    goodsParam: [
        {}
    ],
};

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productPrice: '',
            goodsProduct: {},
            data: {},
            previewVisible: false,
            previewImage: '',
            previewTitle: '66666',
            fileList: [],
            bannerList: [],
            goodsImg: [],
            goodsClassList: [],
            classId: '2',
            edit: '',
        }
    }
    componentDidMount() {
        this.props.callback(this.props.location.pathname)

        this.getClassId()
        // console.log(this.props);
        if (this.props.location.state) {
            let id = this.props.location.state.goodsId
            this.getGoodsDetail(id)
        }
    }

    getGoodsDetail(goodsId) {
        request("get", `/mall/goodsGoods/${goodsId}`, {}).then(res => {
            //console.log('根据商品id获取商品详情信息(商品修改页)=>', res);
            if (res.code === 200) {
                // console.log(res.data.bannerList);

                // let data ;
                data.goodsImg = res.data.goodsImg
                data.detailImgList = res.data.detailImgList
                data.bannerList = res.data.bannerList
                data.goodsName = res.data.goodsName
                data.goodsTitle = res.data.goodsTitle
                data.classId = res.data.classId
                data.recomXq = res.data.recomXq
                data.goodsProp = res.data.goodsProp
                if (res.data.productList && res.data.productList[0]) {
                    // console.log(res.data.goodsProduct.productPrice)
                    // data.productList[0].productWeight = res.data.goodsProduct.productWeight
                    // data.productList[0].productPrice = res.data.goodsProduct.productPrice
                    // data.productList[0].productUnit = res.data.goodsProduct.productUnit
                    // data.productList[0].productStock = res.data.goodsProduct.productStock
                    // data.productList[0].productId = res.data.goodsProduct.productId

                    this.setState({ goodsProduct: res.data.goodsProduct, data: res.data, classId: res.data.classId, edit: res.data.goodsProduct.batchId })
                    data.productList = res.data.productList
                    data.goodsProduct = res.data.productList[0]
                } else {
                    data.productList[0] = res.data.goodsProduct
                    data.productList[0].productDefault = 0
                    data.goodsProduct = data.productList[0]
                    let datas = res.data
                    datas.productList[0] = res.data.goodsProduct
                    datas.productList[0].productDefault = 0


                    this.setState({ goodsProduct: res.data.goodsProduct, data: datas, classId: res.data.classId, edit: res.data.goodsProduct.batchId })

                }
                // if (res.data.productList[0]) {
                //     // console.log(res.data.goodsProduct.productPrice)
                //     data.productList[0].productWeight = res.data.productList[0].productWeight
                //     data.productList[0].productPrice = res.data.productList[0].productPrice
                //     data.productList[0].productUnit = res.data.productList[0].productUnit
                //     data.productList[0].productStock = res.data.productList[0].productStock
                //     data.productList[0].productId = res.data.productList[0].productId
                // }
                ////显示已有的详情图组
                if (res.data.detailImgList) {
                    let newList = []
                    for (let i = 0; i < res.data.detailImgList.length; i++) {
                        if (res.data.detailImgList[i].imgurl) {

                            let a = { uid: i + i, url: res.data.detailImgList[i].imgurl, name: 'image.png', status: 'done' }
                            newList.push(a);
                        }
                        // console.log(a);
                        // console.log(newList);
                    }
                    // console.log(res.data.bannerList);
                    this.setState({ detailImgList: newList })

                }
                ////显示已有的轮播图组
                if (res.data.bannerList) {
                    let newList = []
                    for (let i = 0; i < res.data.bannerList.length; i++) {
                        if (res.data.bannerList[i].imgurl) {

                            let a = { uid: i, url: res.data.bannerList[i].imgurl ? res.data.bannerList[i].imgurl : '', name: 'image.png', status: 'done' }
                            newList.push(a);
                        }
                        // console.log(a);
                        // console.log(newList);
                        // console.log(res.data.bannerList);    
                    }

                    this.setState({ fileList: newList })
                }
                ////显示已有的商品列表图
                if (res.data.goodsImg) {
                    let newList = [{ uid: '-11', url: res.data.goodsImg, status: "done", name: 'image.png', }]
                    this.setState({ goodsImg: newList })
                    // console.log(res.data.goodsImg);
                    // console.log(newList);
                }
                if (res.data.goodsParam) {
                    data.goodsParam = JSON.parse(res.data.goodsParam)
                    // console.log(data);
                } else {
                    data.goodsParam = [{ paramsName: '', paramsValue: '' }]

                }
            } else {
                message.warning('暂无商品信息')
            }
        })


    }
    getImg(value) {
        // console.log(value, '收到了');
        data.goodsImg = value
        // console.log(data);
    }
    getImg2(value) {
        console.log(value, '收到了');
        data.fileList = value
        data.bannerList = value
        console.log(value);

        // console.log(data);
    }

    getImgs(value) {
        // console.log(value, '收到了');
        data.detailImgList = value
        // console.log(data);
    }

    getClassId() {
        request('post', '/mall/goodsClass/getAllClass', {
            shopId: cookie.load('shopId'),
            pageNum: "1",
            pageSize: '99'
        }).then(res => {
            //console.log('根据大分类获取小分类=>', res);
            if (res.code === 200) {
                this.setState({ goodsClassList: res.rows })
            }
        })
    }
    nameInput(e) {
        data.goodsName = e.target.value
    }

    titleInput(e) {
        data.goodsTitle = e.target.value
    }
    setWeight = e => {
        data.productList[0].productWeight = e.target.value
        data.goodsProduct.productWeight = e.target.value

    }
    setPrice = (e) => {
        data.productList[0].productPrice = e.target.value ? (e.target.value * 1) : ''
        data.goodsProduct.productPrice = e.target.value
    }
    // setPrices = (e) => {
    //     data.productList[1].productPrice = e.target.value
    // }

    setUnit = (e) => {
        data.productList[0].productUnit = e.target.value
        data.goodsProduct.productUnit = e.target.value
    }
    // setUnits = (e) => {
    //     data.productList[1].productUnit = e.target.value
    // }
    setStock = (e) => {
        data.productList[0].productStock = e.target.value
        data.goodsProduct.productStock = e.target.value
    }
    // setStocks = (e) => {
    //     data.productList[1].productStock = e.target.value
    // }
    goodsAdd = () => {
        // console.log(data);
    }
    toGoodsList = () => {
        window.location.replace('/index/cksp');

    }


    handleChange = ({ fileList }) => {
        this.setState({ fileList });
        if (fileList.length) {
            if (fileList[fileList.length - 1].status === 'done') {
                ////显示已有的详情图组
                if (data.detailImgList) {
                    let newList = []
                    for (let i = 0; i < data.detailImgList.length; i++) {
                        if (data.detailImgList[i].imgurl) {

                            let a = { uid: i + i, url: data.detailImgList[i].imgurl, name: 'image.png', status: 'done' }
                            newList.push(a);
                        }
                        // console.log(a);
                        // console.log(newList);
                    }
                    // console.log(res.data.bannerList);
                    this.setState({ detailImgList: newList })

                }
                ////显示已有的商品列表图
                if (data.goodsImg) {
                    let newList = [{ uid: '-11', url: data.goodsImg, status: "done", name: 'image.png', }]
                    this.setState({ goodsImg: newList })
                    // console.log(res.data.goodsImg);
                    // console.log(newList);
                }
                // console.log();
                let list = []
                fileList.map(item => {
                    let i = {}
                    i.imgurl = item.response ? item.response.data : item.url
                    list.push(i)
                    // console.log(list);
                    data.bannerList = list;
                    return item
                })
                // console.log(data.bannerList);
                // console.log(data);
            }
        }
    }

    render() {
        ///////商品上下架组件/////
        const App2 = () => {
            const setStatus = e => {
                // console.log(e.target.value);
                // let goodsLoading = e.target.value

                request('post', '/mall/goodsGoods/shelf', {
                    shopId: cookie.load("shpId"),
                    goodsId: this.props.location.state.goodsId,
                    goodsLoading: e.target.value.toString()
                }).then(res => {
                    //console.log('商品状态=>', res);
                    if (res.code === 200) {
                        message.success(res.msg)

                        // if (e.target.value === 1) {
                        //     message.success('商品状态修改成功')
                        // } else if (e.target.value === 2) {
                        //     message.success('商品下架成功')

                        // } else if (e.target.value === 0) {
                        //     message.success('商品状态修改成功')

                        // }   
                    } else {
                        message.warning(res.msg)
                    }
                    if (this.props.location.state) {
                        let id = this.props.location.state.goodsId
                        this.getGoodsDetail(id)
                    }
                })
            }
            let loading = true;
            switch (this.state.data.goodsLoading) {
                case 4:
                    loading = true
                    break;
                case 3:
                    loading = true
                    break;
                case 0:
                    loading = false
                    break
                case 1:
                    loading = false
                    break
                case 2:
                    loading = false
                    break
                default:
                    loading = true
                    break;
            }
            // console.log(loading);
            return (
                <Radio.Group name="radiogroup" defaultValue={this.state.data.goodsLoading} onChange={e => setStatus(e)}>
                    <Radio disabled value={0}>待编辑</Radio>
                    <Radio disabled={loading} value={1}>上架</Radio>
                    <Radio disabled={loading} value={2}>下架</Radio>
                    <Radio disabled value={3}>审核中</Radio>
                    <Radio disabled value={4}>已拒绝 {this.state.data.goodsLoading === 4 ? ' 拒绝理由 : ' + this.state.data.goodsReject : ''}</Radio>
                </Radio.Group>
            )
        };
        ///////溯源组件
        const SuYuan = () => {
            const SuYuanName = (e) => {
                data.goodsParam[0].paramsName = e.target.value
            }
            const SuYuanValue = (e) => {
                data.goodsParam[0].paramsValue = e.target.value
            }
            return (
                <>
                    <div className="sale-item" >
                        <span className="sale-item-title">名称 :</span>
                        <input className="sale-item-value" defaultValue={data.goodsParam[0] ? data.goodsParam[0].paramsName : ''} onBlur={SuYuanName} />
                        <span className="sale-item-title ml10">内容 :</span>
                        <input className="sale-item-value" defaultValue={data.goodsParam[0] ? data.goodsParam[0].paramsValue : ''} onBlur={SuYuanValue} />
                    </div>
                    {/* <div className="sale-item" >
                        <Button type="primary" ghost onClick={SuYuanSave}>保存</Button>
                        <Button className="ml10" type="primary" ghost onClick={SuYuanAdd}>添加</Button>
                    </div> */}

                </>
            )
        }
        //////////////单选组件
        const CheckOne = () => {
            const setDefult = (e) => {
                // console.log(e.target.value);

                data.productList[0].productDefault = e.target.value
                data.goodsProduct.productDefault = e.target.value
            }
            return (
                <Radio.Group name="radiogroup" disabled={this.state.edit ? true : false} defaultValue={data.productList[0].productDefault} onChange={setDefult}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            )
        };
        //////////////单选组件
        // const CheckMore = () => {

        //     const setDefult = (e) => {
        //         // console.log(e.target.value);
        //         data.productList[1].productDefault = e.target.value

        //     }
        //     return (
        //         <Radio.Group name="radiogroup" defaultValue={data.productList[1].productDefault} onChange={setDefult}>
        //             <Radio value={1}>是</Radio>
        //             <Radio value={0}>否</Radio>
        //         </Radio.Group>
        //     )
        // };

        ////商品分类组件///
        const ClassOption = () => {
            const { Option } = Select;
            const onChange = (value) => {
                //console.log(`selected ${value}`);
                data.classId = value
                this.setState({ classId: value })
            }

            // function onBlur() {
            //     console.log('blur');
            // }

            // function onFocus() {
            //     console.log('focus');
            // }

            // function onSearch(val) {
            //     console.log('search:', val);
            // }
            return (
                <Select
                    disabled={this.state.edit ? true : false}
                    showSearch
                    style={{ width: 200 }}
                    // placeholder={this.state.data.className}
                    defaultValue={this.state.classId ? Number(this.state.classId) : "未设置分类"}
                    optionFilterProp="children"
                    onChange={onChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {this.state.goodsClassList.map(item => {
                        return (<Option value={item.classId} key={item.classId.toString()}>{item.className}</Option>)
                    })}

                </Select>
            )
        }
        ////商品等级组件///
        const Level = () => {
            const { Option } = Select;
            const onChange = (value) => {
                // console.log(`selected ${value}`);
                switch (value) {
                    case '精品':
                        data.goodsProp = value;
                        // console.log(data);
                        break;
                    case '优质':
                        data.goodsProp = value;
                        break;
                    case '通货':
                        data.goodsProp = value;
                        break;
                    case '花皮':
                        data.goodsProp = value;

                        break;

                    default:
                        break;
                }
            }
            return (
                <Select style={{ width: 200 }} onChange={onChange} defaultValue={this.state.data.goodsProp ? this.state.data.goodsProp : '未设置等级'} disabled={this.state.edit ? true : false}>
                    <Option value={'精品'} >精品</Option>
                    <Option value={'优质'} >优质</Option>
                    <Option value={'通货'} >通货</Option>
                    <Option value={'花皮'} >花皮</Option>
                </Select>
            )
        }
        ///////////////删除商品对话框//////////
        const Apps = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
                let { goodsName, recomXq, goodsTitle, classId, goodsImg, hasSpec, goodsParam, bannerList, detailImgList, productList, goodsProduct, goodsProp } = data;
                goodsParam = JSON.stringify(goodsParam)
                // detailImgList = JSON.stringify(detailImgList)
                // bannerList = JSON.stringify(bannerList)
                let goodsId = this.props.location.state.goodsId
                //console.log(data);
                productList[0].productPrice = productList[0].productPrice * 1
                // goodsProduct[0].productPrice = goodsProduct[0].productPrice * 1
                console.log(productList);
                console.log(goodsProduct);

                request('post', '/mall/goodsGoods/edit', {
                    goodsId, goodsName, goodsTitle, classId, recomXq, goodsImg, hasSpec, goodsParam, bannerList, detailImgList, productList, goodsProduct, goodsProp, shopId: cookie.load('shopId')
                }).then(res => {
                    //console.log("修改商品信息=>", res);
                    if (res.code === 200) {
                        if (this.props.location.state) {
                            let id = this.props.location.state.goodsId
                            this.getGoodsDetail(id)
                        }
                        message.success('商品信息修改成功')

                        // window.location.replace('/index/cksp');

                        // setTimeout(() => {
                        //     this.props.history.goBack();
                        // }, 500);
                    } else {
                        message.warning('修改失败')
                    }
                })
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <Button onClick={showModal} className="w100" type="primary" >修改</Button>
                    <Modal className="modal-confirm"
                        title="提示" cancelText="取消"
                        okText="确认"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <p className="confirm-text">即将提交商品信息?</p>
                    </Modal>
                </>
            );
        };

        ////////////////////////////照片墙上传组件↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }
        //图片预览函数   ///
        const handlePreview = async file => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }

            this.setState({
                previewImage: file.url || file.preview,
                previewVisible: true,
                previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
            });
        };
        ///////关闭图片预览对话框状态
        const handleCancel = () => this.setState({ previewVisible: false });
        //////上传文件改变时的状态




        /// 组件所需要的变量
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        //////添加图片按钮
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>添加图片  </div>
            </div>
        );
        //////////////是否包邮组件
        const FreePast = () => {
            const setDefult = (e) => {
                // console.log(e.target.value);
                data.recomXq = e.target.value
                // console.log(data);
            }
            return (
                <Radio.Group name="radiogroup" defaultValue={data.recomXq} onChange={setDefult}>
                    <Radio value={0}>是</Radio>
                    <Radio value={1}>否</Radio>
                </Radio.Group>
            )
        };
        return (
            <div className="main-show order-list">
                <div className="table-header">
                    <h1 className="table-name" style={{ minWidth: 1100 }}>商品修改</h1>
                    <div className="table-operation">
                        {/* <Button >导出</Button> */}
                    </div>
                </div>
                <div className="pd20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">标题 :</span>
                        <input className="goodsinfo-text color-black " defaultValue={this.state.data.goodsName} onBlur={this.nameInput} />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品简介 :</span>
                        <textarea className="goodsinfo-text color-black" style={{ resize: 'none' }} title={this.state.data.goodsTitle} placeholder={this.state.data.goodsTitle} defaultValue={this.state.data.goodsTitle} onBlur={this.titleInput} />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品分类 :</span>
                        <ClassOption className="goodsinfo-text color-black " />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品等级 :</span>
                        <Level className="goodsinfo-text" />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="">商品上架状态 :</span>
                        <div className="ml50">
                            <App2 />
                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="" style={{ width: 91, display: "inline-block" }}>是否包邮 :</span>
                        <div className="ml50">
                            <FreePast />
                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">列表图 :</span>
                        <div className="goodsinfo-imgs-area" >
                            <UploadImg getImg={this.getImg} imgList={this.state.goodsImg} />

                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">轮播图组 :</span>
                        <div className="goodsinfo-imgs-area" >

                            {/* <Upload
                                action="https://shop.yingshanhongcn.com/clife-boot-api-app/api/cms/common/upload"
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={this.handleChange}
                            >
                                {fileList.length >= 4 ? null : uploadButton}
                            </Upload>
                            <Modal
                                visible={previewVisible}
                                title={previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                            >
                                <img alt="" style={{ width: '100%' }} src={previewImage} />
                            </Modal> */}
                            <UploadImgs getImgs={this.getImg2} detailImgList={fileList} />

                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">详情图组 :</span>
                        <div className="goodsinfo-imgs-area" >
                            <UploadImgs getImgs={this.getImgs} detailImgList={this.state.detailImgList} />
                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">零售 :</span>
                        <div className="goodsinfo-imgs-area" style={{ flexGrow: 1, alignItems: 'center', justifyContent: "flex-start" }} >
                            <div className="sale-item">
                                <span className="sale-item-title">单位净重 :</span>
                                {this.state.edit
                                    ?
                                    <span className="sale-item-value" style={{ display: 'inline-block' }} >{this.state.data.productList ? this.state.data.productList[0].productWeight : ''}</span>
                                    :
                                    <input className="sale-item-value" defaultValue={this.state.data.productList ? this.state.data.productList[0].productWeight : ''} onBlur={this.setWeight} />
                                }
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">单价(元) :</span>
                                <input className="sale-item-value" defaultValue={this.state.data.productList ? this.state.data.productList[0].productPrice : ''} onBlur={this.setPrice} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">单位(规格) :</span>
                                {this.state.edit
                                    ?
                                    <span className="sale-item-value" >{this.state.data.productList ? this.state.data.productList[0].productUnit : ''}</span>
                                    :
                                    <input className="sale-item-value" defaultValue={this.state.data.productList ? this.state.data.productList[0].productUnit : ''} onBlur={this.setUnit} />
                                }
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">库存量 :</span>
                                {this.state.edit
                                    ?
                                    <span className="sale-item-value" >{this.state.data.productList ? this.state.data.productList[0].productStock : ''}</span>
                                    :
                                    <input className="sale-item-value" defaultValue={this.state.data.productList ? this.state.data.productList[0].productStock : ''} onBlur={this.setStock} />
                                }
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">默认 :</span>
                                <CheckOne />
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">批量销售 :</span>
                        <div className="goodsinfo-imgs-area" style={{ flexGrow: 1, alignItems: 'center', justifyContent: "flex-start" }} >
                            <div className="sale-item" >
                                <span className="sale-item-title">单位净重 :</span>
                                <input className="sale-item-value" placeholder="请输入单位净重" onBlur={this.setLeast} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">单价 :</span>
                                <input className="sale-item-value" placeholder="设置商品单价" onBlur={this.setPrices} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">单位(规格) :</span>
                                <input className="sale-item-value" placeholder="添加商品规格" onBlur={this.setUnits} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">库存量 :</span>
                                <input className="sale-item-value" placeholder="填写库存量" onBlur={this.setStocks} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">默认 :</span>
                                <CheckMore />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品溯源 :</span>
                        <div className="goodsinfo-imgs-area" style={{ flexGrow: 1, alignItems: 'center', flexDirection: "column" }}>
                            <div style={{ width: '100%', display: "flex", padding: '10px' }}>
                                <SuYuan />
                                {/* <div className="sale-item" >
                                    <Button type="primary" ghost onClick={SuYuanSave}>保存</Button>
                                    <Button className="ml10" type="primary" ghost onClick={SuYuanAdd}>添加</Button>
                                </div> */}
                            </div>



                        </div>
                    </div>
                </div>
                <div className="pd20 df-jcc" >
                    <Button className="w100" onClick={this.toGoodsList} >返回</Button>
                    <Apps />
                </div>
            </div>
        )
    }
}
export default withRouter(Index)
