import React, { Component } from 'react'

// import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { Row, Col, Button, message, Badge } from 'antd';
import { UserOutlined, BellOutlined, PhoneOutlined, ExportOutlined, CloseOutlined,  } from '@ant-design/icons';
// import HomePage from './components/homepage'
// import OrderList from './components/pages/orders/order-list'
import cookie from 'react-cookies'
import MyRoute from './components/myroute'
import request from '../../js/http'

// import Touxiang from './touxiang.png'
import Logo from "./logo.png"
import Back from "./back.jpg"
import "./index.css"
import Nav from './components/nav'

let pathList
let data 
// let i = 0
export class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userInfo: {},
            logo: '',
            path: ['首页'],
            status: true,
        }
    }
    getMyRoutePath = (value) => {
        if (this.state.status) {
            data  = value
            let path = ''
            // console.log('home页接收路由页值', value);
            switch (value) {
                case '/index/ddlb':
                    path = [{ name: '订单列表', pathname: value }]
                    break;
                case '/index/ddlb/detail':
                    path = [{ name: '订单列表', pathname: '/index/ddlb' }]
                    break;
                case '/index/tklb':
                    path = [{ name: '退款申请', pathname: value }]
                    break;
                case '/index/pjgl':
                    path = [{ name: '评价管理', pathname: value }]
                    break;

                case '/index/cksp':
                    path = [{ name: '平台商品', pathname: value }]
                    break;
                case '/index/cksp/add':
                    path = [{ name: '平台商品', pathname: '/index/cksp' }, { name: '商品添加', pathname: '/index/cksp/add' }]
                    break;
                case '/index/cksp/edit':
                    path = [{ name: '平台商品', pathname: '/index/cksp' },]
                    break;
                case '/index/cksp/detail':
                    path = [{ name: '平台商品', pathname: '/index/cksp' },]
                    break;

                case '/index/ccsp':
                    path = [{ name: '仓储商品', pathname: value },]
                    break;

                case '/index/fh':
                    path = [{ name: '物流中心', pathname: value },]
                    break;

                case '/index/sjfx':
                    path = [{ name: '数据分析', pathname: value },]
                    break;

                case '/index/dpjy':
                    path = [{ name: '店铺交易', pathname: value },]
                    break;

                case '/index/zxkf':
                    path = [{ name: '客服管理', pathname: value },]
                    break;

                case '/index/dpxx':
                    path = [{ name: '店铺管理', pathname: value },]
                    break;

                case '/index/xtsz':
                    path = [{ name: '密码管理', pathname: value },]
                    break;





                default:
                    break;
            }
            // this.setState({ path,  })
            pathList = path
            // console.log(path);
        }
    }
    componentDidMount() {
        // console.log(this.props.location.pathname)
        this.confirmUser()
    }
    confirmUser() {

        if (cookie.load("shopId") && cookie.load('token')) {

            if (!cookie.load("userInfo")) {
                message.info('欢迎来到 颍山红 商家管理后台')

                request('get', '/mall/shopUser/getInfo', {
                }).then((res) => {
                  //console.log("获取商户信息接口=>", res, "+++++++++");
                    if (res.code === 200) {
                        let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);//一天
                        cookie.save('userInfo', res.data, { expires: inFifteenMinutes })
                        this.setState({ userInfo: res.data })
                        // this.getShopLogo()
                    }

                })
            } else {
                let userInfo = cookie.load('userInfo')
                this.setState({ userInfo })
            }
        } else {
            message.loading("未登录状态, 即将跳转至登录页...")
            // message.warning("您已登录,请勿重复登陆")
            setTimeout(() => {
                window.location.replace('/login');
            }, 2000)
        }
        this.getShopLogo()
    }
    getShopLogo() {
        if (cookie.load('shopId')) {

            let a = cookie.load('shopId')
            request('get', `/mall/shopShop/${a}`, {

            }).then(res => {
              //console.log('店铺logo=>', res);
                if (res.code === 200) {
                    this.setState({ logo: res.data.shopLogo, shopName: res.data.shopName })
                }
            })
        }
    }
    logout = () => {
      //console.log("移除");
        cookie.remove("token")
        cookie.remove("shopId")
        cookie.remove("userInfo")
        request('post', '/logout', {
        }).then((res) => {
          //console.log("退出登录接口=>", res, "+++++++++");
            if (res.code === 200) {
                // this.getShopLogo()
                setTimeout(() => {
                    message.loading('即将退出 "辣椒" 商家后台管理系统...')
                    setTimeout(() => {

                        window.location.replace('/login');
                    }, 2000);
                }, 500);
            }

        })

        // this.props.history.push('/login')
    }
    kf = () => {
        window.location.replace('/index/zxkf');
    }
    toOrder = () => {
        this.props.history.replace('/index/ddlb')

    }
    toShop = () => {
        window.location.replace('/index/dpxx');

    }
    toBack = value => {
        // console.log(value);
        this.props.history.push(value)
    }
    toHomepage = () => {
        this.props.history.push('/index/homepage')
    }
    back = () => {
        window.history.back(-1);
    }
    render() {
        const getName = () => {
            let name = ['小牛辣椒旗舰店', '小牛辣椒专卖店', '小牛辣椒自营店', '小牛辣椒专卖店', '小牛辣椒精品店', '小牛辣椒试营店', '小牛辣椒网红店']
            let num = Math.floor(Math.random() * 8)
            return name[num]
        }

       
        return (
            <div className="home">
                <div className="left-menu">
                    <div className="logo">
                        <img className="logo-img" src={Logo} alt="logo"></img>
                    </div>
                    <div className="menu-nav">
                        <Nav data={data} ></Nav>
                    </div>
                </div>
                <div className="main">
                    <div className="top">
                        <Row>
                            <Col span={6}>
                                <div className="header">
                                    <img className="header-img" alt="" src={this.state.logo?this.state.logo: 'http://img.hntyp.com/IMGFlpg5rz14jQs5YF6SYQh-qSEyK0T.webp'} />
                                </div>
                                <div className="user-info">
                                    {/* <div className="user-name">{this.state.userInfo.name}</div> */}
                                    <div className="user-login-time"> {this.state.shopName ? this.state.shopName : getName()}</div>
                                </div>
                            </Col>
                            <Col span={12}>
                                {/* <div className="userheader-middle">
                                    <SoundOutlined />
                                    <span className="userheader-notice-text">这是一条来自服务器的新的信息</span>
                                </div> */}
                            </Col>
                            <Col span={6} className="top-right">
                                <div className="userheader-tools">
                                    <Button shape="circle" onClick={this.toShop} title="我的" >
                                        <UserOutlined />
                                    </Button>
                                    <Badge count={0} overflowCount={9}>
                                        <Button shape="circle" onClick={this.toOrder} title="待处理数据">
                                            <BellOutlined />
                                        </Button>
                                    </Badge>
                                    <Button shape="circle" onClick={this.kf} title='客服'>
                                        <PhoneOutlined />
                                    </Button>
                                    <Button shape="circle" onClick={this.logout} title="退出登录" >
                                        <ExportOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="record" style={{ display: 'flex', alignItems: 'center', paddingLeft: 30, paddingTop: 6, }}>
                        {/* 面包屑 */}
                        {/* <Back className=" " /> */}
                        <img src={Back} style={{ height: 20, marginRight: 30 }} onClick={this.back} className="pointer" title="返回" alt="" />
                        <Button className="ml10 " size='' shape="round" icon={<CloseOutlined />} type={'primary'} onClick={this.toHomepage} >首页</Button>
                        {pathList ?
                            pathList.map(item => {
                                return <Button className="ml10 " size='' shape="round" icon={<CloseOutlined />} type={'primary'} onClick={e => this.toBack(item.pathname)} >{item.name}</Button>
                            }) : ''
                        }



                    </div>
                    <div className='content'>
                        <MyRoute callback={this.getMyRoutePath} />
                    </div>
                </div>
            </div>
        )
    }
}

export default index
