import React, { Component, useState } from 'react'
import { Table,  Space, Button, Modal } from 'antd';
// import { Link } from "react-router-dom";
import "./answer.css"

export default class Answer extends Component {
    componentDidMount() {
    }

    state = {
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
    };
    start = () => {
        this.setState({ loading: true });
        // ajax request after empty completing
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 1000);
    };
    onSelectChange = selectedRowKeys => {
      //console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    render() {
        ////////////对话框=>删除//////////↓↓↓↓↓↓↓↓↓↓↓
        
        const App = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <span onClick={showModal}>删除</span>
                    <Modal className="modal-confirm" title="提示" cancelText="取消" okText="确认" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p className="confirm-text">确定要删除吗?</p>
                    </Modal>
                </>
            );
        };
        ////////////删除 =>对话框 ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

        ////////////对话框=>QA添加//////////↓↓↓↓↓↓↓↓↓↓↓
        const QA = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <span onClick={showModal}>添加</span>
                    <Modal width={800} className="" title="添加" cancelText="取消"  okText="添加" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                       <div className="qa-add-box">
                           <h5 className="qa-add-item-title">数字标签：</h5>
                           <input className="qa-add-item-content" placeholder="5"/>
                       </div>
                       <div className="qa-add-box">
                           <h5 className="qa-add-item-title">问题描述：</h5>
                           <textarea className="qa-add-item-content" placeholder="请填写问题描述"/>
                       </div>
                       <div className="qa-add-box">
                           <h5 className="qa-add-item-title">回复：</h5>
                           <textarea className="qa-add-item-content" placeholder="请填写回复内容"/>
                       </div>

                    </Modal>
                </>
            );
        };
        ////////////QA添加 =>对话框 ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑


        let orderCancel = () => {
          //console.log("确定删除");
        }
        const data = [];
        for (let i = 0; i < 85; i++) {
            // let op;
            // if((i+1)%)
            data.push({
                key: i,
                order: `${i+1}`,
                question: "辣椒辣不辣",
                answer: `超级无敌辣${i+1}`,
                action: "发货"
            });
        }
        const columns = [
            {
                title: '序号',
                dataIndex: 'order',
            },
            {
                title: '问题',
                dataIndex: 'question',
            },
            {
                title: '回复',
                dataIndex: 'answer',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle" style={{ marginLeft: "0", textAlign: "right" }}>
                        {/* 详情 */}
                        {/* <span style={{ color: "#000", cursor: "pointer" }} onClick={orderCancel}><Details/></span> */}
                        {/* 删除----对话框 -----按钮*/}
                        <span style={{ color: "#000", cursor: "pointer" }} onClick={orderCancel}><App /></span>
                    </Space>
                ),
            },
        ];
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        // const hasSelected = selectedRowKeys.length > 0;
        return (
            <div  style={{padding:"0 20px 20px "}}>
                <Table rowSelection={rowSelection}
                    columns={columns} dataSource={data}
                />
                <div className="table-foot">
                    <div className="foot-left">
                        <Button className="table-export" ghost type="primary"><App/></Button>
                        <Button className="table-export" ghost  type="primary"><QA/></Button>
                    </div>
                    <div className="foot-right">
                        <span className="table-num">共{data.length}条记录</span>
                        <div className="to-page">
                            到第
                            <input type="" placeholder="1" />
                            页
                            <Button type="primary" className="jump">GO</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
