import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Menu } from 'antd';
import Shouye from './shouye.png'

import Dingdan from './dingdan.png'
import Shangpin from './shangpin.png'
import Wuliu from './wuliu.png'
// import Cuxiao from './cuxiao.png'
import Shuju from './shuju.png'
import Zijin from './zijin.png'
import Kefu from './kefu.png'
import Dianpu from './dianpu.png'
import Kehu from './kehu.png'
// import Cuohe from './cuohe.png'
// import Wode from './wode.png'
import Zhineng from './zhineng.png'
import Shezhi from './shezhi.png'
import './index.css'

const { SubMenu } = Menu;
const Icon = ({ src, className }) => {
  return <img src={src} className={className} alt="图片加载失败" />
}
export default class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      defaultSelectedKeys: [],
      defaultOpenKeys: [],
    }
  }
  componentDidMount() {
    let pathname = window.location.pathname.split('/')
    pathname = `/${pathname[1]}/${pathname[2]}`
    // console.log(pathname);
    switch (pathname) {
      case "/index/homepage":
        this.state.defaultSelectedKeys.push('1')
        break;
      case "/index/ddlb":
        this.state.defaultSelectedKeys.push('2');
        this.state.defaultOpenKeys.push("sub1")
        break;
      case "/index/tklb":
        this.state.defaultSelectedKeys.push('3');
        this.state.defaultOpenKeys.push("sub1")
        break;
      case "/index/thlb":
        this.state.defaultSelectedKeys.push('4');
        this.state.defaultOpenKeys.push("sub1")
        break;
      case "/index/pjgl":
        this.state.defaultSelectedKeys.push('5');
        this.state.defaultOpenKeys.push("sub1")
        break;
      case "/index/spfl":
        this.state.defaultSelectedKeys.push('6');
        this.state.defaultOpenKeys.push("sub2")
        break;
      case "/index/spgg":
        this.state.defaultSelectedKeys.push('7');
        this.state.defaultOpenKeys.push("sub2")
        break;
      case "/index/cksp":
        this.state.defaultSelectedKeys.push('8');
        this.state.defaultOpenKeys.push("sub2")
        break;
      case "/index/ptsp":
        this.state.defaultSelectedKeys.push('9');
        this.state.defaultOpenKeys.push("sub2")
        break;
      case "/index/fh":
        this.state.defaultSelectedKeys.push('10');
        this.state.defaultOpenKeys.push("sub3")
        break;
      case "/index/thdz":
        this.state.defaultSelectedKeys.push('11');
        this.state.defaultOpenKeys.push("sub3")
        break;
      case "/index/yhhd":
        this.state.defaultSelectedKeys.push('12');
        this.state.defaultOpenKeys.push("sub4")
        break;
      case "/index/yhlb":
        this.state.defaultSelectedKeys.push('13');
        this.state.defaultOpenKeys.push("sub4")
        break;
      case "/index/sjfx":
        this.state.defaultSelectedKeys.push('14');
        this.state.defaultOpenKeys.push("sub5")
        break;
      case "/index/dpjy":
        this.state.defaultSelectedKeys.push('15');
        this.state.defaultOpenKeys.push("sub6")
        break;
      case "/index/wdbzj":
        this.state.defaultSelectedKeys.push('16');
        this.state.defaultOpenKeys.push("sub6")
        break;
      case "/index/xxsz":
        this.state.defaultSelectedKeys.push('17');
        this.state.defaultOpenKeys.push("sub7")
        break;
      case "/index/kfgj":
        this.state.defaultSelectedKeys.push('18');
        this.state.defaultOpenKeys.push("sub7")
        break;
      case "/index/zxkf":
        this.state.defaultSelectedKeys.push('19');
        this.state.defaultOpenKeys.push("sub7")
        break;
      case "/index/dpxx":
        this.state.defaultSelectedKeys.push('20');
        this.state.defaultOpenKeys.push("sub8")
        break;
      case "/index/rzzl":
        this.state.defaultSelectedKeys.push('21');
        this.state.defaultOpenKeys.push("sub8")
        break;
      case "/index/khgl":
        this.state.defaultSelectedKeys.push('22');
        this.state.defaultOpenKeys.push("sub9")
        break;
      case "/index/wdcg":
        this.state.defaultSelectedKeys.push('23');
        this.state.defaultOpenKeys.push("sub10")
        break;
      case "/index/wdgy":
        this.state.defaultSelectedKeys.push('24');
        this.state.defaultOpenKeys.push("sub10")
        break;
      case "/index/lkcz":
        this.state.defaultSelectedKeys.push('25');
        this.state.defaultOpenKeys.push("sub10")
        break;
      case "/index/lkqz":
        this.state.defaultSelectedKeys.push('26');
        this.state.defaultOpenKeys.push("sub10")
        break;
      case "/index/hqlb":
        this.state.defaultSelectedKeys.push('27');
        this.state.defaultOpenKeys.push("sub11")
        break;
      case "/index/ccsp":
        this.state.defaultSelectedKeys.push('28');
        this.state.defaultOpenKeys.push("sub12")
        break;
      case "/index/xtsz":
        this.state.defaultSelectedKeys.push('29');
        this.state.defaultOpenKeys.push("sub13")
        break;
      default:
        break;
    }
  }

  
  render() {
    return (
      <div style={{ width: 200 }}>
        <Menu
          defaultSelectedKeys={this.state.defaultSelectedKeys ? this.state.defaultSelectedKeys : ['1']}
          defaultOpenKeys={this.state.defaultOpenKeys ? this.state.defaultOpenKeys : ['']}
          mode="inline"
          theme="dark"
          inlineCollapsed={this.state.collapsed}
        >
          <Menu.Item className="page-home" key="1" icon={<Icon src={Shouye} />}>
            <Link to="/index/shouye">首页</Link>
          </Menu.Item>
          <SubMenu key="sub1" icon={<Icon src={Dingdan} className="nav-icon" />} title="订单管理">
            <Menu.Item key="2"><Link to="/index/ddlb">订单管理</Link></Menu.Item>
            <Menu.Item key="3"><Link to="/index/tklb">退款申请</Link></Menu.Item>
            {/* <Menu.Item key="4" disabled><Link to="/index/thlb">退货列表</Link></Menu.Item> */}
            <Menu.Item key="5"><Link to="/index/pjgl">评价管理</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<Icon src={Shangpin} className="nav-icon" />} title="商品管理">
            {/* <Menu.Item key="6" disabled><Link to="/index/spfl">商品分类</Link></Menu.Item> */}
            {/* <Menu.Item key="7" disabled><Link to="/index/spgg">商品规格</Link></Menu.Item> */}
            <Menu.Item key="8" ><Link to="/index/cksp">平台商品</Link></Menu.Item>
            {/* <Menu.Item disabled key="9"><Link to="/index/ptsp">平台商品</Link></Menu.Item> */}
          </SubMenu>
          <SubMenu key="sub12" icon={<Icon src={Zhineng} className="nav-icon" />} title="智能仓储">
            <Menu.Item key="28"><Link to="/index/ccsp">仓储商品</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="sub3" icon={<Icon src={Wuliu} className="nav-icon" />} title="物流中心">
            <Menu.Item key="10"><Link to="/index/fh">发货</Link></Menu.Item>
            {/* <Menu.Item key="11" disabled><Link to="/index/thdz">退货地址管理</Link></Menu.Item> */}
          </SubMenu>
          {/* <SubMenu key="sub4" disabled icon={<Icon src={Cuxiao} className="nav-icon"/>} title="促销管理"> */}
          {/* <Menu.Item key="13"disabled ><Link to="/index/yhhd">优惠活动列表</Link></Menu.Item> */}
          {/* <Menu.Item key="12" disabled ><Link to="/index/yhlb">优惠列表</Link></Menu.Item> */}
          {/* </SubMenu> */}
          <SubMenu key="sub5" icon={<Icon src={Shuju} className="nav-icon" />} title="数据分析">
            <Menu.Item key="14"><Link to="/index/sjfx">流量分析</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="sub6" icon={<Icon src={Zijin} className="nav-icon" />} title="资金管理">
            <Menu.Item key="15"><Link to="/index/dpjy">店铺交易</Link></Menu.Item>
            {/* <Menu.Item disabled key="16"><Link to="/index/wdbzj">店铺账户</Link></Menu.Item> */}
          </SubMenu>
          {/* <SubMenu key="sub7" icon={<Icon src={Kefu} className="nav-icon" />} title="客服管理">
            <Menu.Item key="17"><Link to="/index/xxsz">消息设置*</Link></Menu.Item>
            <Menu.Item key="18"><Link to="/index/kfgj">客服工具*</Link></Menu.Item>
            <Menu.Item key="19"><Link to="/index/zxkf">在线客服</Link></Menu.Item>
          </SubMenu> */}
          <SubMenu key="sub8" icon={<Icon src={Dianpu} className="nav-icon" />} title="店铺管理">
            <Menu.Item key="20"><Link to="/index/dpxx">店铺信息</Link></Menu.Item>
            {/* <Menu.Item key="21"><Link to="/index/rzzl">店铺认证</Link></Menu.Item> */}
          </SubMenu>
          <SubMenu key="sub9" icon={<Icon src={Kehu} className="nav-icon" />} title="客户管理">
            <Menu.Item key="22"><Link to="/index/khgl">客户信息</Link></Menu.Item>
          </SubMenu>
          {/* <SubMenu disabled key="sub10" icon={<Icon src={Cuohe} className="nav-icon"/>} title="撮合管理">
            <Menu.Item  key="23"><Link to="/index/wdcg">我的采购</Link></Menu.Item>
            <Menu.Item  key="24"><Link to="/index/wdgy">我的供应</Link></Menu.Item>
            <Menu.Item  key="25"><Link to="/index/lkcz">冷库出租</Link></Menu.Item>
            <Menu.Item  key="26"><Link to="/index/lkqz">冷库求租</Link></Menu.Item>
          </SubMenu> */}
          {/* <SubMenu key="sub11" disabled icon={<Icon src={Wode} className="nav-icon"/>} title="我的行情"> */}
          {/* <Menu.Item key="27" disabled><Link to="/index/hqlb">行情信息</Link></Menu.Item> */}
          {/* </SubMenu> */}
          
          <SubMenu key="sub13" icon={<Icon src={Shezhi} className="nav-icon" />} title="系统设置">
            <Menu.Item key="29"><Link to="/index/xtsz">修改密码</Link></Menu.Item>
          </SubMenu>

        </Menu>
      </div>
    );
  }
}

// export default Nav;