import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Login from './views/login' //Login是路由组件
import Home from './views/home' //Home是路由组件
// import { message } from 'antd';
// import cookie from 'react-cookies';
// import MyNavLink from './components/MyNavLink'
// import "./js/rem"
import { ConfigProvider } from "antd";
import zhCN from 'antd/es/locale/zh_CN';

import "./App.css"


export default class App extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
   
    componentDidMount() {

    }
    render() {
        return (
            <div className="app" >
                <ConfigProvider locale={zhCN}>


                    {/* 注册路由 */}
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/index" component={Home} />
                        <Redirect to="/login" />
                    </Switch>
                </ConfigProvider>

            </div>
        )
    }
}
