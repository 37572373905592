import React, { Component } from 'react'
import { Radio, DatePicker, message, ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import { Link } from 'react-router-dom'
// import moment from 'moment';
import Charts from './charts'
import cookie from 'react-cookies';

import "./index.css"
import PayMoney from './img/pay-money.png'
import PayOrder from './img/pay-order.png'
import PayNumber from './img/pay-number.png'
import PayLook from './img/pay-look.png'

import Dfk from './img/dfk.png'
import Dxg from './img/dxg.png'
import Dfh from './img/dfh.png'
import Dsh from './img/dsh.png'
import Ywc from './img/ywc.png'
import Dth from './img/dth.png'
import Dtk from './img/dtk.png'

import Service from './img/service.png'
import User from './img/user.png'
import Money from './img/money.png'

import request from '../../../../js/http'
// moment.locale('zh-cn');
// const { RangePicker } = DatePicker;
// function onChange(dates, dateStrings) {
//     console.log('From: ', dates[0], ', to: ', dates[1]);
//     console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
// }

export default class HomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            size: "large",
            data: {},
            chartsData: {}
        }
    }
    componentDidMount() {
        request("post", "/mall/orderOrder/home/dataInfo", {
            shopId: cookie.load("shopId")
        }).then(res => {
          //console.log('首页上面数据展示接口=>', res);
            if (res.code === 200) {
                this.setState({ data: res.data })
            } else {
                message.warning("示例数据")
            }
        })
    }




    onChange = (date, dateString) => {
      //console.log(date, dateString, dateString[0], dateString[1]);

        request("post", "/mall/orderOrder/home/chartInfo", {
            shopId: cookie.load("shopId"),
            startAt: dateString[0],
            endAt: dateString[1]
        }).then(res => {
          //console.log("首页柱状图展示接口=>", res);
            if (res.code === 200) {
                this.setState({ chartsData: res.data })
                // console.log((this.state.chartsData));
            }
        })
    }
    render() {
        const todpjy = ()=>{

            this.props.history.replace('/index/dpjy')
        }
        const toOrderList=()=>{
            this.props.history.replace('/index/ddlb')
        }
        const handleSizeChange = e => {
          //console.log(e.target.value);
        };
        const { RangePicker } = DatePicker;
        return (
            <div className="main-page">
                <div className="cards">
                    <div className="cards-item card1" title="总金额/元">
                        <div className="card-name">
                            <img src={PayMoney} alt="图片加载失败" />
                            <span>支付金额</span>
                        </div>
                        <span className="number" >{this.state.data.orderAllPrice ? this.state.data.orderAllPrice : 0}</span>
                    </div>
                    <div className="cards-item card2" title="总订单数">
                        <div className="card-name">
                            <img src={PayOrder} alt="图片加载失败" />
                            <span>支付订单</span>
                        </div>
                        <span className="number" >{this.state.data.orderAllNum ? this.state.data.orderAllNum : 0}</span>
                    </div>
                    <div className="cards-item card3" >
                        <div className="card-name">
                            <img src={PayNumber} alt="图片加载失败" />
                            <span>商品数量</span>
                        </div>
                        <span className="number">{this.state.data.goodsAllNum ? this.state.data.goodsAllNum : 0}</span>
                    </div>
                    <div className="cards-item card4" title="访问人次">
                        <div className="card-name">
                            <img src={PayLook} alt="图片加载失败" />
                            <span>店铺访问</span>
                        </div>
                        <span className="number">{this.state.data.shopViewNum ? this.state.data.shopViewNum : 0}</span>
                    </div>
                </div>

                <div className="cards-area">
                    <div className="cards-todo-data  cards-left">
                        <div className="cards-title">
                            <span className="cards-title-text">
                                待处理数据
                            </span>
                            <span className="cards-title-order-all pointer" title="查看订单" >
                                <Link className="cards-title-order-all" to="/index/ddlb">查看全部订单 ＞</Link>

                            </span>
                        </div>
                        <div className="todo-items">
                            <div className="todo-item" onClick={toOrderList}>
                                <img src={Dxg} alt="加载失败" />
                                <span className="todo-item-name">待修改</span>
                                <span className="todo-item-number">{this.state.data.orderStatusNum0 ? this.state.data.orderStatusNum0 : 0}</span>
                            </div>
                            <div className="todo-item" onClick={toOrderList}>
                                <img src={Dfk} alt="加载失败" />
                                <span className="todo-item-name">待付款</span>
                                <span className="todo-item-number">{this.state.data.orderStatusNum1 ? this.state.data.orderStatusNum1 : 0}</span>
                            </div>
                            <div className="todo-item" onClick={toOrderList}>
                                <img src={Dfh} alt="加载失败" />
                                <span className="todo-item-name">待发货</span>
                                <span className="todo-item-number">{this.state.data.orderStatusNum2 ? this.state.data.orderStatusNum2 : 0}</span>
                            </div>
                            <div className="todo-item" onClick={toOrderList}>
                                <img src={Dsh} alt="加载失败" />
                                <span className="todo-item-name">待收货</span>
                                <span className="todo-item-number">{this.state.data.orderStatusNum3 ? this.state.data.orderStatusNum3 : 0}</span>
                            </div>
                            <div className="todo-item" onClick={toOrderList}>
                                <img src={Ywc} alt="加载失败" />
                                <span className="todo-item-name">已完成</span>
                                <span className="todo-item-number">{this.state.data.orderStatusNum7 ? this.state.data.orderStatusNum7 : 0}</span>
                            </div>
                            <div className="todo-item" onClick={toOrderList}>
                                <img src={Dth} alt="加载失败" />
                                <span className="todo-item-name">退款中</span>
                                <span className="todo-item-number">{this.state.data.orderStatusNum5 ? this.state.data.orderStatusNum5 : 0}</span>
                            </div>
                            <div className="todo-item" onClick={toOrderList}>
                                <img src={Dtk} alt="加载失败" />
                                <span className="todo-item-name">已退款</span>
                                <span className="todo-item-number">{this.state.data.orderStatusNum6 ? this.state.data.orderStatusNum6 : 0}</span>
                            </div>
                        </div>
                    </div>

                    <div className="cards-todo-data  cards-center">
                        <div className="cards-title">
                            <span className="cards-title-text">常用功能</span>
                            <span className="cards-title-order-all">
                            </span>
                        </div>
                        <div className="todo-items">
                            <Link to="/index/zxkf" className=" todo-item-center">
                                <img src={Service} alt="加载失败" />
                                <span className="todo-item-name">客服</span>
                            </Link>
                            <Link to="/index/khgl" className="todo-item-center ">
                                <img src={User} alt="加载失败" />
                                <span className="todo-item-name">用户管理</span>
                            </Link>
                            {/* <div className=" todo-item-center">
                            </div> */}
                            <span  className=" todo-item-center" onClick={todpjy}>
                                <img src={Money} alt="加载失败" />
                                <span className="todo-item-name">资金</span>
                            </span>
                        </div>
                    </div>

                    <div className="cards-todo-data  cards-right">
                        <div className="cards-title">
                            <span className="cards-title-text">
                                商品总览
                            </span>
                            <span className="cards-title-order-all">
                            </span>
                        </div>
                        <div className="todo-items">

                            <div className="todo-item-right">
                                <span className="todo-item-name name">待评价</span>
                                <span className="todo-item-name number">{this.state.data.orderStatusNum4 ? this.state.data.orderStatusNum4 : 0}</span>
                            </div>
                            <div className="vertical-line"></div>
                            <Link to="/index/ptsp" className="todo-item-right">
                                <span className="todo-item-name name">已上架</span>
                                <span className="todo-item-name number">{this.state.data.goodsUpNum ? this.state.data.goodsUpNum : 0}</span>
                            </Link>
                            <div className="vertical-line"></div>
                            <Link to="/index/ptsp" className="todo-item-right">
                                <span className="todo-item-name name">已下架</span>
                                <span className="todo-item-name number">{this.state.data.goodsDownNum ? this.state.data.goodsDownNum : 0}</span>
                            </Link>
                        </div>
                    </div>

                </div>
                {/* 销售统计 */}
                <div className="cards-todo-data  charts">
                    <div className="cards-title">
                        <span className="cards-title-text">销售统计</span>
                        <span className="date-select">
                            <Radio.Group defaultValue="c" onChange={handleSizeChange}>
                                <Radio.Button value="a">本日</Radio.Button>
                                <Radio.Button value="b">本周</Radio.Button>
                                <Radio.Button value="c">本月</Radio.Button>
                            </Radio.Group>
                            <ConfigProvider >
                                <RangePicker onChange={this.onChange} />
                            </ConfigProvider>
                        </span>
                    </div>
                    <div className="todo-chart">
                        {/* 统计图 */}
                        <Charts data={this.state.chartsData} />
                        {/* <Chartss/> */}

                    </div>
                </div>

            </div>
        )
    }
}
