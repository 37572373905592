import React, { Component, useState } from 'react'
import { withRouter } from "react-router-dom";

import { Select, Table, Modal, message, Button, Tag } from "antd";
// import { RedoOutlined, } from "@ant-design/icons"
import cookie from 'react-cookies';
import request from '../../../../../../js/http';

// import { Link, } from "react-router-dom";




class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            data: {},
            goodsList: [],
            goodsId: '',
            selectedRowKeys: [],
            defauletStatus: 'a1',
            defauletLevel: 'a2',
            goodsClassList: [],
        }
    }
    look = value => {
        message.loading('加载中...')
        let goodsId = value
        let localhost = window.location.href.split('index/ccsp')[0]
      //console.log(window.location.href);
      //console.log(localhost);
        request('post', '/image/videoInfo', {
            goodsId
        }).then(res => {
          //console.log('直播摄像头接口=>', res);
            if (res.code === 0) {
                let url = res.data.url
                // window.open(`${localhost}video/1.html?id=${'99'}`);
                window.open(`http://zhibo.yingshanhongcn.com/?id=${url}`)

                // console.log('http://127.0.0.1:5500/video/1.html'+"?id=99");
                // // window.open('http://127.0.0.1:5500/video/1.html')
                // window.open(`${localhost}/video/1.html?id=${url}`)
            }
        })
    }
    componentDidMount() {
        this.getClassId()
        // var data = [
        //     {
        //         name: '1',
        //         time: '2019-04-26 10:53:19'
        //     },
        //     {
        //         name: '2',
        //         time: '2019-04-26 10:51:19'
        //     },
        //     {
        //         name: '3',
        //         time: '2019-04-26 11:04:32'
        //     },
        //     {
        //         name: '4',
        //         time: '2019-04-26 11:05:32'
        //     }
        // ]
        // data.sort(function (a, b) {
        //     return a.time < b.time ? 1 : -1
        // });
        this.props.callback(this.props.location.pathname)
        this.getData(1, 999)
    }
    getClassId() {
        request('post', '/mall/goodsClass/getAllClass', {
            shopId: cookie.load('shopId'),
            pageNum: "1",
            pageSize: '99'
        }).then(res => {
          //console.log('根据大分类获取小分类=>', res);
            if (res.code === 200) {
                this.setState({ goodsClassList: res.rows })
            }
        })
    }
    getData = (pageNum, pageSize) => {
        this.setState({ loading: true })
        let shopId = cookie.load('shopId')
        if (shopId && pageNum && pageSize) {
            request('post', "/mall/goodsGoods/queryStoreList", {
                shopId, pageNum, pageSize
            }).then(res => {
              //console.log('仓库辣椒商品列表=>', res);
                if (res.code === 200) {
                    let data = res.data

                    data.list = data.list.sort(function (a, b) {
                        return a.createAt < b.createAt ? 1 : -1
                    })
                    let goodsList = res.data.list
                    goodsList = goodsList.sort(function (a, b) {
                        return a.createAt < b.createAt ? 1 : -1
                    })

                    this.setState({ loading: false, data, goodsList })
                }

            })
        }
    }
    render() {

        ///////////////取消订单对话框//////////
        // const App = () => {
        //     const [isModalVisible, setIsModalVisible] = useState(false);
        //     const showModal = () => {
        //         setIsModalVisible(true);
        //     };
        //     const handleOk = () => {
        //         setIsModalVisible(false);
        //     };
        //     const handleCancel = () => {
        //         setIsModalVisible(false);
        //     };
        //     return (
        //         <>
        //             <span onClick={showModal}>删除</span>
        //             <Modal className="modal-confirm"
        //                 title="提示" cancelText="取消"
        //                 okText="确认"
        //                 visible={isModalVisible}
        //                 onOk={handleOk}
        //                 onCancel={handleCancel}
        //             >
        //                 <p className="confirm-text">确定要删除吗?</p>
        //             </Modal>
        //         </>
        //     );
        // };
        ////商品分类组件///
        const ClassOption = () => {
            const { Option } = Select;
            const selectClass = (id) => {
                let audit = this.state.data.list.filter(item => {
                    return item.classId === id.toString()
                })
                this.setState({ goodsList: audit, defaultClassId: id })
            }
            const onChange = (value) => {
                // console.log(`selected ${value}`);
                if (value === 'all1') {
                    this.setState({ goodsList: this.state.data.list, defaultClassId: 'all1' })
                } else {
                    selectClass(value)
                }
            }

            // function onBlur() {
            //     console.log('blur');
            // }

            // function onFocus() {
            //     console.log('focus');
            // }

            // function onSearch(val) {
            //     console.log('search:', val);
            // }
            return (
                <Select
                    showSearch
                    style={{ width: 150 }}
                    placeholder={'选择分类'}
                    defaultValue={this.state.defaultClassId}
                    optionFilterProp="children"
                    onChange={onChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value='all1'>全部</Option>
                    {
                        this.state.goodsClassList.map(item => {
                            return (<Option value={item.classId} key={item.classId.toString()}>{item.className}</Option>)
                        })}

                </Select>
            )
        }
        ///////////////查看商品溯源对话框//////////
        const SuYuan = (text) => {

            let goodsParam
            let key = 1
            if (text.text) {
                let list = text.text.split(',')
                for (let i = 0; i < list.length; i++) {
                    if ((i + 1) % 2 === 0) {
                        list[i] = list[i].replace('paramsName', 'paramsValue')
                    }
                }
                goodsParam = JSON.parse(list.join(','))
            }
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <span className="link pointer" onClick={showModal}>查看</span>
                    <Modal
                        title="溯源信息"
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        footer={false}
                    >
                        <div style={{ width: '100%', height: 300, fontSize: 14, color: "#000", }} >
                            <h2 style={{ padding: "0 20px", fontWeight: 400, fontSize: 16, color: "#000", }}>记录</h2>
                            {
                                goodsParam ?
                                    goodsParam.map(item => {
                                        item.key = key
                                        key++
                                        return <p style={{ paddingLeft: 40, marginBottom: 5 }} key={item.key}><span style={{ width: 60, display: "inline-block" }}>{item.paramsName}  :</span><span>{item.paramsValue}</span></p>
                                    })
                                    :
                                    <p style={{ paddingLeft: 40, marginBottom: 5 }}><span style={{ width: 60, display: "inline-block" }}>{''}</span><span>{''}</span></p>
                            }
                        </div>
                    </Modal>
                </>
            );
        };
        ///////////////时间格式转换函数//////////
        function newTime(ordTime) {
            var date = new Date(ordTime);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = date.getDate() + ' ';
            let h = date.getHours() + ':';
            let m = date.getMinutes() + ':';
            let s = date.getSeconds();
            return Y + M + D + h + m + s;
        }
        /////表格 字段///
        const columns = [
            // { title: '商品ID', dataIndex: 'goodsId', key: 'goodsId', width: 250 },
            {
                title: '状态', key: 'goodsLoading', dataIndex: 'goodsLoading', render: (text, record) => {
                    return (
                        <>
                            {
                                record.goodsLoading === 0 ? <Tag color="#f50">持仓</Tag> : <>{
                                    record.goodsLoading === 1 ? <Tag color="#87d068">上架</Tag> : <Tag color="#2db7f5">下架</Tag>}</>
                            }
                        </>
                    )
                },
            },
            { title: '商品名称', dataIndex: 'goodsName', key: 'goodsName', },
            {
                title: '创建时间', dataIndex: 'createAt', key: 'createAt', width: 250, render(text) {
                    return text ? newTime(text) : ''
                }
            },
            {
                title: '溯源', dataIndex: 'goodsParam', key: 'goodsParam', render: (text, record) => {
                    return <SuYuan text={text} />
                }
            },
            {
                title: '实时监控', dataIndex: 'goodsId', key: 'GoodsId', render: (text, record) => {
                    return <span className="link pointer" onClick={value => this.look(text)} >查看</span>
                }
            },
            { title: '种类 ', dataIndex: 'className', key: 'className', },
            { title: '等级', dataIndex: 'goodsProp', key: 'goodsProp', },
            { title: '单位(规格)', dataIndex: 'goodsUnit', key: 'goodsUnit', },
            { title: '单位净重', dataIndex: 'productWeight', key: 'productWeight', },
            { title: '销量', dataIndex: 'goodsSaleNum', key: 'goodsSaleNum', },
            { title: '库存', dataIndex: 'productStock', key: 'productStock', },
            { title: '仓库库位', dataIndex: 'storeName', key: 'storeName', },
            

            // {
            //     title: '操作', dataIndex: 'goodsId', key: 'action', render: (text, record) => (
            //         <Space size="middle">
            //             {/* <Button onClick={(e) => (this.passIdToView(record.goodsId))} ><Link to={{ pathname: "/index/ptsp/detail", state: { goodsList: this.state.goodsList, goodsId: record.goodsId } }}>查看</Link></Button> */}
            //             <Link to="/index/ptsp/edit">编辑</Link>
            //         </Space>
            //     ),
            // },
        ];
        //////

        ///表格   数据//////
        let newList
        if (this.state.goodsList) {
            // console.log(this.state.data);
            let key = 0
            newList = this.state.goodsList.map(item => {
                item.key = key;
                // item.crateAt = newTime(item.cratAt)
                key++;
                return item
            })

        }
        const data = newList

        /////表格勾选
        const onSelectChange = (selectedRowKeys) => {
            // console.log(selectedRowKeys);
            this.setState({ selectedRowKeys });

        }
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };


        const { Option } = Select
        const selectStatus = (value) => {
            let list = this.state.data.list.filter(item => {
                return item.goodsLoading === value
            })
            this.setState({ defauletStatus: value, goodsList: list })
        }
        const selectLevel = (value) => {
            let list = this.state.data.list.filter(item => {
                return item.goodsProp === value
            })
            this.setState({ defauletLevel: value, goodsList: list })

        }
        const select = value => {
            // console.log(value);
            switch (value) {
                case 'a1':
                    this.setState({ defauletStatus: value, goodsList: this.state.data.list })
                    break;
                case 0:
                    selectStatus(value)
                    break;
                case 1:
                    selectStatus(value)
                    break;
                case 2:
                    selectStatus(value)
                    break;
                case 'a2':
                    this.setState({ defauletLevel: value, goodsList: this.state.data.list })
                    break;
                case '精品':
                    selectLevel(value)
                    break;
                case '优质':
                    selectLevel(value)
                    break;
                case '通货':
                    selectLevel(value)
                    break;
                case '花皮':
                    selectLevel(value)
                    break;

                default:
                    break;
            }
        }
        return (
            <div className="main-show order-list">
                <div className="table-header">
                    <h1 className="table-name">我的仓库</h1>
                    {/* <div className="table-operation">
                        <div className="order-num">
                            <span className="order-num-text">订单编号 :</span>
                            <input className="order-num-num" />
                        </div>
                        <div className="order-state">
                            <span >订单状态 :</span>
                            <Select defaultValue="a" style={{ width: 100, height: 35 }} onChange={handleChange}>
                                <Option value="a">全部</Option>
                                <Option value="g">待商议</Option>
                                <Option value="b">待付款</Option>
                                <Option value="c">代发货</Option>
                                <Option value="d">待收货</Option>
                                <Option value="e">待评价</Option>
                                <Option value="f">已完成</Option>
                            </Select>
                        </div>
                    </div> */}
                </div>
                <div className="table-header">
                    {/* <h1 className="table-name">仓库列表</h1> */}
                    <div className="table-operation">
                        {/* <div className="order-num">
                            <span className="order-num-text">退款编号 :</span>
                            <input className="order-num-num" />
                        </div> */}
                        <div className="order-num">
                            <span >状态 :</span>
                            <Select defaultValue={this.state.defauletStatus} style={{ width: 120, height: 35 }} onChange={select}>
                                <Option value="a1">全部</Option>
                                <Option value={0}>上架</Option>
                                <Option value={1}>下架</Option>
                                <Option value={2}>待上架</Option>
                            </Select>
                        </div>
                        <div className="order-state order-num">
                            <span >等级 :</span>
                            <Select defaultValue={this.state.defauletLevel} style={{ width: 120, height: 35 }} onChange={select}>
                                <Option value="a2">全部</Option>
                                <Option value="精品">精品</Option>
                                <Option value="优质">优质</Option>
                                <Option value="通货">通货</Option>
                                <Option value="花皮">花皮</Option>

                            </Select>
                        </div>
                        <div className="order-state order-num " >
                            <span >种类 :</span>
                            <ClassOption />
                        </div>
                    </div>
                    <div className="">
                    </div>
                </div>
                <div className="table-content">
                    <Table columns={columns}
                        dataSource={data}
                        loading={this.state.loading}
                        // pagination={false}
                        pagination={{
                            showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                            // total: data.length,

                            showTotal: total => `共 ${total} 条记录`,
                            defaultCurrent: 1,
                            showSizeChanger: false
                        }}
                        rowSelection={rowSelection}
                    />
                    <div className="table-foot">
                        <div className="foot-left">
                            {/* <Button className="table-import" type="text"><App /></Button> */}
                            {/* <Button className="table-export" ghost type="primary">导出</Button> */}
                        </div>
                        <div className="foot-right">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Index)
