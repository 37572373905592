import React, { Component } from 'react'
import "./index.scss";
import {
    Modal, Button, Pagination,
} from "antd";
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopGuige: [
                {
                    checkOne: false,
                    name: "精品1",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品2",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品3",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品4",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品5",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品6",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品7",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品8",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品9",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品1",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品2",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品3",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品4",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品5",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品6",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品7",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品8",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品9",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品1",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品2",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品3",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品4",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品5",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品6",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品7",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品8",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
                {
                    checkOne: false,
                    name: "精品9",
                    setTime: '2020-10-10  12:00:00',
                    xiugaitime: '2020-10-10  12:00:00',
                },
            ],
            isModalVisible: false,
            pageNmb: 1,
            indexList: [],//当前渲染的页面数据
            checkAll: false,
            minValue: 0,
            maxValue: 10,
        }
    }

    componentWillMount() {
        this.setState({ indexList: this.state.shopGuige.slice(this.state.minValue, this.state.maxValue) })
    }
    //添加
    showModal = () => {
        this.setState({
            isModalVisible: true,
        });
    };

    handleOk = () => {
        this.setState({
            isModalVisible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
        });
    };

    //单选
    checkOne = (e, i) => {
        const arr = this.state.indexList;
      //console.log(arr);
        arr[i].checkOne = !arr[i].checkOne;
        let arr1 = arr.filter((item) => item.checkOne === true);
      //console.log(arr[i].checkOne);
      //console.log(arr1);
        if (arr1.length === arr.length) {
            this.setState({
                indexList: arr,
                checkAll: true,
            });
        } else {
            this.setState({
                indexList: arr,
                checkAll: false,
            });
        }
      //console.log(this.state.pageNmb);
    };

    //全选
    quan() {
        let flag = this.state.checkAll;
        let arr = this.state.indexList;
      //console.log(arr);
        flag = !flag;
        if (flag === true) {
            arr.map((item) => {
                item.checkOne = true;
                return item
            });
        } else {
            arr.map((item) => {
                item.checkOne = false;
                return item

            });
        }

        this.setState({
            checkAll: flag,
            indexList: arr,
        }, function () {
            this.setState({ indexList: this.state.shopGuige.slice(this.state.minValue, this.state.maxValue) })
        });
    }

    //删除
    del = (index) => {
        const { shopGuige } = this.state
        const guige = shopGuige.filter((item, indey) => index !== indey)
        const indexList = this.state.indexList
      //console.log(indexList);
        guige.map(item => {
            if (item.checkOne === true) {
                this.setState({ checkAll: true })
            } else {
                this.setState({ checkAll: false })
            }
            return item

        })
        if (guige.length === 0) {
            this.setState({ checkAll: false })
        }
        if (indexList === 0) {
            this.setState({ checkAll: false })
        }
        this.setState({
            shopGuige: guige,
            indexList
        }, function () {
            this.setState({ indexList: this.state.shopGuige.slice(this.state.minValue, this.state.maxValue) })
            indexList.map(item => {
                if (item.checkOne === true) {
                    this.setState({ checkAll: true })
                } else {
                    this.setState({ checkAll: false })
                }
                return item

            })
        })
    }

    //批量删除
    delAll = () => {
        let guige = [...this.state.shopGuige];
      //console.log(guige);
        let del = guige.filter(i => {
            return !i.checkOne
        });
        this.setState({
            shopGuige: del
        }, function () {
            this.setState({ indexList: this.state.shopGuige.slice(this.state.minValue, this.state.maxValue) })
            this.state.indexList.map(item => {
                if (item.checkOne === true) {
                    this.setState({
                        checkAll: true
                    })
                } else {
                    this.setState({
                        checkAll: false
                    })
                }
                return item

            })
            if (this.state.indexList.length === 0) {
                this.setState({
                    checkAll: false
                })
            }
        })
    }



    //分页
    onChange = (pageNumber) => {
        if (pageNumber <= 1) {
            this.setState({
                minValue: 0,
                maxValue: 10
            });
        } else {
            this.setState({
                minValue: (pageNumber - 1) * 10,
                maxValue: (pageNumber - 1) * 10 + 10
            });
        }
        this.setState({
            pageNmb: pageNumber
        }, function () {
            this.setState({ indexList: this.state.shopGuige.slice(this.state.minValue, this.state.maxValue) })
            this.state.indexList.map(item => {
                if (item.checkOne === true) {
                    this.setState({
                        checkAll: true
                    })
                } else {
                    this.setState({
                        checkAll: false
                    })
                }
                return item

            })
        })
    }

    render() {
        // const dateFormat = 'YYYY-MM-DD'
        return (
            <div>
                <div className="shopGuiGe">
                    <div className="shopGuiGeTop">
                        <div className="shopGuiGeTopLf">
                            <span className="unitName">单位名称</span>
                            <input className="unitSearch" type="text" placeholder="请输入名称" />
                        </div>
                        <div className="shopGuiGeTopRg">
                            <Button type="primary" onClick={this.showModal}>
                                添加
                            </Button>
                            <Modal title="添加"
                                className="shopGuiGeModal"
                                visible={this.state.isModalVisible}
                                onOk={this.handleOk} onCancel={this.handleCancel}
                                okText="确认"
                                cancelText="取消"
                            >
                                <div className="ModalTop">
                                    <span className="guigeSize">规格单位:</span>
                                    <input type="text" className="guigejieshao" placeholder="请输入文字介绍" />
                                </div>
                                <div className="specificationK">
                                    <div className="specificationKTop">
                                        <div className="specificationKName">规格名称</div>
                                        <div className="specificationKName">规格值</div>
                                        <div className="specificationKName">操作</div>
                                    </div>
                                    <div className="specificationKList">
                                        <div className="specificationKItem">
                                            <input type="text" />
                                        </div>
                                        <div className="specificationKItem">
                                            <input type="text" />
                                        </div>
                                        <div className="specificationKcz">
                                            <div className="caozuo">
                                                <div className="czfu">↑</div>
                                                <div className="czfu">↓</div>
                                                <div className="czfu">X</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="specificationKList">
                                        <div className="specificationKItem">
                                            <input type="text" />
                                        </div>
                                        <div className="specificationKItem">
                                            <input type="text" />
                                        </div>
                                        <div className="specificationKcz">
                                            <div className="caozuo">
                                                <div className="czfu">↑</div>
                                                <div className="czfu">↓</div>
                                                <div className="czfu">X</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="specificationKList">
                                        <div className="specificationKItem">
                                            <input type="text" />
                                        </div>
                                        <div className="specificationKItem">
                                            <input type="text" />
                                        </div>
                                        <div className="specificationKcz">
                                            <div className="caozuo">
                                                <div className="czfu">↑</div>
                                                <div className="czfu">↓</div>
                                                <div className="czfu">X</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="specificationKList">
                                        <div className="specificationKItem">
                                            <input type="text" />
                                        </div>
                                        <div className="specificationKItem">
                                            <input type="text" />
                                        </div>
                                        <div className="specificationKcz">
                                            <div className="caozuo">
                                                <div className="czfu">↑</div>
                                                <div className="czfu">↓</div>
                                                <div className="czfu">X</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div className="shopGuiGeBody">
                        <table className="shopGuiGeTable">
                            <thead className="shopGuiGeThead">
                                <tr className="shopGuiGeTr">
                                    <th>序号</th>
                                    <th>规格名称</th>
                                    <th>创建时间</th>
                                    <th>修改时间</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody className="shopGuiGeTbody">
                                {this.state.shopGuige &&
                                    this.state.shopGuige.length > 0 &&
                                    this.state.shopGuige.slice(this.state.minValue, this.state.maxValue).map((item, index) => (
                                        <tr className="GuiGeTbodyTr" key={index}>
                                            <td>
                                                <input type="checkbox"
                                                    checked={item.checkOne}
                                                    onChange={(e) => {
                                                        this.checkOne(e, index);
                                                    }}
                                                />
                                            </td>
                                            <td>{item.name}</td>
                                            <td>{item.setTime}</td>
                                            <td>{item.xiugaitime}</td>
                                            <td>
                                                <div>
                                                    <span>编辑</span>
                                                    <span onClick={() => this.del(index)}>删除</span>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="commodityListFooter">
                        <div className="listFooterInfo">
                            <input
                                type="checkbox"
                                checked={this.state.checkAll}
                                onChange={(e) => {
                                    this.quan();
                                }}
                            />
                            <span>全选</span>
                            <span>批量上架</span>
                            <span onClick={() => this.delAll()}>批量删除</span>
                            <button>导出</button>
                        </div>
                        <div className="listFooterFenye">
                            <Pagination showQuickJumper defaultCurrent={this.state.pageNmb} total={this.state.shopGuige.length} onChange={(e) => this.onChange(e)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
