import React, { Component } from 'react'
import { Route, Switch, } from "react-router-dom"
import { Select, Button, Tag, Space, Table, message } from 'antd';
import "../../dingdan/dingdan/index.css"
// import MyList from './components/list'
import Detail from "./components/detail"
import request from '../../../../../../js/http';
import cookie from 'react-cookies'


let phoneInputs = null
export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [], // Check here to configure the default column
            loading: true,
            checkedList: [],
            userList: [],
            userLists: [],
            phoneInput: '',
            defaultCheck1: 'a1',
            defaultCheck2: 'a2',
            search: 1


        }
    }
    componentDidMount() {
        this.memberUserList()
    }
    //客户列表接口请求
    memberUserList = () => {
        request('post', '/mall/memberUser/queryList', {
            shopId: cookie.load('shopId'),
            pageNum: 1,
            pageSize: 990,
        }).then(res => {
          //console.log("客户列表接口=>", res)
            if (res.code === 200) {
                this.setState({
                    userList: res.rows,
                    userLists: res.rows,
                    loading: false,
                })
            }
        })
    }
    render() {
        // if (this.state.userList) {
        // console.log(userList);
        // }
        ///option组件
        const { Option } = Select;
        ////筛选组件公用的方法
        const allMoney = (value1, value2, value3) => {
            let newList = this.state.userLists.filter(item => {
                return (value2 <= item.allMoney && ((value3 === value2) ? true : item.allMoney <= value3))
            })
            this.setState({ userList: newList, defaultCheck1: value1, })
          //console.log(newList);
        }
        const orderNum = (value1, value2, value3) => {
            let newList = this.state.userLists.filter(item => {
                return (value2 === item.orderNum || (value3 === 10 ? item.orderNum >= value3 : item.orderNum === value3))
            })
            this.setState({ userList: newList, defaultCheck2: value1, })
          //console.log(newList);
        }
        const handleChange = (value) => {
          //console.log(`selected ${value}`);
            switch (value) {
                case 'a1':
                    this.setState({ userList: this.state.userLists, defaultCheck1: 'a1', })
                    break;
                case 'a2':
                    this.setState({ userList: this.state.userLists, defaultCheck2: 'a2', })
                    break;
                case 'a3':
                    this.setState({ userList: this.state.userLists, defaultCheck3: 'a3', })
                    break;
                case 'b1':
                    allMoney(value, 0, 200)
                    break;
                case 'c1':
                    allMoney(value, 200, 1000)
                    break;
                case 'd1':
                    allMoney(value, 1000, 3000)
                    break;
                case 'e1':
                    allMoney(value, 3000, 5000)
                    break;
                case 'f1':
                    allMoney(value, 5000, 10000)
                    break;
                case 'g1':
                    allMoney(value, 10000, 10000)
                    break;
                case 'b2':
                    orderNum(value, 1, 1)
                    break;
                case 'c2':
                    orderNum(value, 2, 3)
                    break;
                case 'd2':
                    orderNum(value, 4, 5)
                    break;
                case 'e2':
                    orderNum(value, 6, 7)
                    break;
                case 'f2':
                    orderNum(value, 8, 9)
                    break;
                case 'g2':
                    orderNum(value, 10, 10)
                    break;

                default:
                    break;
            }
        }
        //////数据筛选1组件
        const Select1 = () => {
            return (
                <Select defaultValue={this.state.defaultCheck1} style={{ width: 150, height: 35, marginLeft: "" }} onChange={handleChange}>
                    <Option value="a1">全部</Option>
                    <Option value="b1">200元以下</Option>
                    <Option value="c1">200-1000元</Option>
                    <Option value="d1">1000-3000元</Option>
                    <Option value="e1">3000-5000元</Option>
                    <Option value="f1">5000-10000元</Option>
                    <Option value="g1">1万元以上</Option>
                </Select>
            )
        }
        ////数据筛选2组件
        const Select2 = () => {
            return (
                <Select defaultValue={this.state.defaultCheck2} style={{ width: 150, height: 35 }} onChange={handleChange}>
                    <Option value="a2">全部</Option>
                    <Option value="b2">1次</Option>
                    <Option value="c2">2-3次</Option>
                    <Option value="d2">4-5次</Option>
                    <Option value="e2">6-7次</Option>
                    <Option value="f2">8-9次</Option>
                    <Option value="g2">10次及以上</Option>
                </Select>
            )
        }
        /////表格组件
        const MyTable = () => {
            ///////表格勾选
            const onSelectChange = selectedRowKeys => {
                // console.log('selectedRowKeys changed: ', selectedRowKeys);
                this.setState({ selectedRowKeys });
            };
            // /////表格勾选
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: onSelectChange,
            };
            const toDetail = (text) => {
                let memberInfos = this.state.userList.filter(item => {
                    return item.memberId === text
                })
                memberInfos = memberInfos[0]
                this.props.history.push({ pathname: '/index/khgl/detail', state: { memberId: text, memberInfos } })
            }
            /////表格 字段///
            const columns = [
                { title: '姓名', dataIndex: 'memberName', key: 'memberName', },
                { title: '手机号', dataIndex: 'memberPhone', key: 'memberPhone', },
                { title: '交易笔数/笔', dataIndex: 'orderNum', key: 'orderNum', },
                { title: '交易总额', dataIndex: 'allMoney', key: 'allMoney', },
                {
                    title: '标签', key: 'level', dataIndex: 'level', render: (text, record) => {
                        return (
                            <>
                                {
                                    record.level === 0 ? <Tag color="#f50">个人</Tag> : <>{
                                        record.level === 1 ? <Tag color="#2db7f5">企业</Tag> : <Tag color="#87d068">经纪人</Tag>}</>
                                }
                            </>
                        )
                    },
                },
                {
                    title: '操作', dataIndex: 'memberId', key: 'memberId', render: (text, record) => (
                        <Space className="link pointer" size="middle" onClick={() => toDetail(text)}>
                            详情
                            {/* <Link to={`/index/khgl/detail?id=1`}>编辑</Link>
                            <Link to={{ pathname: "/index/khgl/detail", search: `memberId=${text}` }}>编辑s</Link> */}
                        </Space>
                    ),
                },
            ];
            /////表格   数据//////
            let newList = this.state.userList
            newList = newList.map(item => {
                item.key = item.memberPhone;
                return item
            })
            const data = newList
            // 结构//////////////////////////
            return (
                <div className="main-show">
                    <Table columns={columns}
                        dataSource={data}
                        loading={this.state.loading}
                        pagination={{
                            showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                            total: data.length,
                            showTotal: total => `共 ${total} 条记录`,
                            defaultCurrent: 1,
                            showSizeChanger: false
                        }}
                        rowSelection={rowSelection}
                    />
                </div>
            )
        }
        const TB = () => {
            const phoneInput = (e) => {
                let value = e.target.value
                if (/^1[34578]\d{9}$/.test(value)) {
                    this.setState({ phoneInput: value })
                    phoneInputs = value
                    // console.log();
                    // this.setState({ phone: value, phoneTips: { text: "格式正确", color: "#87d068" } })
                } else {
                    if (value) {
                        message.warning("请输入正确的手机号")
                    }
                    // this.setState({ phoneTips: { text: "手机号码格式错误", color: "#f50" } })
                }
            }
            const searchPhone = () => {
                let phone = phoneInputs
                if (phone) {
                    this.setState({ loading: true })
                    setTimeout(() => {

                        let newList = this.state.userLists.filter(item => {
                            return item.memberPhone === phone
                        })
                        if (newList.length) {

                        } else {
                            message.warning("无结果")
                        }
                        this.setState({ userList: newList, loading: false, })
                        phoneInputs = null
                    }, 500);
                }
            }
            return (
                <div className="main-show order-list">
                    <div className="table-header">
                        <h1 className="table-name">客户列表</h1>
                        <div className="table-operation">
                            <div className="order-num">
                                <span className="order-num-text">手机号码 :</span>
                                <input className="order-num-num" placeholder={" 请输入用户手机号"} defaultValue={this.state.phoneInput} type="number" onBlur={e => phoneInput(e)} />
                            </div>
                            <div className="order-num">
                                <Button type="primary" onClick={searchPhone}  >搜索</Button>
                            </div>
                            <div className="order-state ml50">
                                <span >交易总额 </span>
                                <Select1 />
                            </div>
                            <div className="order-state ml50">
                                <span >下单次数 </span>
                                <Select2 />
                            </div>
                        </div>
                    </div>
                    <div className="table-content">
                        <MyTable />
                        <div className="table-foot">
                            <div className="foot-left">
                                {/* <Button className="table-export" ghost type="primary">导出</Button> */}
                                {/* <Button className="table-import" type="text">删除</Button> */}
                            </div>
                            <div className="foot-right">
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        //--------------------------------------------------------------
        return (
            <Switch>
                <Route path="/index/khgl/detail" component={Detail} exact />
                <Route path="/index/khgl" component={TB} />
            </Switch>

        )
    }
}
