import React, { Component } from 'react'
import { Spin } from 'antd';


export default class index extends Component {
    render() {
        return (
            <div>
                {/* 冷库出租 */}
                <div className="loading">
                    <div className="loading-center">
                        <Spin />
                    </div>
                </div>
            </div>
        )
    }
}
