import React, { Component } from 'react'
import "./index.scss";
import {
  Button,
  Modal,
  Pagination,
  Space,
  Menu, Dropdown, message, Radio
} from "antd";
import { DownOutlined, } from '@ant-design/icons';
export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tuihuoList: [
        {
          checkOne: false,
          diqu: '河南郑州高新区1',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区2',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 18239492363985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑32州高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州31高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑31州高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州432高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑543州高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区59',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区59',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区59',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '324',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区59',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区59',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州32高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南23郑州高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州234高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
        {
          checkOne: false,
          diqu: '河南郑州高新区',
          dizhi: '西城科技大厦',
          youxiang: '18239896526@163.com',
          phone: 182394963985,
          shouhuoren: '小舞',
          ismoren: '是',

        },
      ],
      isModalVisible: false,
      isDelModalVisible: false,
      pageNmb: 1,
      indexList: [],//当前渲染的页面数据
      checkAll: false,
      minValue: 0,
      maxValue: 10,
      diqumenu: '中国',
      tuiHuoId: '',
    }
  }

  componentWillMount() {
    this.setState({ indexList: this.state.tuihuoList.slice(this.state.minValue, this.state.maxValue) })
  }
  //添加
  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };
  handleOk = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  //删除模态框
  delShowModal = (item) => {
  //console.log(item);
    this.setState({ tuiHuoId: item.diqu })
    this.setState({
      isDelModalVisible: true,
    })
  }
  delHandleOk = (index) => {
  //console.log(this.state.tuiHuoId);
    const { tuihuoList } = this.state
    const guige = tuihuoList.filter((item, indey) => index !== indey)
    const indexList = this.state.indexList
  //console.log(indexList);

    guige.map(item => {
      if (item.checkOne === true) {
        this.setState({ checkAll: true })
      } else {
        this.setState({ checkAll: false })
      }
      return item
    })
    if (guige.length === 0) {
      this.setState({ checkAll: false })
    }
    if (indexList === 0) {
      this.setState({ checkAll: false })
    }
    this.setState({
      tuihuoList: guige,
      indexList
    }, function () {
      this.setState({ indexList: this.state.tuihuoList.slice(this.state.minValue, this.state.maxValue) })
      indexList.map(item => {
        if (item.checkOne === true) {
          this.setState({ checkAll: true })
        } else {
          this.setState({ checkAll: false })
        }
        return item
      })
    })
    this.setState({
      isDelModalVisible: false,
    });
  };

  delHandleCancel = () => {
    this.setState({
      isDelModalVisible: false,
    });
  };

  //单选
  checkOne = (e, i) => {
    const arr = this.state.indexList;
  //console.log(arr);
    arr[i].checkOne = !arr[i].checkOne;
    let arr1 = arr.filter((item) => item.checkOne === true);
  //console.log(arr[i].checkOne);
  //console.log(arr1);
    if (arr1.length === arr.length) {
      this.setState({
        indexList: arr,
        checkAll: true,
      });
    } else {
      this.setState({
        indexList: arr,
        checkAll: false,
      });
    }
  //console.log(this.state.pageNmb);
  };

  //全选
  quan() {
    let flag = this.state.checkAll;
    let arr = this.state.indexList;
  //console.log(arr);
    flag = !flag;
    if (flag === true) {
      arr.map((item) => {
        item.checkOne = true;
        return item
      });
    } else {
      arr.map((item) => {
        item.checkOne = false;
        return item
      });
    }

    this.setState({
      checkAll: flag,
      indexList: arr,
    }, function () {
      this.setState({ indexList: this.state.tuihuoList.slice(this.state.minValue, this.state.maxValue) })
    });
  }

  //删除
  del = (index) => {
    const { tuihuoList } = this.state
    const guige = tuihuoList.filter((item, indey) => index !== indey)
    const indexList = this.state.indexList
  //console.log(indexList);

    guige.map(item => {
      if (item.checkOne === true) {
        this.setState({ checkAll: true })
      } else {
        this.setState({ checkAll: false })
      }
      return item
    })
    if (guige.length === 0) {
      this.setState({ checkAll: false })
    }
    if (indexList === 0) {
      this.setState({ checkAll: false })
    }
    this.setState({
      tuihuoList: guige,
      indexList
    }, function () {
      this.setState({ indexList: this.state.tuihuoList.slice(this.state.minValue, this.state.maxValue) })
      indexList.map(item => {
        if (item.checkOne === true) {
          this.setState({ checkAll: true })
        } else {
          this.setState({ checkAll: false })
        }
        return item
      })
    })
  }


  //批量删除
  delAll = () => {
    let guige = [...this.state.tuihuoList];
    let del = guige.filter(i => {
      return !i.checkOne
    });
    this.setState({
      tuihuoList: del
    }, function () {
      this.setState({ indexList: this.state.tuihuoList.slice(this.state.minValue, this.state.maxValue) })
      this.state.indexList.map(item => {
        if (item.checkOne === true) {
          this.setState({
            checkAll: true
          })
        } else {
          this.setState({
            checkAll: false
          })
        }
        return item
      })
      if (this.state.indexList.length === 0) {
        this.setState({
          checkAll: false
        })
      }
    })
  }

  //分页
  onChange = (pageNumber) => {
    if (pageNumber <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 10
      });
    } else {
      this.setState({
        minValue: (pageNumber - 1) * 10,
        maxValue: (pageNumber - 1) * 10 + 10
      });
    }
    this.setState({
      pageNmb: pageNumber
    }, function () {
      this.setState({ indexList: this.state.tuihuoList.slice(this.state.minValue, this.state.maxValue) })
      this.state.indexList.map(item => {
        if (item.checkOne === true) {
          this.setState({
            checkAll: true
          })
        } else {
          this.setState({
            checkAll: false
          })
        }
        return item
      })
    })
  }



  //所在地区下拉
  handleButtonClick = (e) => {
    message.info('Click on left button.');
  //console.log('click left button', e);

  }

  handleMenuClick = (e) => {
    message.info('Click on menu item.');
  //console.log('click', e);
    this.setState({
      diqumenu: e.item.props.children[1],
    })
  }

  render() {
    const App = () => {
      const [value, setValue] = React.useState(1);

      const onChange = e => {
      //console.log('radio checked', e.target.value);
        setValue(e.target.value);
      };

      return (
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={1}>是</Radio>
          <Radio value={2}>否</Radio>
        </Radio.Group>
      );
    };

    const diqumenu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">
          中国
              </Menu.Item>
        <Menu.Item key="2">
          国外
              </Menu.Item>
        <Menu.Item key="3">
          地球
              </Menu.Item>
      </Menu>
    );
    return (
      <div>
        <div className="wuliiu-tuihuo">
          <div className="tuihuoTop">
            <div className="tuihuo-lf lf">
              退货地址管理
                        </div>
            <div className="tuihuo-lr lr">
              <Button type="primary" onClick={this.showModal}>
                新增地址
                            </Button>
              <Modal className="tuihuoModal" okText={'确认'} cancelText={'取消'} title="新增地址" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
                <div className="tuihuoItem">
                  <span className="tuihuoSpan">所在地区：</span>
                  <div className="diqu">
                    <Space className="diquSpace">
                      <Dropdown overlay={diqumenu} trigger={['click']}>
                        <Button className="xialadiqu">
                          {this.state.diqumenu} <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Space>
                  </div>
                </div>
                <div className="tuihuoItem">
                  <span className="tuihuoSpan">详细地址：</span>
                  <input className="tuihuoInput" type="text" placeholder="请输入详细地址" />
                </div>
                <div className="tuihuoItem">
                  <span className="tuihuoSpan">邮箱：</span>
                  <input className="tuihuoInput" type="text" placeholder="请输入邮箱" />
                </div>
                <div className="tuihuoItem">
                  <span className="tuihuoSpan">手机号码：</span>
                  <input className="tuihuoInput" type="text" placeholder="请输入手机号" />
                </div>
                <div className="tuihuoItem">
                  <span className="tuihuoSpan">收货人：</span>
                  <input className="tuihuoInput" type="text" placeholder="请输入手机号" />
                </div>
                <div className="tuihuoItem">
                  <span className="tuihuoSpan">是否默认：</span>
                  <App />
                </div>
              </Modal>
            </div>
          </div>
          <div className="tuihuoContent">
            <table className="tuihuoTable">
              <thead className="tuihuoThead">
                <tr className="tuihuoThTr">
                  <th style={{ width: '100px' }}>序号</th>
                  <th>所在地区</th>
                  <th>详细地址</th>
                  <th>邮箱</th>
                  <th>手机号</th>
                  <th>收货人</th>
                  <th>是否默认</th>
                  <th>操作</th>
                </tr>
                {this.state.tuihuoList &&
                  this.state.tuihuoList.length > 0 &&
                  this.state.tuihuoList.slice(this.state.minValue, this.state.maxValue).map((item, index) => (
                    <tr className="tuihuoListTdTr" key={index}>
                      <td style={{ width: '100px' }}>
                        <input type="checkbox"
                          checked={item.checkOne}
                          onChange={(e) => {
                            this.checkOne(e, index);
                          }} />
                      </td>
                      <td>{item.diqu}</td>
                      <td>{item.dizhi}</td>
                      <td>{item.youxiang}</td>
                      <td>{item.phone}</td>
                      <td>{item.shouhuoren}</td>
                      <td>{item.ismoren}</td>
                      <td>
                        <span className="tuihuoxg" onClick={() => this.showModal}>修改</span>
                        <span className="tuihuodel" onClick={() => this.delShowModal(item)}>删除</span>
                        <Modal className="tuihuoDelModal" okText={'确认'} cancelText={'取消'} title="新增地址" visible={this.state.isDelModalVisible} onOk={() => this.delHandleOk()} onCancel={this.delHandleCancel}>
                          <p>确定要删除该退款地址吗？</p>
                        </Modal>
                      </td>
                    </tr>
                  ))
                }
              </thead>
            </table>
          </div>
          <div className="commodityListFooter">
            <div className="listFooterInfo">
              <input
                type="checkbox"
                checked={this.state.checkAll}
                onChange={(e) => {
                  this.quan();
                }}
              />
              <span>全选</span>
              <span>批量上架</span>
              <span onClick={() => this.delAll()}>批量删除</span>
              <button>导出</button>
            </div>
            <div className="listFooterFenye">
              <Pagination showQuickJumper defaultCurrent={this.state.pageNmb} total={this.state.tuihuoList.length} onChange={(e) => this.onChange(e)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
