import React, { Component } from "react";
import request from '../../../../../../js/http'
import cookie from 'react-cookies'
// import axios from 'axios';
// import '../../../../../../js/http'
// import http from '../../../../../../js/http'
import "./index.scss";
import {
  Menu,
  Dropdown,
  Button,
  message,
  Space,
  Modal,
  Tabs,
  Radio,
  Switch,
  Pagination,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Dropdown: "所有",
      addDropdown: "子弹头",
      isModalVisible: false,
      isSpecificationModal: false,
      isCanShuZhiModal: false,
      activeKey: 1,
      selectedRowKeys: [],
      loading: false,
      checkAll: false,
      pageNmb: 1,
      indexList: [],//当前渲染的页面数据
      guigeList: [
        //   {
        //     checkOne: false,
        //     name: "子弹头1",
        //     time: new Date().toLocaleString(),
        // }

      ],
      minValue: 0,
      maxValue: 10
    };
  }

  componentWillMount() {

    this.getList()

  //console.log(this.state.guigeList);
    request('post', '/api/cms/slide/list', {
      type: '1-pc'
    }).then(res => {
    //console.log(res);
    })
  }

  //渲染列表
  getList = () => {
    // let a = cookie.load('userInfo')
    request('post', '/mall/goodsClass/getAllClass', {
      shopId: cookie.load("shopId"),
    }).then(res => {
    //console.log(res);
      let fenleiList = res.rows
      // let checkOne = 'checkOne'
      // let false1 = 1
      // fenleiList[checkOne] = false1
    //console.log(fenleiList);
      fenleiList.forEach((item, i) => {
        let checkOne = 'checkOne'
        let false1 = false
        item[checkOne] = false1
      //console.log(item);
        // console.log(item);
      });
      this.setState({
        guigeList: res.rows
      }, function () {
        this.setState({ indexList: this.state.guigeList.slice(this.state.minValue, this.state.maxValue) })
      //console.log(this.state.indexList);
      })
    })

  }

  // test(){
  //   axios({
  //     url:'/clife-boot-api-web/api/member/sms/send',
  //     type:'post',
  //     data:{}
  //   }).then(res=>{
  //     console.log(res);
  //   })
  // }

  //   test = () => {
  //     axios.post("/clife-boot-api-web/api/member/sms/send").then(response => {
  //         if(response.status === 200){
  //             this.setState({
  //                 messagesData: response.data,
  //             })
  //         }
  //     })
  // }

  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };


  //添加
  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };
  //基本设置
  listAddJiBenOk = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  listAddJiBenCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  //规格
  listAddGuiGeOk = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  listAddGuiGeCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  //参数列表
  listAddCanShuOk = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  listAddCanShuCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  //规格模态框
  showSpecificationModal = () => {
    this.setState({
      isSpecificationModal: true,
    });
  };

  handleSpecificationOk = () => {
    this.setState({
      isSpecificationModal: false,
    });
  };

  handleSpecificationCancel = () => {
    this.setState({
      isSpecificationModal: false,
    });
  };

  //添加参数值模态框
  showCanShuZhiModal = () => {
    this.setState({
      isCanShuZhiModal: true,
    });
  };

  canShuZhiOk = () => {
    this.setState({
      isCanShuZhiModal: false,
    });
  };

  canShuZhiCancel = () => {
    this.setState({
      isCanShuZhiModal: false,
    });
  };

  callback(key) {
  //console.log(key);
    this.setState({
      activeKey: key,
    });
  }


  //状态下拉菜单
  handleMenuClick = (e) => {
    message.info("Click on menu item.");
  //console.log("click", e.item.props.children[1]);
    this.setState({
      Dropdown: e.item.props.children[1],
    });
  };
  //基本设置类型名称下拉菜单
  addMenuClick = (e) => {
    message.info("Click on menu item.");
  //console.log("click", e.item.props.children[1]);
    this.setState({
      addDropdown: e.item.props.children[1],
    });
  };
  //单选
  checkOne = (e, i) => {
    const arr = this.state.indexList;
    arr[i].checkOne = !arr[i].checkOne;
    let arr1 = arr.filter((item) => item.checkOne === true);
  //console.log(arr[i].checkOne);
  //console.log(arr1);
  //console.log(arr);
    if (arr1.length === arr.length) {
      this.setState({
        indexList: arr,
        checkAll: true,
      });
    } else {
      this.setState({
        indexList: arr,
        checkAll: false,
      });
    }
  //console.log(this.state.pageNmb);
  };

  //全选
  quan() {
    let flag = this.state.checkAll;
    let arr = this.state.indexList;
    flag = !flag;
    if (flag === true) {
      arr.map((item) => {
        item.checkOne = true;
        return item
      });
    } else {
      arr.map((item) => {
        item.checkOne = false;
        return item
      });
    }

    this.setState({
      checkAll: flag,
      indexList: arr,
    }, function () {
      this.setState({ indexList: this.state.guigeList.slice(this.state.minValue, this.state.maxValue) })
    });

  }


  //删除
  del = (index) => {
    const { guigeList } = this.state
    const guige = guigeList.filter((item, indey) => index !== indey)
    const indexList = this.state.indexList
  //console.log(indexList);

    guige.map(item => {
      if (item.checkOne === true) {
        this.setState({ checkAll: true })
      } else {
        this.setState({ checkAll: false })
      }
      return item
    })
    if (guige.length === 0) {
      this.setState({ checkAll: false })
    }
    if (indexList === 0) {
      this.setState({ checkAll: false })
    }
    this.setState({
      guigeList: guige,
      indexList
    }, function () {
      this.setState({ indexList: this.state.guigeList.slice(this.state.minValue, this.state.maxValue) })
      indexList.map(item => {
        if (item.checkOne === true) {
          this.setState({ checkAll: true })
        } else {
          this.setState({ checkAll: false })
        }
        return item
      })
    })
  }

  //批量删除
  delAll = () => {
    let guige = [...this.state.guigeList];
    let del = guige.filter(i => {
      return !i.checkOne
    });
    this.setState({
      guigeList: del
    }, function () {
      this.setState({indexList: this.state.guigeList.slice(this.state.minValue, this.state.maxValue)})
    //console.log(this.state.indexList);
      this.state.indexList.map(item => {
        if (item.checkOne === true) {
          this.setState({
            checkAll: true

          })
        } else {
          this.setState({
            checkAll: false
          })
        }
        return item
      })

      if (this.state.indexList.length === 0) {
        this.setState({
          checkAll: false,
        })
      }
    })


  }


  //分页
  onChange = (pageNumber) => {
  //console.log('Page: ', pageNumber);

    if (pageNumber <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 10
      });
    } else {
      this.setState({
        minValue: (pageNumber - 1) * 10,
        maxValue: (pageNumber - 1) * 10 + 10
      });
    }
    this.setState({
      pageNmb: pageNumber
    }, function () {
      this.setState({indexList:this.state.guigeList.slice(this.state.minValue, this.state.maxValue)})
      this.state.indexList.map(item => {
        if (item.checkOne === true) {
          this.setState({
            checkAll: true
          })
        } else {
          this.setState({
            checkAll: false
          })
        }
        return item
      })

    })


  }
  render() {
    const { TabPane } = Tabs;

    //下拉状态
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">所有</Menu.Item>
        <Menu.Item key="2">时间</Menu.Item>
        <Menu.Item key="3">类别名称</Menu.Item>
      </Menu>
    );

    //下拉添加基本设置
    const addmenu = (
      <Menu onClick={this.addMenuClick}>
        <Menu.Item key="1">子弹头</Menu.Item>
        <Menu.Item key="2">子弹头头</Menu.Item>
        <Menu.Item key="3">阿斯蒂芬</Menu.Item>
      </Menu>
    );

    const Demo = () => (
      <Tabs
        defaultActiveKey={this.state.activeKey}
        onChange={(key) => this.callback(key)}
      >
        <TabPane tab="基本设置" key="1">
          <Space wrap>
            <span className="addxiaLazt">类型名称：</span>
            <Dropdown overlay={addmenu}>
              <Button className="addxiala">
                {this.state.addDropdown} <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
          <div className="specificationKFooter">
            <Button onClick={this.listAddJiBenCancel}>取消</Button>
            <Button type="primary" onClick={this.listAddJiBenOk}>确认</Button>
          </div>
        </TabPane>

        <TabPane tab="规格" key="2">
          <Button
            type="primary"
            className="addguige"
            onClick={(e) => this.showSpecificationModal(e.target)}
          >
            添加规格
          </Button>

          {/* 添加规格模态框 */}

          <div className="addGuiGeModal">
            <Modal
              title="添加"
              className="addGuiGeModal"
              visible={this.state.isSpecificationModal}
              onOk={this.handleSpecificationOk}
              onCancel={this.handleSpecificationCancel}
              okText="确认"
              cancelText="取消"
            >
              <div className="addguigeK">
                <Radio.Group
                  name="radiogroup"
                  defaultValue={1}
                  onChange={this.onRadioChange}
                >
                  <Radio value={1}>箱</Radio>
                  <Radio value={2}>箱</Radio>
                  <Radio value={3}>箱</Radio>
                  <Radio value={4}>箱</Radio>
                  <Radio value={5}>箱</Radio>
                  <Radio value={6}>箱</Radio>
                  <Radio value={7}>箱</Radio>
                  <Radio value={8}>箱</Radio>
                  <Radio value={9}>箱</Radio>
                  <Radio value={10}>箱</Radio>
                  <Radio value={11}>箱</Radio>
                  <Radio value={12}>箱</Radio>
                  <Radio value={13}>箱</Radio>
                  <Radio value={14}>箱</Radio>
                  <Radio value={15}>箱</Radio>
                  <Radio value={16}>箱</Radio>
                </Radio.Group>
              </div>
              <Radio.Group defaultValue="a" buttonStyle="solid">
                <Radio.Button value="a" ghost>斤</Radio.Button>
              </Radio.Group>
            </Modal>
          </div>



          <div className="specificationK">
            <div className="specificationKTop">
              <div className="specificationKName">规格名称</div>
              <div className="specificationKName">规格值</div>
              <div className="specificationKName">操作</div>
            </div>
            <div className="specificationKBody">
              <div className="specificationKList">
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKcz">
                  <div className="caozuo">
                    <div className="czfu">↑</div>
                    <div className="czfu">↓</div>
                    <div className="czfu">X</div>
                  </div>
                </div>
              </div>
              <div className="specificationKList">
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKcz">
                  <div className="caozuo">
                    <div className="czfu">↑</div>
                    <div className="czfu">↓</div>
                    <div className="czfu">X</div>
                  </div>
                </div>
              </div>
              <div className="specificationKList">
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKcz">
                  <div className="caozuo">
                    <div className="czfu">↑</div>
                    <div className="czfu">↓</div>
                    <div className="czfu">X</div>
                  </div>
                </div>
              </div>
              <div className="specificationKList">
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKcz">
                  <div className="caozuo">
                    <div className="czfu">↑</div>
                    <div className="czfu">↓</div>
                    <div className="czfu">X</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="specificationKFooter">
            <Button onClick={this.listAddGuiGeCancel}>取消</Button>
            <Button onClick={this.listAddGuiGeOk} type="primary">确认</Button>
          </div>
        </TabPane>

        <TabPane tab="参数列表" key="3">
          <Button
            type="primary"
            className="addguige"
            onClick={() => this.showCanShuZhiModal()}
          >
            添加参数值
          </Button>
          <Modal
            title="添加"
            className="addCanShuModal"
            visible={this.state.isCanShuZhiModal}
            onOk={this.canShuZhiOk}
            onCancel={this.canShuZhiCancel}
            okText="确认"
            cancelText="取消"
          >
            <div className="addguigeK">
              <Radio.Group
                name="radiogroup"
                defaultValue={1}
                onChange={this.onRadioChange}
              >
                <Radio value={1}>箱</Radio>
                <Radio value={2}>箱</Radio>
                <Radio value={3}>箱</Radio>
                <Radio value={4}>箱</Radio>
                <Radio value={5}>箱</Radio>
                <Radio value={6}>箱</Radio>
                <Radio value={7}>箱</Radio>
                <Radio value={8}>箱</Radio>
                <Radio value={9}>箱</Radio>
                <Radio value={10}>箱</Radio>
                <Radio value={11}>箱</Radio>
                <Radio value={12}>箱</Radio>
                <Radio value={13}>箱</Radio>
                <Radio value={14}>箱</Radio>
                <Radio value={15}>箱</Radio>
                <Radio value={16}>箱</Radio>
              </Radio.Group>
            </div>
            <Radio.Group defaultValue="a" buttonStyle="solid">
              <Radio.Button value="a">斤</Radio.Button>
            </Radio.Group>
          </Modal>
          <div className="specificationK">
            <div className="specificationKTop">
              <div className="specificationKName">规格名称</div>
              <div className="specificationKName">规格值</div>
              <div className="specificationKName">操作</div>
            </div>
            <div className="specificationKBody">
              <div className="specificationKList">
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKcz">
                  <div className="caozuo">
                    <div className="czfu">↑</div>
                    <div className="czfu">↓</div>
                    <div className="czfu">X</div>
                  </div>
                </div>
              </div>
              <div className="specificationKList">
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKcz">
                  <div className="caozuo">
                    <div className="czfu">↑</div>
                    <div className="czfu">↓</div>
                    <div className="czfu">X</div>
                  </div>
                </div>
              </div>
              <div className="specificationKList">
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKcz">
                  <div className="caozuo">
                    <div className="czfu">↑</div>
                    <div className="czfu">↓</div>
                    <div className="czfu">X</div>
                  </div>
                </div>
              </div>
              <div className="specificationKList">
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKItem">
                  <input type="text" />
                </div>
                <div className="specificationKcz">
                  <div className="caozuo">
                    <div className="czfu">↑</div>
                    <div className="czfu">↓</div>
                    <div className="czfu">X</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="specificationKFooter">
            <Button onClick={this.listAddCanShuCancel}>取消</Button>
            <Button onClick={this.listAddCanShuOk} type="primary">确认</Button>
          </div>
        </TabPane>
      </Tabs>
    );
    return (
      <>
        <div className="commodityList">
          <div className="commodityListTop">
            <div className="typeSearch">
              <span>类别名称</span>
              <input type="text" placeholder="请输入名称" />
            </div>
            <Space wrap>
              <span className="xiaLazt">状态</span>
              <Dropdown overlay={menu}>
                <Button className="xiala">
                  {this.state.Dropdown} <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
            <div className="commodityListAdd">
              <Button type="primary" onClick={this.showModal}>
                添加
              </Button>
              <Modal
                title="添加"
                visible={this.state.isModalVisible}
                footer={[]}
                onCancel={this.listAddJiBenCancel}
                className="fenleiModal"
              >
                <Demo />
              </Modal>
            </div>
          </div>
          <div className="commodityListBody">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>序号</th>
                  <th>类别名称</th>
                  <th>状态</th>
                  <th>创建时间</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                {this.state.guigeList &&
                  this.state.guigeList.length > 0 &&
                  this.state.guigeList.slice(this.state.minValue, this.state.maxValue).map((item, index) => (
                    <tr className="TbodyTr" key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={item.checkOne}
                          onChange={(e) => {
                            this.checkOne(e, index);
                          }}
                        />
                      </td>
                      <td>{item.className}</td>
                      <td>
                        <Switch defaultChecked onChange={this.onChange} />
                      </td>
                      <td>{item.createAt}</td>
                      <td>
                        <div>
                          <span>编辑</span>
                          <span onClick={() => this.del(index)}>删除</span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="commodityListFooter">
            <div className="listFooterInfo">
              <input
                type="checkbox"
                checked={this.state.checkAll}
                onChange={(e) => {
                  this.quan();
                }}
              />
              <span>全选</span>
              <span>批量上架</span>
              <span onClick={() => this.delAll()}>批量删除</span>
              <button>导出</button>
            </div>
            <div className="listFooterFenye">
              <Pagination showQuickJumper defaultCurrent={this.state.pageNmb} total={this.state.guigeList.length} onChange={(e) => this.onChange(e)} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
