import React, { useState, Component } from 'react';
import { Modal } from 'antd';

import "./logistics.css"
export default class Logistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logistics:{}
        }
    }
    componentDidMount(){
        // console.log(this.props.logistics);
        this.setState({logistics: this.props.logistics})
    }
    render() {
        ///////////////时间格式转换函数//////////
        function newTime(ordTime) {
            var date = new Date(ordTime);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = date.getDate() + ' ';
            let h = date.getHours() + ':';
            let m = date.getMinutes() + ':';
            let s = date.getSeconds();
            return Y + M + D + h + m + s;
        }
        const App = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <div className="pointer" type="primary" onClick={showModal}>
                        查看详情
                </div>
                    <Modal className="content-right"
                        footer={null}
                        title="物流详情"
                        visible={isModalVisible}
                        onOk={handleOk}
                        centered={true}
                        onCancel={handleCancel}
                        width={800}
                    >
                        <div className="modal-content">
                            <div className="detail-header">
                                <span className="title-address fs14px">收货地址: </span>
                                <span className="name fs14px">{this.props.logistics.orderAddress.receiverName}</span>
                                <span className="mobile fs14px">{this.props.logistics.orderAddress.receiverPhone}</span>
                                <span className="address fs14px">{this.props.logistics.orderAddress.receiverProvince}</span>
                                <span className="address fs14px">{this.props.logistics.orderAddress.receiverCity}</span>
                                <span className="address fs14px">{this.props.logistics.orderAddress.receiverArea}</span>
                                <span className="address fs14px">{this.props.logistics.orderAddress.receiverTown}</span>
                                <span className="address fs14px">{this.props.logistics.orderAddress.receiverAddress}</span>
                            </div>
                            <div className="detail-area">
                                <div className="scroll">
                                    <p className="flex"><span className="w70px">发货方式：</span><span >快递</span></p>
                                    <p className="flex"><span className="w70px">物流公司：</span><span >{this.props.logistics.shippingCode}</span></p>
                                    <p className="flex"><span className="w70px">运单号码：</span><span >{this.props.logistics.shippingName}</span></p>
                                    <p className="flex"><span className="w70px">其他详情：</span><span ></span></p>
                                    <p className="flex"><span className="w70px"></span><span>下单时间 :{newTime(this.props.logistics.createAt)}</span></p>
                                    <p className="flex"><span className="w70px"></span><span>发货时间 :{newTime(this.props.logistics.consignAt)}</span></p>
                                    <p className="flex"><span className="w70px"></span><span>收货时间 :{this.props.logistics.receivingAt? newTime(this.props.logistics.receivingAt):''}</span></p>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        };
        return (
            <div className="content-right">
                <App />
            </div>
        )
    }
}
