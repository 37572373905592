import React, { Component, useState } from 'react'
import { message, Modal, Select, Image } from 'antd';
import PriceModifyApp from "./price-modify"
import Logistics from './logistics'
import "./index.css"
import Close from './close.png'
import Warning from './warning.png'
import request from '../../../../../../../../js/http'
import cookie from 'react-cookies'
import moment from 'moment';

let shippingCodeInput, postSelect = "a", shippingName = '顺丰速递';
let shippingTypes = "0"

export default class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dingdanInfo: [],
            orderDetail: {},
            orderAddress: [],
            status: 0,
            statusText: "",
            endTime: 1,
            orderGoodsList: [],

        }
    }

    componentDidMount() {
        // console.log(this.props);
        this.xiangqing()
    }
    newTime(ordTime) {
        var date = new Date(ordTime);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = date.getDate() + ' ';
        let h = date.getHours() + ':';
        let m = date.getMinutes() + ':';
        let s = date.getSeconds();
        return Y + M + D + h + m + s;
    }
    xiangqing = () => {
        let orderId = this.props.location.state.orderId
        if (this.props.location)
            request('get', `/mall/orderOrder/${orderId}`, {
                shopId: cookie.load("shopId")
            }).then(res => {
              //console.log("根据id获取订单详情=>", res);
                // this.state.dingdanInfo=[res.data]
                this.setState({
                    orderGoodsList: res.data.detailList,
                    dingdanInfo: [res.data],
                    orderAddress: res.data.orderAddress,
                    status: res.data.status,
                    orderDetail: res.data,
                    endTime: this.newTime(res.data.createAt + 24 * 60 * 60 * 1000)
                })
                switch (this.state.status) {
                    case 0:
                        this.setState({ statusText: "待商议" })
                        break;
                    case 1:
                        this.setState({ statusText: "待支付" })
                        break;
                    case 2:
                        this.setState({ statusText: "待发货" })
                        break;
                    case 3:
                        this.setState({ statusText: "待收货" })
                        break;
                    case 4:
                        this.setState({ statusText: "待评价" })
                        break;
                    case 7:
                        this.setState({ statusText: "已完成" })
                        break;
                    default:
                        break;
                }
            })
    }

    exit = () => {
        let path = this.props.location.state.from
        window.location.replace(path)

        // window.history.back(-1);
        // console.log(this.props);

    }

    shippingCodeInput = e => {
        let value = e.target.value
        if (/^\w{1,32}$/.test(value) && value) {
            shippingCodeInput = value;
        } else {
            if (/\s/.test(value)) {
                message.warning("物流单号不能包含空字符")
            } else {
                message.warning("物流单号长度有误")
            }

        }
    }
    handleChange = value => {
        // console.log(value);
        switch (value) {
            case 'a':
                postSelect = value
                shippingName = "顺风速运"
                break;
            case 'b':
                postSelect = value
                shippingName = "京东快递"
                break;
            case 'c':
                postSelect = value
                shippingName = "申通快递"
                break;
            case 'd':
                postSelect = value
                shippingName = "圆通速递"
                break;
            case 'e':
                postSelect = value
                shippingName = "韵达快递"
                break;
            case 'f':
                postSelect = value
                shippingName = "中通速递"
                break;
            case 'g':
                postSelect = value
                shippingName = "百世汇通"
                break;
            case 'h':
                postSelect = value
                shippingName = "天天快递"
                break;
            default:
                break;
        }
        // console.log(shippingName);
    }
    addDeliverInfo = () => {

        let shopId = this.state.orderDetail.shopId, orderId = this.state.orderDetail.orderId, shippingCode = shippingCodeInput, shippingType = shippingTypes
        // console.log(shopId, orderId, shippingCode, shippingName,shippingType);
        // console.log(shopId, orderId, shippingCode, shippingName,shippingType);
        if (shopId && orderId && shippingCode && shippingName && shippingType) {
            // console.log(666);
            request("post", "/mall/orderOrder/deliver", {
                shopId,
                orderId,
                shippingName,
                shippingCode,
                shippingType,
            }).then(res => {
              //console.log("店铺订单发货=>", res);
                if (res.code === 200) {
                    message.success("发货信息添加成功")
                    this.xiangqing()
                }
            })
        } else {
          //console.log(shopId, orderId, shippingCode, shippingName, shippingType);

            message.warning('请填写完整的物流信息')
        }
    }
    render() {
        const App = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);

            const showModal = () => {
                setIsModalVisible(true);
            };

            const handleOk = () => {
                setIsModalVisible(false);
                this.addDeliverInfo()
            };

            const handleCancel = () => {
                setIsModalVisible(false);
            };
            let status = this.state.status
            // let orderSn = 0 
            // console.log( this.state.orderDetail.orderSn);
            const haha = () => {
                message.info("等待买家支付...")

            }
            return (
                <>
                    <span type="primary" onClick={status === 2 ? showModal : haha}>{status === 2 ? "添加发货信息" : '等待支付...'}</span>
                    <Modal title="添加发货信息" width={800} visible={isModalVisible} okText="确认" cancelText="取消" onOk={handleOk} onCancel={handleCancel}>
                        <div className="shipping-info-add">
                            <div className="div">
                                <span className="span">订单编号：</span><input className="input" defaultValue={this.state.orderDetail.orderSn} />
                            </div>
                            <div className="div">
                                <span className="span">物流类型：</span>
                                <Select defaultValue={shippingTypes} style={{ width: 350, height: 40 }} onChange={handleChange}>
                                    <Option value="0">公司</Option>
                                    <Option value="1">个人</Option>
                                </Select>
                            </div>
                            <div className="div">
                                <span className="span">物流单号：</span><input className="input" onBlur={e => this.shippingCodeInput(e)} placeholder="请填写物流单号" />
                            </div>
                            <div className="div">
                                <span className="span">物流公司：</span>
                                <Select defaultValue={postSelect} style={{ width: 350, height: 40 }} onChange={value => this.handleChange(value)}>
                                    <Option value="a">顺丰速运</Option>
                                    <Option value="b">京东快递</Option>
                                    <Option value="c">申通快递</Option>
                                    <Option value="d">圆通速递</Option>
                                    <Option value="e">韵达快递</Option>
                                    <Option value="f">中通速递</Option>
                                    <Option value="g">百世汇通</Option>
                                    <Option value="h">天天快递</Option>
                                </Select>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        };
        ///////////////////////////////////////////////////////
        const OrderInfo = () => {
            return (
                <>
                    <div className="content-left">
                        <div className="content-lleft">
                            {/* <span >物流类型：公司</span> */}
                            <span >物流单号：{this.state.dingdanInfo[0].shippingName}</span>
                            <span >物流公司：{this.state.dingdanInfo[0].shippingCode}</span>
                        </div>
                    </div>
                    <Logistics logistics={this.state.dingdanInfo[0]} />
                </>
            )
        }
        const OrderInfoAdd = () => {
            return (
                <>
                    <div className="logistics-info">
                        <span className="logistics-add"><App /></span>
                    </div>
                </>
            )
        }


        const wuliuInfo = () => {
            let { status } = this.state
            // console.log(status);
            switch (status) {
                case 0:
                    return <OrderInfoAdd />
                case 1:
                    return <OrderInfoAdd />;
                case 2:
                    return <OrderInfoAdd />;
                default:
                    return <OrderInfo />
            }
        }
        /// 选择器↓↓↓↓↓↓↓↓↓↓↓↓↓↓/////////////////////////////////////////////
        const { Option } = Select;
        function handleChange(value) {
          //console.log(`selected ${value}`);
            shippingTypes = value


        }
        /////////////////////////////////////////////////////////////////////////////////

        return (
            <div className="main-show">
                <div className="detail-header" style={{ justifyContent: 'flex-start' }}>
                    <img className="img-close" src={Close} onClick={this.exit} alt="图片加载失败" />
                    <h1 className="detail-title">详情</h1>
                </div>
                <div className="detail-main">
                    <div className="order-status">
                        <div className="order-status-now">
                            <img className="img-warning" src={Warning} alt="图片加载失败" />
                            <span className="order-status-text">订单状态: {this.state.statusText}</span>
                        </div>
                        <div className="order-status-endtime">
                            {this.state.status === 0
                                ?
                                <>
                                    <span>截至时间:</span><span>{this.state.endTime}</span>
                                </>
                                :
                                <>
                                    {this.state.status === 1
                                        ?
                                        <>
                                            <span>截至时间:</span><span>{this.state.endTime}</span>
                                        </>
                                        :
                                        <>
                                        </>}
                                </>}
                        </div>
                    </div>
                    {
                        this.state.dingdanInfo.map((item, index) => (
                            <div className="scard order-info" key={index}>
                                <div className="scard-title">
                                    订单信息
                                </div>
                                {this.state.orderGoodsList.map(it => {
                                    item.key = item.detailId
                                    return (
                                        <div className="scard-content">
                                            <div className="content-left">
                                                <div className="content-lleft">
                                                    <Image
                                                        width={120}
                                                        src={it.goodsImg}
                                                    />
                                                </div>
                                                <div className="content-lleft">
                                                    <span className="gray">订单编号：{item.orderSn}</span>
                                                    <span >商品标题：{it.goodsName}</span>
                                                    <span >等级：{it.goodsProp}</span>
                                                    <span >购买数量：{it.total}</span>
                                                </div>
                                                <div className="content-lright content-lleft">
                                                    <span className="gray">下单时间：{moment(item.createAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                    <span>品种：{it.className}</span>
                                                    <span>单位净重: {it.productWeight} 斤</span>
                                                    <span>包邮 : {item.orderPost === 0 ? ' 包邮' : ' 不包邮'}</span>
                                                </div>
                                                <div className="content-lright content-lleft">
                                                    <span className="gray">{'*'}</span>
                                                    <span>规格(单位)：{it.productUnit}</span>
                                                    <span>价格：￥{it.price}/斤</span>
                                                    <span>金额：￥{it.allPrice}</span>
                                                </div>
                                            </div>
                                            <div className="content-right">
                                                <span className="total">金额</span>
                                                <span className="price">￥{it.allPrice}</span>
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="scard-foot">
                                    <div className="foot-conten">
                                        <span className="left-span">商品合计：￥{item.orderPrice}</span>
                                        <span className="left-span">活动优惠：￥0.00</span>
                                        <span className="left-span">优惠券：￥0.00</span>
                                        <span className="left-span">运费：￥{item.orderPost}</span>
                                        <span className="left-span">付款金额:<span className="orange">￥{item.payment}</span></span>
                                    </div>
                                    <PriceModifyApp info={this.state.dingdanInfo} />
                                </div>
                            </div>
                        ))
                    }
                    <div className="scard  ">
                        <div className="scard-title">
                            收货人信息
                        </div>
                        <div className="scard-content">
                            <div className="content-left">
                                <div className="content-lleft">
                                    <span className="left">收货人：{this.state.orderAddress.receiverName}</span>
                                    <span >手机号码：{this.state.orderAddress.receiverPhone}</span>
                                    <span >收货地址：
                                        {this.state.orderAddress.receiverProvince}
                                        {this.state.orderAddress.receiverCity}
                                        {this.state.orderAddress.receiverArea}
                                        {this.state.orderAddress.receiverAddress}

                                    </span>
                                </div>

                            </div>
                            <div className="content-right">
                                {/* 修改 */}
                            </div>
                        </div>
                    </div>
                    <div className="scard  ">
                        <div className="scard-title">
                            物流信息
                        </div>
                        <div className="scard-content">
                            {
                                wuliuInfo()
                            }
                            {/* {this.state.orderInfo ? <OrderInfo /> : <OrderInfoAdd />} */}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
