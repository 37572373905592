import React, { Component } from 'react'
import { withRouter} from "react-router-dom";
import { Radio } from "antd";
// import * as echarts from 'echarts';
import cookie from 'react-cookies';
import request from '../../../../../../js/http';


import Visitors from '../img/visitors.png'
import Views from '../img/views.png'
import Num from '../img/goodsViewsVisitors.png'
import Blue from '../img/blue.png'
import Orange from '../img/orange.png'
import Green from '../img/green.png'



import "./index.css"

// function onchange(value) {
//     console.log(value);
// }
// function onChanges(date, dateString) {
//     console.log(date, dateString);

// }

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultDay: '1',
            data: {}
        }
    }
    componentDidMount() {

        this.props.callback(this.props.location.pathname)

        this.getData('0')
    }
    getData(type) {

        if (type) {

            request('post', '/mall/orderOrder/shop/dataInfo', {
                shopId: cookie.load("shopId"),
                type: type
            }).then(res => {
              //console.log('流量分析-数据中心=>', res);
                if (res.code === 0) {
                    this.setState({ data: res.data })
                    // console.log(this.state.data);
                }
            })
        }
    }
    handleSizeChange = e => {
        let value = e.target.value
        switch (value) {
            case '1':
                this.getData(value)
                this.setState({ defaultDay: value })
                break;
            case '2':
                this.getData(value)
                this.setState({ defaultDay: value })
                break;
            case '3':
                this.getData(value)
                this.setState({ defaultDay: value })
                break;
            case '4':
                this.getData(value)
                this.setState({ defaultDay: value })
                break;

            default:
                break;
        }
    };

    render() {
        // const { Option } = Select;
        return (
            <>
                {/* ///////四个卡片 */}
                <div className="visitors-views ">
                    <div className="item">
                        <div className="item-left blue">
                            <img src={Visitors} alt="访客数" />
                        </div>
                        <div className="item-right">
                            <span className="item-title">商品访客数</span>
                            <span className="item-num">{this.state.data.allGoodsMemberNum}</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-left red">
                            <img src={Views} alt="浏览量" />
                        </div>
                        <div className="item-right">
                            <span className="item-title">商品浏览量</span>
                            <span className="item-num"> {this.state.data.allGoodsViewNum}</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-left green">
                            <img src={Num} alt="商品访客数" />
                        </div>
                        <div className="item-right">
                            <span className="item-title">店铺访客数</span>
                            <span className="item-num"> {this.state.data.allShopViewNum}</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-left orange">
                            <img src={Num} alt="商品浏览量" />
                        </div>
                        <div className="item-right">
                            <span className="item-title">店铺浏览量</span>
                            <span className="item-num"> {this.state.data.allShopMemberNum}</span>
                        </div>
                    </div>
                </div>
                {/* ///////////////////流量总览 */}
                <div className="main-show order-list br5">
                    <div className="table-header">
                        <h1 className="table-name">流量总览</h1>
                        <div className="table-operation">
                            {/* <Button className="btn-add" ><Link to="/index/ptsp/add">添加</Link></Button> */}
                            {/* <div className="order-state order-num">
                                <span >选择时间 :</span>
                                <DatePicker onChange={onChanges} />
                            </div> */}
                            <span className="date-select">
                                <Radio.Group defaultValue={this.state.defaultDay} onChange={this.handleSizeChange}>
                                    <Radio.Button value="1">今天</Radio.Button>
                                    <Radio.Button value="2">昨天</Radio.Button>
                                    <Radio.Button value="3">近7天</Radio.Button>
                                    <Radio.Button value="4">近30天</Radio.Button>
                                </Radio.Group>
                            </span>
                        </div>
                    </div>
                    {/* //////数据统计区域 (假折线统计图) */}
                    <div className="charts">
                        <div className="chart-item">
                            <div className="item-top">
                                <div className="num-area">
                                    <p className="item-title">访问店铺</p>
                                    <p className="item-num">{this.state.data.shopMemberNum} <span className="num-nuit">访客数</span></p>
                                </div>
                                {/* ///////////圆点 */}
                                <div className="dot dot-blue "></div>
                            </div>
                            <img className="item-bottom" src={Blue} alt="店铺访问曲线" />
                        </div>
                        <div className="chart-item">
                            <div className="item-top">
                                <div className="num-area">
                                    <p className="item-title">访问商品</p>
                                    <p className="item-num">{this.state.data.goodsMemberNum} <span className="num-nuit">商品访客数</span></p>
                                </div>
                                <div className="dot dot-green "></div>
                            </div>
                            <img className="item-bottom" src={Green} alt="访问商品曲线" />
                        </div>
                        <div className="chart-item">
                            <div className="item-top">
                                <div className="num-area">
                                    <p className="item-title">转化</p>
                                    <p className="item-num">{this.state.data.orderMemberNum} <span className="num-nuit">支付买家</span></p>
                                </div>
                                <div className="dot dot-orange "></div>
                            </div>
                            <img className="item-bottom" src={Orange} alt="转化曲线" />
                        </div>
                    </div>
                    {/* /////////数据统计多点 */}
                    <div className="data-statistical">
                        <div className="data-item">
                            <p className="data-item-title">店铺新访客数</p>
                            <p className="data-item-num">{this.state.data.shopNewMemberNum}</p>
                        </div>
                        <div className="data-item">
                            <p className="data-item-title">店铺老访客数</p>
                            <p className="data-item-num">{this.state.data.shopOldMemberNum}</p>
                        </div>
                        <div className="data-item">
                            <p className="data-item-title">商品新访客数</p>
                            <p className="data-item-num">{this.state.data.goodsNewMemberNum}</p>
                        </div>
                        <div className="data-item">
                            <p className="data-item-title">商品老访客数</p>
                            <p className="data-item-num">{this.state.data.goodsOldMemberNum}</p>
                        </div>
                        <div className="data-item">
                            <p className="data-item-title">人均浏览量</p>
                            <p className="data-item-num">{this.state.data.shopAvgViewNum}</p>
                        </div>
                        <div className="data-item">
                            <p className="data-item-title">店铺关注人数</p>
                            <p className="data-item-num">{this.state.data.shouCollectNum}</p>
                        </div>
                        <div className="data-item">
                            <p className="data-item-title">收藏商品人数</p>
                            <p className="data-item-num">{this.state.data.goodsCollectNum}</p>
                        </div>
                        <div className="data-item">
                            <p className="data-item-title">加入购物车人数</p>
                            <p className="data-item-num">{this.state.data.goodsCartNum}</p>
                        </div>
                        <div className="data-item">
                            <p className="data-item-title">销售额</p>
                            <p className="data-item-num">{this.state.data.orderRate}</p>
                        </div>
                        <div className="data-item">
                            <p className="data-item-title">支付转化率</p>
                            <p className="data-item-num">{this.state.data.payRate}</p>
                        </div>
                        <div className="data-item">
                            <p className="data-item-title">支付转化率</p>
                            <p className="data-item-num">{this.state.data.payRate}</p>
                        </div>


                    </div>
                </div>

            </>
        )
    }
}
export default withRouter(Index)
