import React, { Component } from 'react'
import { Select, Button,  Tag, Space, Table } from "antd";
import { PlusOutlined} from "@ant-design/icons"

import { Link, Switch, Route } from "react-router-dom";


const { Option } = Select;
function handleChange(value) {
  //console.log(`selected ${value}`);
}

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            refundsList: [],
            goodsId: '',
        }
    }
    render() {
        /////表格 字段///
        const columns = [
            { title: '商品ID', dataIndex: 'goodsId', key: 'goodsId', },
            { title: '商品名称', dataIndex: 'goodsName', key: 'goodsName', },
            { title: '种类 ', dataIndex: 'className', key: 'className', },
            { title: '等级', dataIndex: 'goodsProp', key: 'goodsProp', },
            {
                title: '状态', key: 'goodsLoading', dataIndex: 'goodsLoading', render: (text, record) => {
                    return (
                        <>
                            {
                                record.goodsLoading === 0 ? <Tag color="#f50">待上架</Tag> : <>{
                                    record.goodsLoading === 1 ? <Tag color="#87d068">上架</Tag> : <Tag color="#2db7f5">下架</Tag>}</>
                            }
                        </>
                    )
                },
            },
            {
                title: '操作', dataIndex: 'goodsId', key: 'action', render: (text, record) => (
                    <Space size="middle">
                        {/* <Button onClick={(e) => (this.passIdToView(record.goodsId))} ><Link to={{ pathname: "/index/ptsp/detail", state: { goodsList: this.state.goodsList, goodsId: record.goodsId } }}>查看</Link></Button> */}
                        <Link to="/index/ptsp/edit">编辑</Link>
                    </Space>
                ),
            },
        ];
        /////表格   数据//////
        // let newList = this.state.goodsList.map(item => {
        //     item.key = item.goodsId;
        //     return item
        // })
        // const data = newList
        let datas = [{
            key: `001`,
            goodsId: `021`,
            goodsName: "看,辣椒",
            className: "可以吃",
            goodsProp: "p, 上等品", 
            goodsLoading: 2,
        }];
        for (let i = 0; i < 50; i++) {
            datas.push({
                key: i,
                goodsId: `${i}021`,
                goodsName: "看,辣椒",
                className: "可以吃sdfsdf",
                goodsProp: "p, 上等品",
                goodsLoading: '656565',
            })
        }
        let data = datas

        /////表格勾选
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const TB = () => {
            return (
                <div className="main-show order-list">
                    <div className="table-header">
                        <h1 className="table-name">优惠活动列表</h1>
                        <div className="table-operation">
                            {/* <div className="order-num">
                                <span className="order-num-text">订单编号 :</span>
                                <input className="order-num-num" />
                            </div>
                            <div className="order-state">
                                <span >订单状态 :</span>
                                <Select defaultValue="a" style={{ width: 100, height: 35 }} onChange={handleChange}>
                                    <Option value="a">全部</Option>
                                    <Option value="g">待商议</Option>
                                    <Option value="b">待付款</Option>
                                    <Option value="c">代发货</Option>
                                    <Option value="d">待收货</Option>
                                    <Option value="e">待评价</Option>
                                    <Option value="f">已完成</Option>
                                </Select>
                            </div> */}
                        </div>
                    </div>
                    <div className="table-header">
                        {/* <h1 className="table-name">退款列表</h1> */}
                        <div className="table-operation">
                            <div className="order-num">
                                <span className="order-num-text">活动类型 :</span>
                                <Select defaultValue="a" style={{ width: 100, height: 35 }} onChange={handleChange}>
                                    <Option value="a">全部</Option>
                                    <Option value="b">折扣活动</Option>
                                </Select>
                            </div>
                            <div className="order-state">
                                <span >活动状态 :</span>
                                <Select defaultValue="c" style={{ width: 100, height: 35 }} onChange={handleChange}>
                                    <Option value="c">全部</Option>
                                    <Option value="d">未开始</Option>
                                    <Option value="e">进行中</Option>
                                    <Option value="f">已结束</Option>
                                    <Option value="g">暂停中</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="">
                            {/* <Button icon={<DownloadOutlined />} type="">导出</Button> */}
                            <Button icon={<PlusOutlined />} className="ml50" type="primary">添加</Button>
                        </div>
                    </div>
                    <div className="table-content">
                        <Table columns={columns}
                            dataSource={data}
                            pagination={{
                                showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                                total: data.length,
                                showTotal: total => `共 ${total} 条记录`,
                                defaultCurrent: 2,
                                showSizeChanger: false
                            }}
                        rowSelection={rowSelection} 
                        />
                        <div className="table-foot">
                    <div className="foot-left">
                        <Button className="table-import" type="text">删除</Button>
                        {/* <Button className="table-export" ghost type="primary">导出</Button> */}
                    </div>
                    <div className="foot-right">
                        {/* <span className="table-num">共{dataFather.length}条记录</span>
                        <div className="to-page">
                            到第
                            <input type="" placeholder="1" />
                            页
                            <Button type="primary" className="jump">GO</Button>
                        </div> */}
                    </div>
                </div>
                    </div>
                </div>
            )
        }
        return (
            <Switch>
                {/* <Route path="/index/ptsp/detail" component={GoodsDetail} exact />
                <Route path="/index/ptsp/add" component={GoodsAdd} exact />
                <Route path="/index/ptsp/edit" component={GoodsEdit} exact /> */}
                <Route path="/index/yhhd" component={TB} />
            </Switch>

        )
    }
}
