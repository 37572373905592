import React, { Component } from 'react'
// import ReactDom from 'react-dom'

// import { withRouter } from "react-router-dom"
import request from '../../../../js/http'
import cookie from 'react-cookies'

import userIcon from '../../user.png'
import { Button, message } from 'antd';
import './index.css'


export default class Codedlogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendBtn: true,
            codeData: "",
            code: '',
            times: 0,
            phone: '',
            phones: '',

        }
    }
    componentDidMount() {
        if (cookie.load("shopId") && cookie.load("userId")) {
            message.warning("您已经登录, 请勿重复登录")
            // cookie.remove("userInfo")
            // cookie.remove("userId")
            setTimeout(() => {
                window.location.replace('/index');

            }, 500)

        }
    }
    phoneChange(e) {
        let value = e.target.value
        if (/^1[34578]\d{9}$/.test(value)) {
            this.setState({ phone: value, phones: value })
        } else {
            if (!value) {
                // this.setState({ phoneTips: { text: "手机号码为必填项", color: "#f50" } })
                message.warning('手机号码为必填项')


            } else {
                // this.setState({ phoneTips: { text: "手机号码格式错误", color: "#f50" } })
                message.warning('手机号码格式错误')

            }
        }
        // this.setState({phone: e.target.value})
        // console.log(e.target.);
    }
    codeChange(e) {
        this.setState({ code: e.target.value })
    }
    getcode = () => {
        this.setState({ sendBtn: false ,times: 60})
        let time = 60;
        let timeDo = setInterval(() => {
            time--;
            this.setState({ times: time })
            if (time === 0) {
                clearInterval(timeDo);
                this.setState({ sendBtn: true, phones: '', })
            }
        }, 1000);
        request('post', '/mall/shopUser/send', {
            phone: this.state.phones
        }).then((res) => {
          //console.log('验证码登录=>', res);
            if (res.code === 0) {
                this.setState({
                    codeData: res.data

                })
                message.success('验证码发送成功');

            } else {
                message.warning("验证码发送失败,请稍后再次发送")
            }
        })
    }
    get = () => {
        this.props.callback(1)
    }
    submit = () => {

        let phone = this.state.phone, code = this.state.code
        if (phone && code) {
            message.loading('登录中...');

            // console.log(phone, code);

            request("post", "//mall/shopUser/phone/login", {
                phone,
                code,
            }).then(res => {
              //console.log('手机号验证码登陆=>', res);
                if (res.code === 200) {

                    let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);//一天
                    cookie.save('token', (res.token), { expires: inFifteenMinutes })
                    cookie.save("shopId", res.shopId, { expires: inFifteenMinutes })

                    // this.props.history.replace("/index")
                    setTimeout(() => {

                        window.location.replace('/index')
                    }, 2000);


                } else {
                    setTimeout(() => {

                        message.warning(res.msg)
                    }, 2000);
                }
            })
        } else {
            message.warning('请输入正确的密码和验证码')
          //console.log("手机号", 15093120592);

        }
    }
    render() {
        const SendBtn = () => {
            return (<>
                {this.state.sendBtn === true ? <Button className="getcode" onClick={this.getcode} type="primary" >发送验证码</Button> : <Button className="getcode" disabled type="primary" >{this.state.times}秒后再次发送</Button>}
            </>
            )
        }
        return (
            <div className="login-form">
                <h1 className="tittle">
                颍山红商家管理后台
                </h1>
                <div className="login-form-main">
                    <h2 className="login-title">
                        登录
                    </h2>
                    <div className="form">
                        <div className="mobile">
                            <img className="user-icon" src={userIcon} alt="账号" />
                            <input placeholder="请输入手机号" onBlur={(e) => this.phoneChange(e)} />
                        </div>
                        <div className="mobile">
                            <input onChange={(e) => this.codeChange(e)} placeholder="请输入验证码" />
                            <SendBtn />
                        </div>

                    </div>
                    <div className="switch-method">
                        {/* <Button type="text">忘记密码</Button> */}
                        <Button type="text" onClick={this.get}>使用密码登录</Button>
                    </div>
                    <Button className="submit" onClick={this.submit} type="primary" >登录</Button>
                </div>
            </div>
        )
    }
}
