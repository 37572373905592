import React, { Component, } from 'react'
import Editor from './editor';
import request from '../../../../../../js/http';
import cookie from 'react-cookies'


import { Select, Button, Tag, Space, Table, Badge, message } from "antd";
import { RedoOutlined, } from "@ant-design/icons"
// import { DownloadOutlined } from "@ant-design/icons"

// import { Link, Switch, Route } from "react-router-dom";



let inputBackId;
export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            refundsList: [],
            goodsId: '',
            data: [],
            defaultOption: 'a',
            inputBackId: '',
            loading: true,
        }
    }
    componentDidMount() {
        this.refundsList(9999)
    }
    refundsList = pageSIze => {
        let shopId = cookie.load("shopId"), pageNum = '1';
        if (shopId && pageSIze && pageNum) {
            // console.log(shopId, pageSIze, pageNum);
            request("post", "/mall/orderOrder/refundList", {
                shopId: shopId,
                pageSize: pageSIze,
                pageNum: pageNum
            }).then(res => {
              //console.log('店铺退款中列表=>', res);
                if (res.code === 200) {
                    this.setState({ refundsList: res.data.list, data: res.data, loading: false })
                }
            })
        }
    }
    inputBackId = e => {
        let value = e.target.value
        if (/^[\d\w]{1,32}$/.test(value)) {
            inputBackId = value;
        } else {
            if (/\s/.test(value)) {

                message.warning("退款单号不能包含空字符")
            }
        }
        // console.log(inputBackId);
    }
    searchBackId = () => {

        if (inputBackId) {

            if (inputBackId.length <= 32 && inputBackId.length >= 1) {

                let searchedList = this.state.data.list.filter(item => {
                    return (item.backId.indexOf(inputBackId) === 0)
                })
                if (searchedList[0]) {
                    this.setState({ refundsList: searchedList })
                } else {
                    message.info(`未查找到该订单`)
                }
            } else {
                if (inputBackId) {
                    message.warning("退款编号过长")
                }
                message.info("请重新输入退款编号")

            }
        } else {
            message.warning("请输入退款编号")
        }
    }
    selectedRefundsList = (value, num) => {
        let selectedList = this.state.data.list.filter(item => {
            return item.status === num;
        })
        this.setState({ defaultOption: value, refundsList: selectedList })
    }
    handleChange = value => {
        switch (value) {
            case 'a':
                this.setState({ defaultOption: "a", refundsList: this.state.data.list })
                break;
            case 'b':
                this.selectedRefundsList(value, 0)
                break;
            case 'c':
                this.selectedRefundsList(value, 1)
                break;
            case 'd':
                this.selectedRefundsList(value, 2)
                break;
            default:
                break;
        }

    }
    //重置函数
    reset = () => {
        inputBackId = undefined;
        this.setState({
            refundsList: this.state.data.list,
            defaultOption: "a",
        })
    }
    render() {
        /////表格 字段///
        const columns = [
            { title: '退款编号', dataIndex: 'backId', key: 'backId', },
            // { title: '订单编号', dataIndex: 'orderSn', key: 'orderSn', },
            { title: '订单金额(元) ', dataIndex: 'orderPrice', key: 'orderPrice', },
            { title: '订单实付金额(元) ', dataIndex: 'payment', key: 'payment', },
            { title: '申请退款金额(元)', dataIndex: 'price', key: 'price', },
            { title: '订单邮费(元)', dataIndex: 'orderPost', key: 'orderPost', },
            { title: '退款原因', dataIndex: 'reason', key: 'reason', },
            {
                title: '商家状态', key: 'status', dataIndex: 'status', render: (text, record) => {
                    return (
                        <>
                            {
                                record.status === 0 ? <Tag color="processing">待上架</Tag> : <>{
                                    record.status === 1 ? <Tag color="green">已通过</Tag> : <Tag color="pink">已拒绝</Tag>
                                }</>
                            }
                        </>
                    )
                },
            },
            {
                title: '退款状态', dataIndex: 'prices', render: (text, record) => {
                    let statusColor = "", statusText = "";
                    switch (record.prices.status) {
                        case 0:
                            statusColor = "processing"; statusText = "退款待处理";
                            break;
                        case 1:
                            statusColor = "success"; statusText = `退款￥${record.prices.price}`;
                            break;
                        case 2:
                            statusColor = "pink"; statusText = "退款已拒绝";
                            break;
                        default:
                            break;
                    }
                    return (<Badge status={statusColor} text={statusText} />)
                },
            },
            { title: '申请时间', dataIndex: 'createAt', key: 'createAt', },
            {
                title: '操作', dataIndex: 'backId', key: 'backId', render: (text, record) => {
                    let data = this.state.refundsList.filter(item => {
                        return item.backId === record.backId
                    })
                    return (
                        <Space size="middle" className="pointer" >
                            <Editor data={data} />
                        </Space>
                    )
                },
            },
        ];
        /////表格   数据//////
        let newList = this.state.refundsList.map(item => {
            item.key = item.backId;
            item.prices = { price: item.price, status: item.status, key: item.backId }

            return item
        })
        let data = newList

        /////表格勾选
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };


        const TB = () => {
            const { Option } = Select;
            return (
                <div className="main-show order-list">
                    <div className="table-header">
                        <h1 className="table-name">退货列表</h1>
                        <div className="table-operation">
                            {/* <div className="order-num">
                                <span className="order-num-text">订单编号 :</span>
                                <input className="order-num-num" />
                            </div>
                            <div className="order-state">
                                <span >订单状态 :</span>
                                <Select defaultValue="a" style={{ width: 100, height: 35 }} onChange={handleChange}>
                                    <Option value="a">全部</Option>
                                    <Option value="g">待商议</Option>
                                    <Option value="b">待付款</Option>
                                    <Option value="c">代发货</Option>
                                    <Option value="d">待收货</Option>
                                    <Option value="e">待评价</Option>
                                    <Option value="f">已完成</Option>
                                </Select>
                            </div> */}
                        </div>
                    </div>
                    <div className="table-header">
                        {/* <h1 className="table-name">退款列表</h1> */}
                        <div className="table-operation">
                            <div className="order-num">
                                <span className="order-num-text">退款编号 :</span>
                                <input className="order-num-num" placeholder={inputBackId} onChange={e => this.inputBackId(e)} />
                            </div>
                            <div className="order-num">
                                <Button type="primary" onClick={this.searchBackId}  >搜索</Button>
                            </div>
                            <div className="order-state">
                                <span >商家状态 :</span>
                                <Select defaultValue={this.state.defaultOption} style={{ width: 100, height: 35 }} onChange={value => this.handleChange(value)} >
                                    <Option value="a">全部</Option>
                                    <Option value="b">待审核</Option>
                                    <Option value="c">已通过</Option>
                                    <Option value="d">已拒绝</Option>

                                </Select>
                            </div>
                        </div>
                        <div className="">
                            {/* <Button icon={<DownloadOutlined />} ghost type="primary">导出</Button> */}
                            <Button icon={<RedoOutlined />} className="ml50" ghost type="primary" onClick={this.reset}> 重置</Button>
                        </div>
                    </div>
                    <div className="table-content">
                        <Table columns={columns}
                            dataSource={data}
                            loading={this.state.loading}
                            pagination={{
                                showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                                total: data.length,
                                showTotal: total => `共 ${total} 条记录`,
                                defaultCurrent: 1,
                                showSizeChanger: false
                            }}
                            rowSelection={rowSelection}
                        />
                    </div>
                </div>
            )
        }
        return (
            // <Switch>
            //     {/* <Route path="/index/ptsp/detail" component={GoodsDetail} exact />
            //     <Route path="/index/ptsp/add" component={GoodsAdd} exact />
            //     <Route path="/index/ptsp/edit" component={GoodsEdit} exact /> */}
            //     <Route path="/index/tklb" component={TB} />
            // </Switch>
            <TB />

        )
    }
}
