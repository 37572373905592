import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJhXLGk/oROfxXObLpiBlkuYp9E5k2Bh\n' +
  '4zIY+GdqTWjPr/91hKnK8+FBQtblfD+jZT7oOGwTeBbQUrIkNVCWFbMCAwEAAQ=='

const privateKey = 'MIIBVQIBADANBgkqhkiG9w0BAQEFAASCAT8wggE7AgEAAkEAmFcsaT+hE5/Fc5su\n' +
  'mIGWS5in0TmTYGHjMhj4Z2pNaM+v/3WEqcrz4UFC1uV8P6NlPug4bBN4FtBSsiQ1\n' +
  'UJYVswIDAQABAkB/mTykp1wWoNN04n0j1h76ENI3h/8uUq9G7e1aSYqvB0OpmsWc\n' +
  'pxMRQ3h0jct5/r20wewFoy+JNXcEsQmFCQKJAiEAyI7W7Y3auBGQqQQVqMC6d+P1\n' +
  'za2XX2GTQqrV4pYrXR8CIQDCdBBag0RaRcBiRTWO5Rfo7Cjl/KZaTfjWZRKI2pMg\n' +
  '7QIhALc/1F//8i2nVv8njUbAumAbOReyGhJ7jFsozv8W6Q5PAiEAoHr3MCwbkkD0\n' +
  'qGWj8P2DQRj+G+jqRacKEa6yoD17pXUCICVCh9W+I5IO3djoZyljuwKi/aVw0bTr\n' +
  'azUOzUkOBVlF'

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

