import React, { Component } from 'react'
import { Route, Switch, withRouter } from "react-router-dom"
import { Button, message, Select, DatePicker, ConfigProvider } from 'antd';
import "./index.css"
import MyTable from './components/mytable'
import Detail from "./components/detail"
import cookie from 'react-cookies'
import request from '../../../../../../js/http';


let timeStart = 0, timeEnd = new Date().getTime();
let inputOrderSn;
class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderList: [],
            msg: "msg",
            name: "name",
            defaultOption: 'a',
            inputOrderSn: '',
            loading: true,
        }
    }
    // callback=(msg,name)=>{
    //     // setState方法,修改msg的值,值是由child里面传过来的
    //     this.setState({msg});
    //     this.setState({name});
    // }
    dingdanList = type => {
        // this.setState({ loading: true })
        // message.info('数据加载中...')
        request('post', '/mall/orderOrder/queryList', {
            shopId: cookie.load("shopId"),
            type: type
        }).then(res => {
          //console.log("订单列表接口=> ", res);
            if (res.code === 200) {
                // message.success('加载成功')
                this.setState({
                    orderList: res.rows,
                    loading: false
                })
            }
        })
    }

    componentDidMount() {
        // setInterval(() => {
        //   //console.log(new Date().getTime());
        // }, 1000);
        // setTimeout(()=>{
        this.dingdanList(10)
        this.props.callback(this.props.location.pathname)


        // },100)
    }

    // UNSAFE_componentWillUnmount() {
    //     this.setState = () => false
    // }
    inputOrderSn = e => {
        let value = e.target.value
        if (/^\d{1,19}$/.test(value)) {
            inputOrderSn = value;
        } else {
            if (/\s/.test(value)) {

                message.warning("订单号不能包含空字符")
            } else if (Number(value) !== Number) {
                // message.warning("")
            }
        }

    }
    searchOrder = () => {
        if (inputOrderSn) {

            if (inputOrderSn.length <= 19 && inputOrderSn.length >= 1 && isNaN(typeof Number(inputOrderSn))) {

                let selectedOrderList = this.state.orderList.filter(item => {
                    return (item.orderSn.indexOf(inputOrderSn) !== -1)
                })
                if (selectedOrderList[0]) {
                    this.setState({ orderList: selectedOrderList })
                } else {
                    message.info(`未查找到该订单`)
                }
            } else {
                if (inputOrderSn) {
                    message.warning("订单编号过长")
                }
                message.info("请重新输入订单编号")

            }
        } else {
            message.warning("请输入订单编号")
        }
    }
    handleChange = (value) => {
        // console.log(`selected ${value}`, typeof value);
        switch (value) {
            case 'a':
                // console.log(value);
                this.dingdanList(10)
                this.setState({ defaultOption: "a" })
                break;
            case 'b':
                this.dingdanList(0)
                this.setState({ defaultOption: "b" })
                break;
            case 'c':
                this.dingdanList(1)
                this.setState({ defaultOption: "c" })
                break;
            case 'd':
                this.dingdanList(2)
                this.setState({ defaultOption: "d" })
                break;
            case 'e':
                this.dingdanList(3)
                this.setState({ defaultOption: "e" })
                break;
            case 'f':
                this.dingdanList(4)
                this.setState({ defaultOption: "f" })
                break;
            case 'g':
                this.dingdanList(7)
                this.setState({ defaultOption: "g" })
                break;

            default:
                break;
        }
    }
    checkDate = (date, dateString) => {
        // console.log(date, dateString, dateString[0], dateString[1]);
        timeStart = new Date(dateString[0]).getTime()
        timeEnd = new Date(dateString[1]).getTime()
    }
    select = () => {
        if(this.state.orderList){
            let newList = this.state.orderList.filter(item => {
                return ((new Date(item.createAt).getTime() >= timeStart) && (new Date(item.createAt).getTime() <= timeEnd))
            })
            this.setState({ orderList: newList })
        }
    }
    reset = () => {
        this.dingdanList(10)

    }
    render() {
        const { Option } = Select;
        const TB = () => {
            const { RangePicker } = DatePicker;

            return (
                <div className="main-show order-list">
                    <div className="table-header">
                        <h1 className="table-name">订单列表</h1>
                        <div className="table-operation">
                            <div className="order-num">
                                <span className="order-num-text">订单编号 :</span>
                                <input className="order-num-num" defaultValue={inputOrderSn} type="number" onChange={e => this.inputOrderSn(e)} />
                            </div>
                            <div className="order-num">
                                <Button type="primary" onClick={this.searchOrder}  >搜索</Button>

                            </div>
                            <div className="order-state">
                                <span >订单状态 :</span>
                                <Select defaultValue={this.state.defaultOption} style={{ width: 100, height: 35 }} onChange={value => this.handleChange(value)} >
                                    <Option value="a">全部</Option>
                                    <Option value="b">待商议</Option>
                                    <Option value="c">待付款</Option>
                                    <Option value="d">待发货</Option>
                                    <Option value="e">待收货</Option>
                                    <Option value="f">待评价</Option>
                                    <Option value="g">已完成</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="table-operation">
                            <div className="order-state">
                                <ConfigProvider >
                                    <RangePicker onChange={this.checkDate} />
                                </ConfigProvider>
                            </div>
                            <Button onClick={this.select} type='primary' ghost >确定</Button>
                            <Button onClick={this.reset} type='primary' className='ml10' >重置</Button>
                        </div>
                    </div>
                    <div className="table-content">
                        <MyTable orderList={this.state.orderList} loading={this.state.loading} callback={this.dingdanList} />
                    </div>
                </div>
            )
        }
        return (
            <Switch>
                <Route path="/index/ddlb/detail" component={Detail} exact />
                <Route path="/index/ddlb" component={TB} />
            </Switch>

        )
    }
}
export default withRouter(Index)

