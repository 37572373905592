import React, { Component } from 'react'
import { Route, Switch, withRouter } from "react-router-dom"
import { Select, Button } from 'antd';
import { RedoOutlined } from "@ant-design/icons"
// import "./index.css"
import GoodsTable from './components/goodsTable'
import GoodsDetail from "./components/goodsDetail"
import GoodsAdd from "./components/goodsAdd"
import GoodsEdit from "./components/goodsEdit"
import cookie from 'react-cookies';
import request from '../../../../../../js/http';



// ///////平台商品页
class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            childData: '',
            goodsList: [],
            goodsLists: [],
            goodsId: '',
            dingdan: [],
            msg: "msg",
            name: "name",
            defaultClassId: 'all1',
            defaultProp: 'all2',
            defaultStatus: "all3",
            defaultCome: 'all4',
            loading: true,
            goodsClassList: [],


        }
    }
    // callback=(msg,name)=>{
    //     // setState方法,修改msg的值,值是由child里面传过来的
    //     this.setState({msg});
    //     this.setState({name});
    // }
    getClassId() {
        request('post', '/mall/goodsClass/getAllClass', {
            shopId: cookie.load('shopId'),
            pageNum: "1",
            pageSize: '99'
        }).then(res => {
          //console.log('根据大分类获取小分类=>', res);
            if (res.code === 200) {
                this.setState({ goodsClassList: res.rows })
            }
        })
    }
    getGoodsId = (goodsId) => {
        // console.log("父", goodsId);
        this.setState({ goodsId: goodsId })
        // console.log(this.state.goodsId, "----------------+++++++++++++++");
    }
    selectedList1 = (value1, value2) => {
        let audit = this.state.goodsLists.filter(item => {
            return item.className === value2
        })
        this.setState({
            goodsList: audit,
            defaultClass: value1
        })
    }
    selectedList2 = (value1, value2) => {
        let audit = this.state.goodsLists.filter(item => {
            return item.goodsProp === value2
        })
        this.setState({
            goodsList: audit,
            defaultProp: value1
        })
    }
    selectedList3 = (value) => {
        let audit = this.state.goodsLists.filter(item => {
            return item.goodsLoading === parseInt(value)
        })
        this.setState({
            goodsList: audit,
            defaultStatus: value
        })
    }
    selectedList4 = (value1, value2) => {
        let audit = this.state.goodsLists.filter(item => {
            return Boolean(item.batchId) === value2
        })
        this.setState({
            goodsList: audit,
            defaultCome: value1
        })
    }
    handleChange = (value) => {
        switch (value) {
            case "all1":
                this.setState({ goodsList: this.state.goodsLists, defaultClass: 'all1' })
                break;
            case "zdt":
                this.selectedList1(value, '子弹头')
                break;
            case "hyj":
                this.selectedList1(value, '花樱椒')
                break;
            case "all2":
                this.setState({ goodsList: this.state.goodsLists, defaultProp: 'all2' })
                break;
            case "th":
                this.selectedList2(value, '通货')
                break;
            case "jp":
                this.selectedList2(value, '精品')
                break;
            case "yz":
                this.selectedList2(value, '优质')
                break;
            case "hp":
                this.selectedList2(value, '花皮')
                break;
            case "all3":
                this.setState({ goodsList: this.state.goodsLists, defaultStatus: 'all3' })
                break;
            case "0":
                this.selectedList3(value)
                break;
            case "1":
                this.selectedList3(value)
                break;
            case "2":
                this.selectedList3(value)
                break;
            case "3":
                this.selectedList3(value)
                break;
            case "4":
                this.selectedList3(value)
                break;
            case "all4":
                this.setState({ goodsList: this.state.goodsLists, defaultCome: 'all4' })
                break;
            case '平台商品':
                this.selectedList4(value, false)
                break;
            case '仓库商品':
                this.selectedList4(value, true)
                break;
            default:
                break
        }



    }
    getPathname = value => {
        // console.log(value);
        this.props.callback(value)

    }
    componentDidMount() {
        this.props.callback(this.props.location.pathname)
        // console.log(this.props);
        // let a = []
        // if(a[0]){

        //   //console.log(true);
        // }else{
        //     console.log(false);
        // }
        this.goodsList()
        this.getClassId()
    }
    //获取商品列表 接口请求
    goodsList = () => {
        // if(shopId)
        request('post', '/mall/goodsGoods/list', {
            shopId: cookie.load('shopId'),
            // total:9999,
            pageSize: 999,
            pageNum: 1
        }).then(res => {
          //console.log("获取商品列表=>", res);
            if (res.code === 200) {
                this.setState({
                    goodsList: res.rows,
                    goodsLists: res.rows,
                    loading: false
                })
            }
        })
    }
    // UNSAFE_componentWillUnmount() {
    //     this.setState = () => false
    // }

    render() {
        ////商品分类组件///
        const ClassOption = () => {
            const { Option } = Select;
            const selectClass = (id) => {
                let audit = this.state.goodsLists.filter(item => {
                    return item.classId === id.toString()
                })
                this.setState({ goodsList: audit, defaultClassId: id })
            }
            const onChange = (value) => {
                // console.log(`selected ${value}`);
                if (value === 'all1') {
                    this.setState({ goodsList: this.state.goodsLists, defaultClassId: 'all1' })
                } else {
                    selectClass(value)
                }
            }

            // function onBlur() {
            //     console.log('blur');
            // }

            // function onFocus() {
            //     console.log('focus');
            // }

            // function onSearch(val) {
            //     console.log('search:', val);
            // }
            return (
                <Select
                    showSearch
                    style={{ width: 150 }}
                    placeholder={'选择分类'}
                    defaultValue={this.state.defaultClassId}
                    optionFilterProp="children"
                    onChange={onChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value='all1'>全部</Option>
                    {
                        this.state.goodsClassList.map(item => {
                            return (<Option value={item.classId} key={item.classId.toString()}>{item.className}</Option>)
                        })}

                </Select>
            )
        }
        const reset = () => {
            this.setState({ goodsList: this.state.goodsLists, defaultClassId: 'all1', defaultProp: 'all2', defaultStatus: 'all3', defaultCome: 'all4' })
        }
        const { Option } = Select;

        const TB = () => {
            const toGoodsAdd = () => {
                this.props.history.push({ pathname: '/index/cksp/add', })
            }
            return (
                <div className="main-show order-list">
                    <div className="table-header">
                        <h1 className="table-name">商品列表</h1>
                        <div className="table-operation">
                            <Button onClick={toGoodsAdd} >添加</Button>
                            {/* <Button className="btn-add" ><Link to="/index/cksp/add">添加</Link></Button> */}
                            {/* <Button >导出</Button> */}
                        </div>
                    </div>
                    <div className="table-header">
                        {/* <h1 className="table-name">订单列表</h1> */}
                        <div className="table-operation">
                            {/* <div className="order-num">
                                <span className="order-num-text">查询 :</span>
                                <input className="order-num-num" />
                            </div> */}
                            <div className="order-state order-num">
                                <span >种类 :</span>
                                <ClassOption />
                            </div>
                            <div className="order-state order-num">
                                <span >等级 :</span>
                                <Select defaultValue={this.state.defaultProp} style={{ width: 100, height: 35 }} onChange={(e) => this.handleChange(e)}>
                                    <Option value="all2">全部</Option>
                                    <Option value="jp">精品</Option>
                                    <Option value="yz">优质</Option>
                                    <Option value="th">通货</Option>
                                    <Option value="hp">花皮</Option>
                                </Select>
                            </div>
                            <div className="order-state order-num">
                                <span >商品状态 :</span>
                                <Select defaultValue={this.state.defaultStatus} style={{ width: 100, height: 35 }} onChange={(e) => this.handleChange(e)}>
                                    <Option value="all3">全部</Option>
                                    <Option value="0">待编辑</Option>
                                    <Option value="1">上架</Option>
                                    <Option value="2">下架</Option>
                                    <Option value="3">审核中</Option>
                                    <Option value="4">已拒绝</Option>
                                </Select>
                            </div>
                            <div className="order-state ">
                                <span >商品来源 :</span>
                                <Select defaultValue={this.state.defaultCome} style={{ width: 120, height: 35 }} onChange={(e) => this.handleChange(e)}>
                                    <Option value="all4">全部</Option>
                                    <Option value='平台商品'>平台商品</Option>
                                    <Option value='仓库商品'>仓库商品</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="">
                            {/* <Button icon={<SearchOutlined />} type="primary">查询</Button> */}
                            <Button icon={<RedoOutlined />} className="ml50" onClick={reset} type="primary"> 重置</Button>
                        </div>
                    </div>
                    <div className="table-content">
                        <GoodsTable data={this.state.goodsList} loading={this.state.loading} getGoodsId={this.getGoodsId} reloadData={this.reloadData} />
                    </div>
                </div>
            )
        }
        const GoodsDetails = () => <GoodsDetail callback={this.getPathname} />
        const GoodsAdds = () => <GoodsAdd callback={this.getPathname} />
        const GoodsEdits = () => <GoodsEdit callback={this.getPathname} />
        return (
            <Switch>
                {/* <Route path="/index/cksp/detail" component={GoodsDetails} exact />
                <Route path="/index/cksp/add" component={GoodsAdds} exact />
                <Route path="/index/cksp/edit" component={GoodsEdits} exact />
                <Route path="/index/cksp" component={TB} /> */}
                <Route path="/index/cksp/detail" component={GoodsDetails} exact />
                <Route path="/index/cksp/add" component={GoodsAdds} exact />
                <Route path="/index/cksp/edit" component={GoodsEdits} exact />
                <Route path="/index/cksp" component={TB} />
            </Switch>

        )
    }
}
export default withRouter(Index)
