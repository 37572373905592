import React, { Component, useState } from 'react'
import { withRouter } from "react-router-dom"
import { Table, Space, Button, Modal, Badge, message, Select, } from 'antd';
// import { DownOutlined } from '@ant-design/icons';
// import { Link } from "react-router-dom";
import request from '../../../../../../../../js/http'
import "./index.css"

// import request from '../../../../../../../../js/http'
import cookie from 'react-cookies'
import Item from 'antd/lib/list/Item';


let shippingCodeInput, postSelect = "a", shippingName = '顺丰速递';
let shippingTypes = "0"
class MyTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            dingdan: [],
        }
        // this.dingdandetail=this.dingdandetail.bind(this)
    }
    componentDidMount() {
        // console.log(this.props.orderList);
        this.setState({ dingdan: this.props.orderList, })

    }
    // UNSAFE_componentWillUnmount() {
    //     this.setState = () => false
    // }

    // dingdanList = () => {
    //     request('post', '/mall/orderOrder/queryList', {
    //         shopId: cookie.load("shopId"),
    //         type: 10
    //     }).then(res => {
    //       //console.log("订单列表接口=>", res);
    //         if (res.code === 200) {
    //             this.setState({
    //                 dingdan: res.rows,
    //                 loading:false
    //             })
    //         }
    //     })
    // }

    dingdandetail(e) {
        let orderId = e.key
        this.props.history.push({ pathname: '/index/ddlb/detail', state: { orderId } })
    }

    start = () => {
        this.setState({ loading: true });
        // ajax request after empty completing
        // setTimeout(() => {
        this.setState({
            selectedRowKeys: [],
            loading: false,
        });
        // }, 1000);
    };
    shippingCodeInput = e => {
        let value = e.target.value
        if (/^\w{1,32}$/.test(value) && value) {
            shippingCodeInput = value;
        } else {
            if (/\s/.test(value)) {
                message.warning("物流单号不能包含空字符")
            } else {
                message.warning("物流单号长度有误")
            }

        }
    }
    /////导出选中数据
    exportData= ()=>{
        let list = this.state.selectedRowKeys
        let data = {}
        if(list.length>=1){
            //调接口
            request('post', '/index' , {data},).then(res=>{
                if(res.code === 200){

                }
            })
            message.loading('加载中...')
        }else{
            message.warning('请选择您要导出的数据')
        }
    }
    handleChange = value => {
        // console.log(value);
        switch (value) {
            case 'a':
                postSelect = value
                shippingName = "顺风速运"
                break;
            case 'b':
                postSelect = value
                shippingName = "京东快递"
                break;
            case 'c':
                postSelect = value
                shippingName = "申通快递"
                break;
            case 'd':
                postSelect = value
                shippingName = "圆通速递"
                break;
            case 'e':
                postSelect = value
                shippingName = "韵达快递"
                break;
            case 'f':
                postSelect = value
                shippingName = "中通速递"
                break;
            case 'g':
                postSelect = value
                shippingName = "百世汇通"
                break;
            case 'h':
                postSelect = value
                shippingName = "天天快递"
                break;
            default:
                break;
        }
        // console.log(shippingName);
    }
    render() {

        ///////////////时间格式转换函数//////////
        function newTime(ordTime) {
            var date = new Date(ordTime);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = date.getDate() + ' ';
            let h = date.getHours() + ':';
            let m = date.getMinutes() + ':';
            let s = date.getSeconds();
            return Y + M + D + h + m + s;
        }
        ///////////////取消订单对话框//////////
        const App = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);

            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <span onClick={showModal}>
                        取消
                    </span>
                    <Modal className="modal-confirm" title="提示" cancelText="取消" okText="确认" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p className="confirm-text">确定要取消该订单吗?</p>

                    </Modal>
                </>
            );
        };

        ///发货对话框
        const Deliver = ({ data }) => {
            /// 选择器↓↓↓↓↓↓↓↓↓↓↓↓↓↓/////////////////////////////////////////////
            const { Option } = Select;
            function handleChange(value) {
              //console.log(`selected ${value}`);
                shippingTypes = value


            }
            const [isModalVisible, setIsModalVisible] = useState(false);

            const showModal = () => {
                setIsModalVisible(true);
            };

            const handleOk = () => {
                setIsModalVisible(false);
                let shopId = cookie.load("shopId"), orderId = data.orderId, shippingCode = shippingCodeInput, shippingType = shippingTypes
                // console.log(shopId, orderId, shippingCode, shippingName,shippingType);
                // console.log(shopId, orderId, shippingCode, shippingName,shippingType);
                if (shopId && orderId && shippingCode && shippingName && shippingType) {
                    // console.log(666);
                    request("post", "/mall/orderOrder/deliver", {
                        shopId,
                        orderId,
                        shippingName,
                        shippingCode,
                        shippingType,
                    }).then(res => {
                      //console.log("店铺订单发货=>", res);
                        if (res.code === 200) {
                            message.success("发货信息添加成功")
                            this.props.callback(10)
                            
                        }
                    })
                } else {
                  //console.log(shopId, orderId, shippingCode, shippingName, shippingType);

                    message.warning('请填写完整的物流信息')
                }
            };

            const handleCancel = () => {
                setIsModalVisible(false);
            };
            let status = this.state.status
            // let orderSn = 0 
            // console.log( this.state.orderDetail.orderSn);
            const haha = () => {
                message.info("等待买家支付...")

            }
            return (
                <>
                    <span style={{ color: 'red' }} className='pointer' onClick={showModal}> 发货 </span>
                    {/* <span type="primary" onClick={status === 2 ? showModal : haha}>{status === 2 ? "添加发货信息" : '等待支付...'}</span> */}
                    <Modal title="添加发货信息" width={800} visible={isModalVisible} okText="确认" cancelText="取消" onOk={handleOk} onCancel={handleCancel}>
                        <div className="shipping-info-add">
                            <div className="div">
                                <span className="span">订单编号：</span><input className="input" disabled defaultValue={data.orderSn} />
                            </div>
                            <div className="div">
                                <span className="span">物流类型：</span>
                                <Select defaultValue={shippingTypes} style={{ width: 350, height: 40 }} onChange={handleChange}>
                                    <Option value="0">公司</Option>
                                    <Option value="1">个人</Option>
                                </Select>
                            </div>
                            <div className="div">
                                <span className="span">物流单号：</span><input className="input" onBlur={e => this.shippingCodeInput(e)} placeholder="请填写物流单号" />
                            </div>
                            <div className="div">
                                <span className="span">物流公司：</span>
                                <Select defaultValue={postSelect} style={{ width: 350, height: 40 }} onChange={value => this.handleChange(value)}>
                                    <Option value="a">顺丰速运</Option>
                                    <Option value="b">京东快递</Option>
                                    <Option value="c">申通快递</Option>
                                    <Option value="d">圆通速递</Option>
                                    <Option value="e">韵达快递</Option>
                                    <Option value="f">中通速递</Option>
                                    <Option value="g">百世汇通</Option>
                                    <Option value="h">天天快递</Option>
                                </Select>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        };



        const onSelectChange = selectedRowKeys => {
            // console.log('selectedRowKeys changed: ', selectedRowKeys);
            this.setState({ selectedRowKeys });
        };
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };
        // const hasSelected = selectedRowKeys.length > 0;
        //嵌套表格//////
        let key = 0
        ////外层表格数据
        let dataFather = this.state.dingdan.map(item => {
            item.key = `${key}`
            // item.goodsList.map(it => {
            //     it.key = `${key}${key}`
            // })
            item.createAt = newTime(item.createAt)
            key++
            return item;
        })
        let dingdandetail = (e) => {
            // console.log(e.orderId);
            // console.log(111);
            let orderId = e.orderId
            // console.log(orderId);
            this.props.history.push({ pathname: '/index/ddlb/detail', state: { orderId, from: 'index/ddlb' } })
        }
        let orderCancel = () => {
            // console.log("哈哈哈哈");
            // console.log(this.state.dingdan);
        }
        let NestedTable = () => {
            ///////父级表格字段
            const columns = [
                { title: '订单编号', dataIndex: 'orderSn', key: 'orderSn' },
                {
                    title: '下单时间', dataIndex: 'createAt', key: 'createAt', 
                },
                {
                    title: '收货人信息',  key: 'createAt',render: (text, record) => {
                        return <span > {record.receiverName+record.receiverPhone+record.receiverProvince+record.receiverCity+record.receiverArea+record.receiverAddress} </span>
                    }, 
                },
                { title: '订单金额/元', dataIndex: 'orderPrice', key: 'orderPrice' },
                { title: '实付金额/元', dataIndex: 'payment', key: 'payment' },
                {
                    title: '订单状态', dataIndex: 'status', key: 'status', render: (text, record) => {
                        let statusColor = "", statusText = "";
                        switch (record.status) {
                            case 0:
                                statusColor = "error"; statusText = "待商议";
                                break;
                            case 1:
                                statusColor = "warning"; statusText = "待付款";
                                break;
                            case 2:
                                statusColor = "processing"; statusText = "待发货";
                                break;
                            case 3:
                                statusColor = "success"; statusText = "待收货";
                                break;
                            case 4:
                                statusColor = "pink"; statusText = "待评价";
                                break;
                            case 7:
                                statusColor = "default"; statusText = "已完成";
                                break;
                            default:
                                break;
                        }
                        return (<Badge status={statusColor} text={statusText} />)
                    },
                },
                {
                    title: '操作', key: 'operation', render: (text, record) => (
                        <Space size="middle" style={{ marginLeft: "0", textAlign: "right" }}>
                            <span onClick={() => dingdandetail(text)} style={{ color: 'blue', cursor: 'pointer' }}>详情</span>
                            {/* <Link to="#" onClick={this.dingdandetail(text)}>详情</Link> */}
                            <span style={{ color: "#000", cursor: "pointer" }} onClick={orderCancel}><App data={record} /></span>
                            {/* <span style={{color:'red'}}> 发货 </span> */}
                            {record.status === 2 ? <Deliver data={record} /> : null}
                        </Space>
                    )
                },
            ];
            // /////父级表格数据
            let data = dataFather;
            data = data.map(item=>{
                item.key = item.orderId
                return item
            })

            return (
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    rowSelection={rowSelection}
                    //////子表格////
                    expandable={{
                        childrenColumnName: '666',
                        expandedRowRender: (record, index, indent, expanded) => {
                            // 子表格字段
                            const columns = [
                                { title: '商品名称', dataIndex: 'goodsName', key: 'goodsName' },
                                { title: '单位', dataIndex: 'productUnit', key: 'productUnit' },
                                { title: '规格(单位净重)', dataIndex: 'productWeight', key: 'productWeight' },
                                { title: '购买数量', dataIndex: 'total', key: 'total' },
                                { title: '单价/元', dataIndex: 'price', key: 'price' },
                                { title: '总价/元', dataIndex: 'allPrice', key: 'allPrice' },
                            ];
                            let key = 1;
                            let data = record.goodsList.map(item => {
                                item.key = key
                                key++
                                return item
                            })
                            return <Table columns={columns} dataSource={data} pagination={false} />
                        }
                    }}
                    dataSource={data}
                    loading={this.props.loading}
                    pagination={{
                        showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                        total: dataFather.length,
                        showTotal: total => `共 ${total} 条记录`,
                        defaultCurrent: 1, showSizeChanger: false
                    }}
                />
            );
        }

        ///////////////////////////////////////////////////////
        return (
            <div >
                <NestedTable />
                <div className="table-foot">
                    <div className="foot-left">
                    <Button className="table-export" ghost type="primary" onClick={this.exportData}>导出</Button>
                        {/* <Button className="table-import" type="text">导入-批量发货</Button>
                        // <Button className="table-export" ghost type="primary">导出</Button> */}
                        {/* <input  type="flie" accept='.xlsx, .xls'/> */}
                    </div>
                    <div className="foot-right">
                        {/* <span className="table-num">共{dataFather.length}条记录</span>
                        <div className="to-page">
                            到第
                            <input type="" placeholder="1" />
                            页
                            <Button type="primary" className="jump">GO</Button>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(MyTable)
