import React, { Component } from 'react'
import {  Switch } from 'antd';

import "./index.css"
import '../shezhi/index.css'

export default class index extends Component {
    render() {
        ////////////开关//////////↓↓↓↓↓↓↓↓↓↓↓
        function onChange1(checked) {
          //console.log(`switch to ${checked}`);
        }
        function onChange2(checked) {
          //console.log(`switch to ${checked}`);
        }
        function onChange3(checked) {
          //console.log(`switch to ${checked}`);
        }
        ////////////开关↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
        return (
            <div className="main-show">
                <div className="full">

                    <div className="tool-title">
                        <h1>客服工具</h1>
                    </div>

                    <div className="reply-setting-contain">
                        <div className="reply-setting h110">
                            <div className="reply-setting-left">
                                <div>智能卖点</div>
                                <div className="fs16c90">
                                    <p>根据该商品的特征，智能生成的商品卖点 </p>
                                </div>
                            </div>
                            <div className="reply-setting-right">
                                <Switch defaultChecked onChange={onChange1} />
                            </div>
                        </div>
                    </div>
                    <div className="reply-setting-contain">
                        <div className="reply-setting h110">
                            <div className="reply-setting-left">
                                <div>活动优惠</div>
                                <div className="fs16c90">
                                    <p>若有多张优惠券满足条件时，会根据买家特征智能选择发送；若无优惠券，则会透出商品参与的活动</p>
                                    <p>自动同步店铺宝中活动数据</p>
                                </div>
                            </div>
                            <div className="reply-setting-right">
                                <Switch defaultChecked onChange={onChange2} />
                            </div>
                        </div>
                    </div>
                    <div className="reply-setting-contain">
                        <div className="reply-setting h110">
                            <div className="reply-setting-left">
                                <div>智能卖点</div>
                                <div className="fs16c90">
                                    <p>根据该商品的特征，智能生成的商品卖点 </p>
                                </div>
                            </div>
                            <div className="reply-setting-right">
                                <Switch  onChange={onChange3} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
