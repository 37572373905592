import React, { Component, useState } from 'react'
import request from '../../../../../../../../js/http'
import cookie from 'react-cookies';
import { message, Modal, } from 'antd'

let orderPost;
export default class PriceModify extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: '',
            orderPost:'',
        }
    }
    componentDidMount() {
        this.setState({ orderId: this.props.info[0].orderId })
    }
    orderPostInput = e => {
        orderPost = e.target.value
    }
    render() {
        const App = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
                // console.log(orderPost);
                if(orderPost){
                    request("post", "/mall/orderOrder/editPrice", {
                        shopId: cookie.load('shopId'),
                        orderId: this.state.orderId,
                        orderPost
                    }).then(res => {
                      //console.log('商议订单添加运费=>', res);
                        if (res.code === 200) {
                            message.success("运费修改成功")
                            window.location.reload()
                           this.setState({orderPost})
                        }
                    })
                }
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <span type="primary" onClick={showModal}>
                        修改
                </span>
                    {this.props.info[0].status === 0 ?


                        <Modal className="modal-modify" width={800} title="费用修改" visible={isModalVisible} okText="确认" cancelText="取消" onOk={handleOk} onCancel={handleCancel}>
                            <div className="shipping-info-add">
                                <div className="div">
                                    <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>商品合计：</span><input className="input" defaultValue={'￥' + this.props.info[0].detailList[0].allPrice} />
                                </div>
                                <div className="div">
                                    <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>活动优惠：</span><input className="input" defaultValue="￥0.00" />
                                </div>
                                <div className="div">
                                    <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>优惠券：</span><input className="input" defaultValue="￥0.00" />
                                </div>
                                <div className="div">
                                    <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>付款金额：</span><input className="input" defaultValue={'￥' + this.props.info[0].payment} />
                                </div>
                                <div className="div">
                                    <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>*运费*：</span><input className="input"  onBlur={e => this.orderPostInput(e)} defaultValue={'￥' + this.state.orderPost ===''?this.props.info[0].orderPost: this.state.orderPost} />
                                </div>
                            </div>
                        </Modal>
                        : <></>
                    }
                </>
            );
        };
        return (
            <div className="modify">
                <App></App>
            </div>
        )
    }
}
