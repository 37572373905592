import React, { Component } from 'react'
import PasswordLogin from './compnents/passwordlogin'
import CodeLogin from './compnents/codelogin'
// import {message} from 'antd';
// import cookie from 'react-cookies'

import './index.css'

export default class Login extends Component {
    
    state ={index:1 }
    callback2=(m)=>{
        // setState方法,修改msg的值,值是由child里面传过来的
        this.setState({index:m})
        // console.log(this.state.index);
    }
    render() {
        const {index}=this.state
        // console.log(index);
        return (
            <div className="login">
                {
                    index===1? <PasswordLogin callback={this.callback2}/>: <CodeLogin callback={this.callback2}/>
                }
            </div>
        )
    }
}
