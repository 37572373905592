import React, { Component, useState } from 'react'
import { Button,  Tag, Space, Table, Modal } from "antd";
// import { RedoOutlined, DownloadOutlined, } from "@ant-design/icons"

import { Link} from "react-router-dom";


// const { Option } = Select;
// function handleChange(value) {
//     console.log(`selected ${value}`);
// }

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            refundsList: [],
            goodsId: '',
        }
    }
    render() {
        ///////////////取消订单对话框//////////
        const App = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <span onClick={showModal}>删除</span>
                    <Modal className="modal-confirm"
                        title="提示" cancelText="取消"
                        okText="确认"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <p className="confirm-text">确定要删除吗?</p>
                    </Modal>
                </>
            );
        };
        /////表格 字段///
        const columns = [
            { title: '商品ID', dataIndex: 'goodsId', key: 'goodsId', },
            { title: '商品名称', dataIndex: 'goodsName', key: 'goodsName', },
            { title: '种类 ', dataIndex: 'className', key: 'className', },
            { title: '等级', dataIndex: 'goodsProp', key: 'goodsProp', },
            {
                title: '状态', key: 'goodsLoading', dataIndex: 'goodsLoading', render: (text, record) => {
                    return (
                        <>
                            {
                                record.goodsLoading === 0 ? <Tag color="#f50">待上架</Tag> : <>{
                                    record.goodsLoading === 1 ? <Tag color="#87d068">上架</Tag> : <Tag color="#2db7f5">下架</Tag>}</>
                            }
                        </>
                    )
                },
            },
            {
                title: '操作', dataIndex: 'goodsId', key: 'action', render: (text, record) => (
                    <Space size="middle">
                        {/* <Button onClick={(e) => (this.passIdToView(record.goodsId))} ><Link to={{ pathname: "/index/ptsp/detail", state: { goodsList: this.state.goodsList, goodsId: record.goodsId } }}>查看</Link></Button> */}
                        <Link to="/index/ptsp/edit">编辑</Link>
                    </Space>
                ),
            },
        ];
        /////表格   数据//////
        // let newList = this.state.goodsList.map(item => {
        //     item.key = item.goodsId;
        //     return item
        // })
        // const data = newList
        let datas = [{
            key: `001`,
            goodsId: `021`,
            goodsName: "看,辣椒",
            className: "可以吃",
            goodsProp: "p, 上等品",
            goodsLoading: 2,
        }];
        for (let i = 0; i < 50; i++) {
            datas.push({
                key: i,
                goodsId: `${i}021`,
                goodsName: "看,辣椒",
                className: "可以吃sdfsdf",
                goodsProp: "p, 上等品",
                goodsLoading: '656565',
            })
        }
        let data = datas

        /////表格勾选
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div className="main-show order-list">
                {/* <div className="table-header">
                        <h1 className="table-name">行情列表</h1>
                        <div className="table-operation"> */}
                {/* <div className="order-num">
                                <span className="order-num-text">订单编号 :</span>
                                <input className="order-num-num" />
                            </div>
                            <div className="order-state">
                                <span >订单状态 :</span>
                                <Select defaultValue="a" style={{ width: 100, height: 35 }} onChange={handleChange}>
                                    <Option value="a">全部</Option>
                                    <Option value="g">待商议</Option>
                                    <Option value="b">待付款</Option>
                                    <Option value="c">代发货</Option>
                                    <Option value="d">待收货</Option>
                                    <Option value="e">待评价</Option>
                                    <Option value="f">已完成</Option>
                                </Select>
                            </div> */}
                {/* </div>
                    </div> */}
                <div className="table-header">
                    <h1 className="table-name">行情列表</h1>
                    {/* <div className="table-operation">
                            <div className="order-num">
                                <span className="order-num-text">退款编号 :</span>
                                <input className="order-num-num" />
                            </div>
                            <div className="order-state">
                                <span >商家审核 :</span>
                                <Select defaultValue="a" style={{ width: 100, height: 35 }} onChange={handleChange}>
                                    <Option value="a">全部</Option>
                                    <Option value="g">待审核</Option>
                                    <Option value="b">已通过</Option>
                                    <Option value="c">已拒绝</Option>
                                   
                                </Select>
                            </div>
                        </div> */}
                    <div className="">
                        <Button className="ml50" type="primary">发布行情</Button>
                    </div>
                </div>
                <div className="table-content">
                    <Table columns={columns}
                        dataSource={data}
                        pagination={{
                            showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                            total: data.length,
                            showTotal: total => `共 ${total} 条记录`,
                            defaultCurrent: 2,
                            showSizeChanger: false
                        }}
                        rowSelection={rowSelection}
                    />
                    <div className="table-foot">
                        <div className="foot-left">
                            <Button className="table-import" type="text"><App /></Button>
                            {/* <Button className="table-export" ghost type="primary">导出</Button> */}
                        </div>
                        <div className="foot-right">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
