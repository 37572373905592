import React, { Component, useState } from 'react'
import { withRouter } from "react-router-dom";

import { Button, Modal, Select, message, Cascader, Statistic, Col } from 'antd';
import city from './city';
// import { PlusOutlined } from '@ant-design/icons';
import { LikeOutlined } from '@ant-design/icons';
import request from '../../../../../../js/http';
import cookie from 'react-cookies';
import UploadImg from './uploadImg';


// let data = {
//     hasSpec: 0,
//     goodsProp: '精品',
//     productList: [
//         { productDefault: 0 },
//         // { productDefault: 0 },
//     ],
//     goodsParam: [
//         {}
//     ],
// };
let datas = {

}
class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productPrice: '',
            goodsProduct: {},
            data: {},
            previewVisible: false,
            previewImage: '',
            previewTitle: '66666',
            fileList: [],
            bannerList: [],
            goodsImg: [],
            goodsClassList: [],
            /////////////////////////////////////////////////

            shopInfo: {},
            shopLogo: [],
        }
    }

    getShopInfo() {
        if (cookie.load('shopId')) {

            let a = cookie.load('shopId')
            request('get', `/mall/shopShop/${a}`, {

            }).then(res => {
              //console.log('获取店铺信息详细信息=>', res);
                if (res.code === 200) {
                    this.setState({ shopInfo: res.data })

                    datas.shopLogo = res.data.shopLogo


                    ////显示已有的店铺logo
                    if (res.data.shopLogo) {
                        let newList = [{ uid: '-11', url: res.data.shopLogo, status: "done", name: 'image.png', }]
                        this.setState({ shopLogo: newList })
                        // console.log(res.data.goodsImg);
                        // console.log(newList);
                    }

                    datas.shopName = res.data.shopName
                    datas.shopMain = res.data.shopMain
                    datas.shopDesc = res.data.shopDesc
                    datas.shopNotice = res.data.shopNotice
                    datas.shopProvince = res.data.shopProvince
                    datas.shopCity = res.data.shopCity
                    datas.shopArea = res.data.shopArea
                    datas.shopAddress = res.data.shopAddress


                    ////显示已有的店铺图组图组
                    // if (res.data.bannerList) {
                    //     let newList = []
                    //     for (let i = 0; i < res.data.bannerList.length; i++) {
                    //         if (res.data.bannerList[i].imgurl) {

                    //             let a = { uid: i, url: res.data.bannerList[i].imgurl ? res.data.bannerList[i].imgurl : '', name: 'image.png', status: 'done' }
                    //             newList.push(a);
                    //         }
                    //         // console.log(a);
                    //         // console.log(newList);
                    //         // console.log(res.data.bannerList);    
                    //     }

                    //     this.setState({ fileList: newList })
                    // }
                }
            })
        }
    }
    shopNameInput = e => {
        datas.shopName = e.target.value
    }
    componentDidMount() {
        // console.log(this.props.location.pathname);
        this.props.callback(this.props.location.pathname)


        this.getShopInfo()
        this.getClassId()
        // if (this.props.location.state) {
        //     let id = this.props.location.state.goodsId
        //     this.getGoodsDetail(id)
        // }
    }

    getImg(value) {
      //console.log(value, '收到了');
        // console.log(value);
        datas.shopLogo = value
        // console.log(datas);
    }

    getClassId() {
        request('post', '/mall/goodsClass/getAllClass', {
            shopId: cookie.load('shopId'),
            pageNum: "1",
            pageSize: '99'
        }).then(res => {
          //console.log('根据大分类获取小分类=>', res);
            if (res.code === 200) {
                this.setState({ goodsClassList: res.rows })
            }
        })
    }
    shopAddressInput(e) {
        datas.shopAddress = e.target.value
    }

    shopDescInput(e) {
        datas.shopDesc = e.target.value
    }
    shopNoticeInput(e) {
        datas.shopNotice = e.target.value
    }
    shopInfoEdit = () => {
        // console.log(datas);
    }
    render() {
        //////////////单选组件
        // const CheckOne = () => {
        //     const setDefult = (e) => {
        //         // console.log(e.target.value);
        //         data.productList[0].productDefault = e.target.value
        //     }
        //     return (
        //         <Radio.Group name="radiogroup" defaultValue={data.productList[0].productDefault} onChange={setDefult}>
        //             <Radio value={1}>是</Radio>
        //             <Radio value={0}>否</Radio>
        //         </Radio.Group>
        //     )
        // };


        ////主营辣椒品种组件///
        const ClassOption = () => {
            const { Option } = Select;
            const onChange = (value) => {
                // console.log(`selected ${value}`);
                let name = this.state.goodsClassList.filter(item => {
                    return item.classId === value
                })
                if (name.length) {
                    datas.shopMain = name[0].className
                }
            }
            return (
                <Select
                    showSearch
                    style={{ width: 200 }}
                    // placeholder={this.state.data.className}
                    defaultValue={this.state.shopInfo.shopMain}
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {this.state.goodsClassList.map(item => {
                        return (<Option value={item.classId} key={item.classId.toString()}>{item.className}</Option>)
                    })}

                </Select>
            )
        }
        ////商品等级组件///
        const ShopType = () => {
            const { Option } = Select;
            const onChange = (value) => {
                // console.log(`selected ${value}`);
                switch (value) {
                    case 0:
                        datas.shopType = value;
                        // console.log(datas);
                        break;
                    case 1:
                        datas.shopType = value;
                        // console.log(datas);
                        break;
                    case 2:
                        datas.shopType = value;
                        // console.log(datas);
                        break;
                    default:
                        break;
                }
            }
            return (
                <Select style={{ width: 200 }} onChange={onChange} defaultValue={this.state.shopInfo.shopType}>
                    <Option value={0} >个人</Option>
                    <Option value={1} >企业</Option>
                    <Option value={2} >经纪人</Option>
                </Select>
            )
        }
        ///////////////修改店铺信息对话框//////////
        const Apps = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
                let { shopName, shopAddress, shopProvince, shopCity, shopArea, shopDesc, shopNotice, shopLogo, shopMain } = datas;

                // goodsParam = JSON.stringify(goodsParam)
                request('put', '/mall/shopShop', {
                    shopName, shopAddress, shopProvince, shopCity, shopArea, shopDesc, shopNotice, shopLogo, shopMain, shopId: cookie.load('shopId')
                }).then(res => {
                  //console.log("修改店铺信息=>", res);
                    if (res.code === 200) {
                        message.success('店铺信息修改成功')
                        this.getShopInfo()

                    } else {
                        message.warning('修改失败')
                    }
                })
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                <Button type="primary" className="w100" onClick={showModal}>提交修改</Button>
                    {/* <span onClick={showModal}>提交修改</span> */}
                    <Modal className="modal-confirm"
                        title="提示" cancelText="取消"
                        okText="确认"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <p className="confirm-text">确认修改店铺信息?</p>
                    </Modal>
                </>
            );
        };

        ////////////////////////////照片墙上传组件↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
        // function getBase64(file) {
        //     return new Promise((resolve, reject) => {
        //         const reader = new FileReader();
        //         reader.readAsDataURL(file);
        //         reader.onload = () => resolve(reader.result);
        //         reader.onerror = error => reject(error);
        //     });
        // }
        // //图片预览函数   ///
        // const handlePreview = async file => {
        //     if (!file.url && !file.preview) {
        //         file.preview = await getBase64(file.originFileObj);
        //     }

        //     this.setState({
        //         previewImage: file.url || file.preview,
        //         previewVisible: true,
        //         previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        //     });
        // };
        // ///////关闭图片预览对话框状态
        // const handleCancel = () => this.setState({ previewVisible: false });
        // //////上传文件改变时的状态
        // const handleChange = ({ fileList }) => {
        //     this.setState({ fileList });
        //     if (fileList.length) {
        //         if (fileList[fileList.length - 1].status === 'done') {
        //             // console.log();
        //             let list = []
        //             fileList.map(item => {
        //                 let i = {}
        //                 i.imgurl = item.response ? item.response.data : item.url
        //                 list.push(i)
        //                 // console.log(list);
        //                 return item
        //             })
        //             data.bannerList = list;
        //             // console.log(data.bannerList);
        //         }
        //     }
        // }
        // /// 组件所需要的变量
        // const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        // //////添加图片按钮
        // const uploadButton = (
        //     <div>
        //         <PlusOutlined />
        //         <div style={{ marginTop: 8 }}>添加图片  </div>
        //     </div>
        // );
        ////////////////////////////照片墙上传组件↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
        ///////////////////地址选择组件/////////////////
        const CheckShopAddress = () => {
            const { shopProvince, shopCity, shopArea } = this.state.shopInfo
            // console.log(shopProvince,shopCity,shopArea);
            let address = []
            address.push(shopProvince, shopCity, shopArea)
            const options = city
            function onChange(value) {

                datas.shopProvince = value[0]
                datas.shopCity = value[1]
                datas.shopArea = value[2]

            }
            return <Cascader options={options} onChange={onChange} defaultValue={address} style={{ width: 300 }} />
        }
        return (
            <div className="main-show order-list">
                <div className="table-header">
                    <h1 className="table-name">店铺信息</h1>
                    <div className="table-operation">
                        {/* <Button >导出</Button> */}
                    </div>
                </div>
                <div className="pd20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">店铺名称 :</span>
                        <input className="goodsinfo-text color-black " defaultValue={this.state.shopInfo.shopName} onBlur={this.shopNameInput} />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">店铺类型 :</span>
                        <ShopType className="goodsinfo-text" />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                {/* 上传店铺logo */}
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">店铺logo :</span>
                        <div className="goodsinfo-imgs-area" >
                            <UploadImg getImg={this.getImg} imgList={this.state.shopLogo} />

                        </div>
                    </div>
                </div>
                <div className="pd20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">联系方式 :</span>
                        <input className="goodsinfo-text  " defaultValue={this.state.shopInfo.shopPhone} />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">主营品种 :</span>
                        <ClassOption className="goodsinfo-text color-black " />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">店铺简介 :</span>
                        <textarea className="goodsinfo-text color-black" style={{ resize: 'none', lineHeight: 2 }} defaultValue={this.state.shopInfo.shopDesc} onBlur={this.shopDescInput} />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">店铺描述 :</span>
                        <textarea className="goodsinfo-text color-black" style={{ resize: 'none', lineHeight: 2 }} defaultValue={this.state.shopInfo.shopNotice} onBlur={this.shopNoticeInput} />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">店铺信誉 :</span>
                        {/* <textarea className="goodsinfo-text color-black" style={{ resize: 'none', lineHeight: 2 }}  defaultValue={this.state.shopInfo.shopNotice} onBlur={this.titleInput} /> */}
                        {/* <Rate disabled defaultValue={this.state.shopInfo.shopStar} /> */}
                        {/* <Row gutter={160}> */}
                        <Col span={120}>
                            <Statistic title="信誉值" value={this.state.shopInfo.shopStar} prefix={<LikeOutlined />} />
                        </Col>
                        {/* <Col span={12}>
                                <Statistic title="Unmerged" value={93} suffix="/ 100" />
                            </Col> */}
                        {/* </Row>, */}
                    </div>
                </div>
                {/* 地址------------- */}
                <div className="pd20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">经营地址 :</span>
                        {/* <input className="goodsinfo-text  " defaultValue={this.state.data.goodsName} onBlur={this.nameInput} /> */}
                        <CheckShopAddress />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">详细地址 :</span>
                        <input className="goodsinfo-text  " defaultValue={this.state.shopInfo.shopAddress} onBlur={this.shopAddressInput} />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                {/* <div className="pd20">
                    <div className="sale-item">
                        <span className="sale-item-title"> <span style={{ color: '#FF5858' }}>*</span> 是否缴纳保证金 :</span>
                        <CheckOne />
                    </div>
                </div> */}
                <div className="pd20 df-jcc" >
                    <Button className="w100" type="primary" ghost >取消</Button>
                    <Apps />
                </div>
            </div>
        )
    }
}
export default withRouter(Index)
