import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Spin,  } from 'antd';
// import { Table } from 'antd';
// import cookie from 'react-cookies';

import request from '../../../../../../js/http';


class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderList: [],
            msg: "msg",
            name: "name",
            defaultOption: 'a',
            inputOrderSn: '',
            loading: true,
            List: [],
            data: {},
        }
    }

    componentDidMount() {
        this.props.callback(this.props.location.pathname)
        // this.getData(cookie.load('shopId'), 1, 10)

    }
    getData = (id, num, size,) => {
        let shopId = id
        let pageNum = num
        let pageSize = size
        request('post', '/mall/orderOrder/queryList', {
            shopId, pageNum, pageSize
        }).then(res => {
          //console.log(res);
            this.setState({ data: res.data })

        })
    }
    render() {
        // const columns = [
        //     { title: '订单编号', dataIndex: 'orderSn', key: 'orderSn' },
        //     { title: '下单时间', dataIndex: 'createAt', key: 'createAt' },
        //     { title: '订单金额/元', dataIndex: 'orderPrice', key: 'orderPrice' },
        //     { title: '实付金额/元', dataIndex: 'payment', key: 'payment' },
        //     { title: '订单状态', dataIndex: 'status', key: 'status',  },
        // ];
        // const data = this.state.data.rows
        return (
            <div className="main-show">
                {/* 在线客服 */}
                <div className="loading">
                    <div className="loading-center">
                        <Spin />
                    </div>
                </div>


                {/* <Table columns={columns} dataSource={data} /> */}
            </div>
        )
    }
}
export default withRouter(Index)
