import React, { Component, } from 'react'
import { Table, Tag, Space, Button, } from 'antd';
import { Link, } from "react-router-dom";

// import request from '../../../../../../../../js/http'
// import cookie from 'react-cookies'

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            goodsList: [],
            goodsId: '',
            loading: true,
            selectedRowKeys: [], // Check here to configure the default column

        }
    }
    componentDidMount() {
        // console.log(this.props);
        // this.goodsList()
        this.setState({
            goodsList: this.props.data
        })

        // function newTime(ordTime) {
        //     var date = new Date(ordTime);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        //    let Y = date.getFullYear() + '-';
        //    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        //    let D = date.getDate() + ' ';
        //    let h = date.getHours() + ':';
        //    let m = date.getMinutes() + ':';
        //    let s = date.getSeconds();
        //     return Y + M + D + h + m + s;
        // }
    }
    // componentWillUnmount() {
    //     this.setState = (state, callback) => {
    //         return
    //     }
    // }
    passIdToView = e => {
        // window.location.href="/index/ptsp/detail"
        this.props.getGoodsId(e)
      //console.log('子table', e);
        this.setState({ goodsId: e })
    }
    //获取商品列表 接口请求
    goodsList = () => {
        // request('post', '/mall/goodsGoods/list', {

        // }).then(res => {
        //     console.log("获取商品列表=>",res);
        //     if (res.code === 200) {
        //         this.setState({
        //             goodsList: res.rows
        //         })
        //     }
        // })
    }


    //--------------------------------------------------------------

    render() {
        ///////////////取消订单对话框//////////
        // const App = () => {
        //     const [isModalVisible, setIsModalVisible] = useState(false);
        //     const showModal = () => {
        //         setIsModalVisible(true);
        //     };
        //     const handleOk = () => {
        //         setIsModalVisible(false);

        //         alert("您无权限")
        //     };
        //     const handleCancel = () => {
        //         setIsModalVisible(false);
        //     };
        //     return (
        //         <>
        //             <span onClick={showModal}>删除</span>
        //             <Modal className="modal-confirm"
        //                 title="提示" cancelText="取消"
        //                 okText="确认"
        //                 visible={isModalVisible}
        //                 onOk={handleOk}
        //                 onCancel={handleCancel}
        //             >
        //                 <p className="confirm-text">确定要删除吗?</p>
        //             </Modal>
        //         </>
        //     );
        // };
        /////表格 字段///
        const columns = [
            { title: '商品ID', dataIndex: 'goodsId', key: 'key', width: 300 },
            {
                title: '商品来源', dataIndex: 'batchId', key: 'key', width: 150, render: (text, record) => {
                    return <>{text ? <Tag color="#2db7f5">仓库商品</Tag> : <Tag color="#87d068">平台商品</Tag>}</>
                }
            },
            { title: '商品名称', dataIndex: 'goodsName', key: 'key', },
            { title: '种类 ', dataIndex: 'className', key: 'key', },
            { title: '等级', dataIndex: 'goodsProp', key: 'key', },
            {
                title: '状态', key: 'goodsLoading', dataIndex: 'goodsLoading', render: (text, record) => {
                    switch (text) {
                        case 0:
                            return <Tag color="magenta">待编辑</Tag>
                        case 1:
                            return <Tag color="volcano">上 架</Tag>
                        case 2:
                            return <Tag color="gold">下 架</Tag>
                        case 3:
                            return <Tag color="green">审核中</Tag>
                        case 4:
                            return <Tag color="purple">已拒绝</Tag>
                        default:
                            break;
                    }
                },
            },
            {
                title: '操作', dataIndex: 'goodsImg', key: 'goodsImg', render: (text, record) => (
                    <Space size="middle" onClick={(e) => (this.passIdToView(record.goodsImg.goodsId))} >
                        <Link to={{ pathname: "/index/cksp/detail", state: { goodsList: this.state.goodsList, goodsId: record.goodsImg.goodsId } }}>查看</Link>
                        <Link to={{ pathname: "/index/cksp/edit", state: { goodsId: record.goodsImg.goodsId, goodsImg: record.goodsImg.goodsImg } }}>修改</Link>
                    </Space>
                ),
            },
        ];
        /////表格   数据//////
        let newList = this.state.goodsList.map((item, index) => {
            item.key = index
            item.goodsImg = { goodsId: item.goodsId, goodsImg: item.goodsImg }
            return item
        })
        const data = newList

        /////表格勾选
        ///////表格勾选
        const onSelectChange = selectedRowKeys => {
          //console.log('selectedRowKeys changed: ', selectedRowKeys);
            this.setState({ selectedRowKeys });
        };
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };


        // 结构//////////////////////////
        return (
            <div className="main-show">
                <Table columns={columns}
                    dataSource={data}
                    loading={this.props.loading}
                    pagination={{
                        showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                        total: this.state.goodsList.length,
                        showTotal: total => `共 ${total} 条记录`,
                        defaultCurrent: 1,
                        showSizeChanger: false
                    }}
                    rowSelection={rowSelection} />
                <div className="table-foot">
                    <div className="foot-left">
                        {/* <Button className="table-import" disabled type="text"><App /></Button> */}
                        {/* <Button className="table-export" ghost type="primary">导出</Button> */}
                    </div>
                    <div className="foot-right">
                        {/* <span className="table-num">共{this.state.goodsList.length}条记录</span>
                        <div className="to-page">
                            到第<input type="" placeholder="1" />页
                            <Button type="primary" className="jump">GO</Button>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}
