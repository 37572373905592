import React, { Component } from 'react'
import ReactEcharts from "echarts-for-react"
export class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chartsdata: false,
            data: []
        }
    }
    componentDidMount() {
    }
    onClickChart = (e) => {
      //console.log('打印', e);
    }
    render() {
        const option = {
            grid: {
                top: '15%',
                left: '5%',
                right: '2%',
                bottom: '10%',
                containLabel: true,			//---grid 区域是否包含坐标轴的刻度标签
                tooltip: {					//---鼠标焦点放在图形上，产生的提示框
                    textStyle: {
                        color: '#176FC2',
                    },
                }
            },
            xAxis: {
                type: 'category',
                data: ['9月1', '9月2', '9月3', '9月4', '9月5', '9月6', '9月7', '9月8',
                    '9月9', '9月10', '9月11', '9月12', '9月13', '9月14', '9月15', '9月16',
                    '9月17', '9月18', '9月19', '9月20', '9月21', '9月22', '9月23', '9月24',
                    '9月25', '9月26', '9月27', '9月28', '9月29', '9月30'
                ]
            },
            yAxis: {
                type: 'value',
                name: '金额/元',				//---轴名称
                nameTextStyle: {				//---坐标轴名称样式
                    color: "#000",
                    padding: [0, 0, 0, 16],	//---坐标轴名称相对位置
                },
            },
            series: [{
                data: [1500, 300, 900, 500, 700, 645, 787, 123, 1398, 780, 1398, 1001, 110, 110, 1500, 300, 900, 500, 700, 645, 130, 80, 70, 110, 130, 130, 110, 130, 130, 110, 130, 130],
                type: 'bar',
                barWidth: 30,
                name: '销量',				//---系列名称
                itemStyle: {					//---图形形状
                    color: '#176FC2',
                },

            }]
        }
        const onEvents = {
            'click': this.onClickChart,
        }
        const option2 = {
            grid: {
                top: '15%',
                left: '5%',
                right: '2%',
                bottom: '10%',
                containLabel: true,			//---grid 区域是否包含坐标轴的刻度标签
                tooltip: {					//---鼠标焦点放在图形上，产生的提示框
                    textStyle: {
                        color: '#176FC2',
                    },
                }
            },
            xAxis: {
                type: 'category',
                data: this.props.data.orderDay
            },
            yAxis: {
                type: 'value',
                name: '金额/元',				//---轴名称
                nameTextStyle: {				//---坐标轴名称样式
                    color: "#000",
                    padding: [0, 0, 0, 16],	//---坐标轴名称相对位置
                },
            },
            series: [{
                data: this.props.data.orderPrice,
                type: 'bar',
                barWidth: 30,
                name: '销量',				//---系列名称
                itemStyle: {					//---图形形状
                    color: '#176FC2',
                },

            }]
        }
        return (

            <div >
                <ReactEcharts
                    option={this.props.data.orderDay ? option2 : option }
                    onEvents={onEvents}
                    style={{  width: '95%'}}
                />
            </div>
        )
    }
}

export default index