import React, { Component, useState } from 'react'
import { Select, Button, Tag, Space, Table, Image, Modal, Rate } from "antd";
import { RedoOutlined, } from "@ant-design/icons"
import { withRouter } from 'react-router-dom';
// import { Switch, Route } from "react-router-dom";
import request from '../../../../../../js/http';
import cookie from 'react-cookies';

 class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            commentsList: [],
            goodsId: '',
            loading: true,
            selectList: [],
            defaultSelect: 'a'
        }
    }
    componentDidMount() {
        this.props.callback(this.props.location.pathname)

        // console.log(window);
        // console.log(this.props);

        this.evaluation()
    }
    test=value=>{
      //console.log(value);
    }
    evaluation=()=> {
        request("post", "/mall/orderComent/shopGetList", {
            shopId: cookie.load("shopId"),
            pageNum: 1,
            pageSize: 99
        }).then(res => {
          //console.log('评价列表=>', res);
            if (res.code === 0) {
                this.setState({ commentsList: res.data.list, selectList: res.data.list, loading: false })
            }

        })
    }
    render() {
        const Deatil = (data) => {
            let key = 1
            let imgList = data.data.commentImg.split(',').map(item => {
                let i = { img: item, key: key++ }
                item = i
                return item
            })
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);

            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            // let status = this.state.status
            // let orderSn = 0 
            // console.log( this.state.orderDetail.orderSn);
            // const handleChange = value => {
            //     console.log(value);
            // }
            return (
                <>
                    <span onClick={showModal} title="点击查看评价详情">详情</span>
                    <Modal title="评价详情" width={800} visible={isModalVisible} footer={false} onCancel={handleCancel}>
                        <div className="modal-content" style={{ color: "#000" }}>
                            <div className="detail-area" style={{ height: 500 }}>
                                <div className="scroll">
                                    <div className="flex">
                                        <span className="w70px" style={{ lineHeight: '40px' }}>评价：</span>
                                        <span style={{ width: 550, height: 40, boxSizing: 'border-box', lineHeight: '40px', marginBottom: 20, paddingLeft: '20px' }}>
                                            {<>
                                                {
                                                    data.data.star === (1 || 2) ? <Tag color="error">差评</Tag> : <>{
                                                        data.data.star === 3 ? <Tag color="cyan">中评</Tag> : <Tag color="green">好评</Tag>
                                                    }</>
                                                }
                                                <Rate disabled defaultValue={data.data.star} className="ml10" />
                                            </>}
                                        </span>
                                    </div>
                                    <div className="flex" style={{ marginBottom: 10 }}>
                                        <span className="w70px" style={{ lineHeight: '40px' }}>订单编号：</span>
                                        <span style={{ width: 550, height: 40, boxSizing: 'border-box', backgroundColor: '#F9F9F9', border: '1px solid #EEE', lineHeight: '40px', paddingLeft: '20px' }}>
                                            {data.data.orderSn}
                                        </span>
                                    </div>
                                    <p className="flex">
                                        <span className="w70px" style={{ lineHeight: '40px' }}>评价内容：</span>
                                        <span style={{ width: 550, boxSizing: 'border-box', backgroundColor: '#F9F9F9', border: '1px solid #EEE', padding: '10px 20px' }}>
                                            {data.data.content}
                                        </span>
                                    </p>
                                    {/* <p className="flex">
                                        <span className="w70px" style={{ lineHeight: '40px' }}>商家回复：</span>
                                        <span style={{ width: 550, boxSizing: 'border-box', backgroundColor: '#F9F9F9', border: '1px solid #EEE', padding: '10px 20px' }}>
                                            好评，非常棒，这是买家评论内容好评，非常棒，这是买家评论内容好评，非常棒，这是买 家评...
                                        </span>
                                    </p> */}
                                    <div className="flex" style={{ marginBottom: 14 }}>
                                        <span className="w70px">评价图片：</span>
                                        <div style={{ display: 'flex' }} >
                                            {imgList.map(item => {
                                                return (
                                                    <div style={{ marginRight: 20 }} key={item.key}>
                                                        <Image className="mr35px" width={80} src={item.img} />
                                                    </div>
                                                )
                                            })}



                                        </div>
                                    </div>
                                    <p className="flex">
                                        <span className="w70px" style={{ lineHeight: '40px' }}>时间：</span>
                                        <span style={{ width: 550, height: 40, boxSizing: 'border-box', backgroundColor: '#F9F9F9', border: '1px solid #EEE', lineHeight: '40px', paddingLeft: '20px' }}>
                                            {data.data.createAt}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="detail-header">
                                <Button type="primary" ghost onClick={handleOk} >确认</Button>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        };
        //////回复组件
        // const Reply = () => {
        //     let anwserText
        //     const [isModalVisible, setIsModalVisible] = useState(false);

        //     const showModal = () => {
        //         setIsModalVisible(true);
        //     };

        //     const handleOk = () => {
        //         setIsModalVisible(false);
        //         console.log("好的");
        //         console.log(anwserText);

        //     };

        //     const handleCancel = () => {
        //         setIsModalVisible(false);
        //     };
        //     let status = this.state.status
        //     // let orderSn = 0 
        //     // console.log( this.state.orderDetail.orderSn);

        //     const answerInput = e => {
        //         anwserText= e.target.value;
        //         console.log(anwserText);
        //     }
        //     return (
        //         <>
        //             <span onClick={showModal} title="点击回复">回复</span>
        //             <Modal title="评论回复" width={800} visible={isModalVisible} okText="确认" cancelText="取消" onOk={handleOk} onCancel={handleCancel}>
        //                 <div className="modal-content">
        //                     <div className="detail-header">
        //                         <span className="title-address fs14px">用户名：</span>
        //                         <span className="name fs14px link">张小帅</span>
        //                         <span className="mobile fs14px">2020-08-08{' '}23:44:32</span>
        //                         <span className="address fs14px"></span>
        //                         <span className="address fs14px"></span>
        //                         <span className="address fs14px"> <Tag color="red">好评</Tag></span>
        //                     </div>
        //                     <div className="detail-area">
        //                         <div className="scroll">
        //                             {/* <p className="flex"><span className="w70px"></span><span >好评，非常棒，这是买家评论内容好评，非常棒，这是买家评论内容好评，非常棒，这是买家评论内容</span></p> */}
        //                             <p className="flex">
        //                                 <span className="w70px" style={{ lineHeight: '40px' }}></span>
        //                                 <span style={{ width: 550, boxSizing: 'border-box', padding: '10px 20px', backgroundColor: "#fff1f0", color: "#cf1322", border: "1px solid #ffa39e" }}>
        //                                     好评，非常棒，这是买家评论内容好评，非常棒，这是买家评论内容好评，非常棒，这是买 家评...
        //                                 </span>
        //                             </p>
        //                             <p className="flex"><span className="w70px"></span><span ></span></p>
        //                             <div className="qa-add-box">
        //                                 <h5 className="qa-add-item-title">商家回复：</h5>
        //                                 <textarea className="qa-add-item-content" onChange={answerInput} placeholder="请填写回复内容" />
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </Modal>
        //         </>
        //     );
        // };
        const { Option } = Select;
        /////表格 字段///
        const columns = [
            {
                title: '评价', dataIndex: 'star', width: 250, render: (text, record) => {
                    if (text) {
                        switch (text) {
                            case 1:
                                return (
                                    <>
                                        <Tag color={'error'}>差评</Tag>
                                        <Rate disabled defaultValue={text} className="ml10" />
                                    </>
                                )
                            case 2:
                                return (
                                    <>
                                        <Tag color={'error'}>差评</Tag>
                                        <Rate disabled defaultValue={text} className="ml10" />
                                    </>
                                )
                            case 3:
                                return (
                                    <>
                                        <Tag color={'cyan'}>中评</Tag>
                                        <Rate disabled defaultValue={text} className="ml10" />
                                    </>
                                )
                            case 4:
                                return (
                                    <>
                                        <Tag color={'cyan'}>中评</Tag>
                                        <Rate disabled defaultValue={text} className="ml10" />
                                    </>
                                )
                            case 5:
                                return (
                                    <>
                                        <Tag color={'green'}>好评</Tag>
                                        <Rate disabled defaultValue={text} className="ml10" />
                                    </>
                                )
                            default:
                                break;
                        }
                    }
                },
            },
            { title: '订单编号', dataIndex: 'orderSn', width: 200 },
            { title: '评价内容', dataIndex: 'content', width: 300, ellipsis: { showTitle: false, }, },
            {
                title: '评价图片 ', dataIndex: 'commentImg', width: 130, render: (text, record) => {
                    let img = text.split(',')
                    return (<Image width={60} src={img[0]} fallback={'https://img2.baidu.com/it/u=40218031,4235022445&fm=26&fmt=auto&gp=0.jpg'} />)
                }
            },
            { title: '评价时间 ', dataIndex: 'createAt', width: 200 },
            {
                title: '操作', dataIndex: 'commentId', width: 100, render: (text, record) => {
                    let data = this.state.commentsList.filter(item => {
                        return item.commentId === text
                    })
                    return (
                        <Space size="middle" className="pointer link">
                            {/* <Reply /> */}
                            {/* <Link to="#" onClick={this.dingdandetail(text)}>详情</Link> */}
                            <Deatil data={data[0]} />
                        </Space>
                    )
                },
            },
        ];
        /////表格   数据//////
        let newList = this.state.commentsList.map(item => {
            item.key = item.commentId;
            return item
        })
        // console.log(newList[0].commentImg.split(','));
        const datas = newList
        // let datas = [];
        // for (let i = 0; i < 50; i++) {
        //     let type
        //     if (i === 0 || i % 3 === 0) {
        //         type = 0
        //     }
        //     if (i === 2 || i % 3 === 2) {
        //         type = 2
        //     }
        //     if (i === 1 || i % 3 === 1) {
        //         type = 1
        //     }
        //     datas.push({
        //         key: i,
        //         type,
        //         orderSn: `${i}0211212121212121242745`,
        //         content: "好评，非常棒，这是买家评论内容好评，非常棒， 这是买家评论内容好评，非常棒，这是买家评...",
        //         createAt: "2020-03-27 23:47:59",
        //         className: "可以吃sdfsdf",
        //         goodProp: "p, 上等品",
        //         goodsLoading: '656565',
        //     })
        // }
        let data = datas

        /////表格勾选
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const TB = () => {
            const lists = (a, b, c) => {
                newList = this.state.selectList.filter(item => {
                    return ((item.star === a) || (item.star === b))
                })
                this.setState({ commentsList: newList, defaultSelect: c })
                // console.log(newList);
            }

            const handleChanges = (value) => {
                // console.log(`selected ${value}`);
                //  console.log(this);
                switch (value) {
                    case 'a':
                        this.setState({ commentsList: this.state.selectList, defaultSelect: value })
                        break;
                    case 'b':
                        lists(5, 5, value)
                        break;
                    case 'c':
                        lists(3, 4, value)
                        break;
                    case 'd':
                        lists(1, 2, value)
                        break;

                    default:
                        break;
                }
            }
            const reset = () => {
                this.setState({ commentsList: this.state.selectList, defaultSelect: 'a' })
            }
            return (
                <div className="main-show order-list">
                    <div className="table-header">
                        <h1 className="table-name">评价管理</h1>
                        <div className="table-operation">
                            {/* <div className="order-num">
                                <span className="order-num-text">订单编号 :</span>
                                <input className="order-num-num" />
                                </div>
                                <div className="order-state">
                                <span >订单状态 :</span>
                                <Select defaultValue="a" style={{ width: 100, height: 35 }} onChange={handleChange}>
                                <Option value="a">全部</Option>
                                <Option value="g">待商议</Option>
                                <Option value="b">待付款</Option>
                                <Option value="c">代发货</Option>
                                <Option value="d">待收货</Option>
                                <Option value="e">待评价</Option>
                                <Option value="f">已完成</Option>
                                </Select>
                            </div> */}
                        </div>
                    </div>
                    <div className="table-header">
                        {/* <h1 className="table-name">退款列表</h1> */}
                        <div className="table-operation">
                            {/* <div className="order-num">
                                <span className="order-num-text">退款编号 :</span>
                                <input className="order-num-num" />
                            </div> */}
                            <div className="order-state">
                                <span >类型 :</span>
                                <Select defaultValue={this.state.defaultSelect} style={{ width: 100, height: 35 }} onChange={handleChanges}>
                                    <Option value="a">全部</Option>
                                    <Option value="b">好评</Option>
                                    <Option value="c">中评</Option>
                                    <Option value="d">差评</Option>

                                </Select>
                            </div>
                        </div>
                        <div className="">
                            {/* <Button icon={<DownloadOutlined />} type="">导出</Button> */}
                            <Button icon={<RedoOutlined />} className="ml50" type="primary" onClick={reset}> 重置</Button>
                        </div>
                    </div>
                    <div className="table-content">
                        <Table columns={columns}
                            dataSource={data}
                            loading={this.state.loading}
                            pagination={{
                                showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                                total: data.length,
                                showTotal: total => `共 ${total} 条记录`,
                                defaultCurrent: 2,
                                showSizeChanger: false
                            }}
                            rowSelection={rowSelection}
                        />
                    </div>
                </div>
            )
        }
        return (
            // <Switch>
            //     {/* <Route path="/index/ptsp/detail" component={GoodsDetail} exact />
            //     <Route path="/index/ptsp/add" component={GoodsAdd} exact />
            //     <Route path="/index/ptsp/edit" component={GoodsEdit} exact /> */}
            //     <Route path="/index/pjgl" component={TB} />
            // </Switch>
            <TB></TB>

        )
    }
}
export default withRouter(Index)