import React, { Component } from 'react'
import { encrypt } from '../../../../utils/jsencrypt'
// import ReactDom from 'react-dom'
// 引用
import { withRouter } from "react-router-dom"
import request from '../../../../js/http'
import userIcon from '../../user.png'
import passwordIcon from '../../password.png'
import { Button, message } from 'antd';
import './index.css'
import cookie from 'react-cookies'
class Passwordlogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            password: ""
        }
        this.login = this.login.bind(this)
    }
    componentDidMount() {
        cookie.remove("shopId")
        cookie.remove("token")
        cookie.remove("userInfo")
        if (cookie.load("shopId") && cookie.load("token") && cookie.load("userInfo")) {
            // message.warning("您已经登录, 请勿重复登录")
            cookie.remove("shopId")
            cookie.remove("token")
            cookie.remove("userInfo")
            // setTimeout(() => {
            //     window.location.replace('/index');
            // }, 500)
        } else {
            // message.warning("您还未登录, 请登录")
        }
    }
    login = () => {
      //console.log("手机号", 15093120592);
        let phone = this.state.phone, password = this.state.password;
        phone = encrypt(phone);
        password = encrypt(password);
        if (phone && password) {
            message.loading('登录中...');

            request('post', '/mall/shopUser/login', {
                phone,
                password,
            }).then((res) => {
              //console.log("商家登录接口=>", res, res.token, typeof (res.token));
                if (res.code === 200) {
                    let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);//一天
                    cookie.save('token', (res.token), { expires: inFifteenMinutes })
                    cookie.save("shopId", res.shopId, { expires: inFifteenMinutes })
                    // cookie.save("token", res.token)
                    // console.log("密码登录页登录shopId:", cookie.load("shopId"));
                    // const hide = message.loading('登录中...', 0);

                    // setTimeout(hide, 500);

                    // this.props.history.push("/index")
                    setTimeout(() => {
                        window.location.replace('/index')
                        // window.location.reload()
                        // this.userInfo()
                    }, 1000)
                } else {
                    setTimeout(() => {
                        if(res.msg){

                            // message.warning(res.msg)
                            message.warning("请输入正确的账号信息")
                        }else{
                            message.warning("请输入正确的账号信息")

                        }
                    }, 3000)
                }
            })
        }else{
            if(!phone && password){
                message.warning('请输入手机号码')
            }
            if(!password && phone){
                message.warning('请输入密码')
            }
            if(!password && !phone){

                message.warning('请输入您的手机号码和密码')
            }
        }
    }

    phoneChange(e) {
        this.setState({ phone: e.target.value })
        // console.log(e.target.);
    }
    passwordChange(e) {
        this.setState({ password: e.target.value })
    }
    get = () => {
        // console.log(this.props);
        this.props.callback(2)
    }

    render() {

        return (
            <div className="login-form">
                <h1 className="tittle">
                颍山红商家管理后台
                </h1>
                <div className="login-form-main">
                    <h2 className="login-title">
                        登录
                    </h2>
                    <div className="form">
                        <div className="mobile">
                            <img className="user-icon" src={userIcon} alt="账号" />
                            <input placeholder="请输入手机号" onChange={(e) => this.phoneChange(e)} />
                        </div>
                        <div className="mobile">
                            <img className="password-icon" src={passwordIcon} alt="密码" />
                            <input type="password" onChange={(e) => this.passwordChange(e)} placeholder="请输入密码" />
                        </div>
                    </div>
                    <div className="switch-method">
                        {/* <Button type="text">忘记密码</Button> */}
                        <Button type="text" onClick={this.get}>短信验证登录</Button>
                    </div>
                    <Button className="submit" onClick={this.login} type="primary" >登录</Button>
                </div>
            </div>
        )
    }
}
export default withRouter(Passwordlogin)
