import React, { Component, useState } from 'react'
import Answer from './answer';
import { Select, Switch, Tabs, Button, Modal,  } from 'antd';
import './index.css'

export default class index extends Component {

    render() {
        ////////////对话框 新增//////////↓↓↓↓↓↓↓↓↓↓↓
        const App = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <span type="primary" onClick={showModal}>新增</span>
                    <Modal title="新增"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        okText="确认"
                        cancelText="取消"
                        width={800}

                    >
                        <div className="qa-add-box">
                            <h5 className="qa-add-item-title">标题：</h5>
                            <textarea className="qa-add-item-content" placeholder="请输入内容" />
                        </div>
                    </Modal>
                </>
            );
        };
        ////////////对话框 新增 ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

        ////////////select选择器//////////↓↓↓↓↓↓↓↓↓↓↓
        const { Option } = Select;
        function handleChange(value) {
          //console.log(`selected ${value}`);
        }
        ////////////select选择器↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

        ////////////开关//////////↓↓↓↓↓↓↓↓↓↓↓
        function onChange1(checked) {
          //console.log(`switch to ${checked}`);
        }
        function onChange2(checked) {
          //console.log(`switch to ${checked}`);
        }
        function onChange3(checked) {
          //console.log(`switch to ${checked}`);
        }
        ////////////开关↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

        ////////////tabs标签页//////////↓↓↓↓↓↓↓↓↓↓↓
        const { TabPane } = Tabs;
        // const operations = <Button className="button-add" type="primary"> 添加 </Button>;

        function callback(key) {
          //console.log(key);
        }
        const Demo = () => (
            <Tabs defaultActiveKey="1" onChange={callback} >
                {/* 标签页1   tabs1 菜单1///////////////////////////////// */}
                <TabPane tab="常见问题回复" key="1">
                    <Answer />
                </TabPane>
                {/* 标签页2   tabs2 caidan2///////////////////////////////// */}

                <TabPane tab="自动回复" key="2">
                    <div className="reply-setting-contain">
                        <div className="reply-setting h110">
                            <div className="reply-setting-left">
                                <div > 自动回复</div>
                                <div className="fs16c90">
                                    <span>通过编辑内容或关键词规则，快速进行自动回复设置,关闭自动回复之后，将立即对所有用户生效。 </span>
                                </div>
                            </div>
                            <div className="reply-setting-right">
                                <Switch defaultChecked onChange={onChange1} />
                            </div>
                        </div>
                    </div>
                    <div className="reply-auto">
                        <div className="reply-auto-left flexclo">
                            <h2 className="reply-auto-title">自动回复短语</h2>
                            <ul className="reply-auto-phrase-list">
                                <li>1本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊</li>
                                <li>2本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊</li>
                                <li>3本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊</li>
                                <li>4本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊</li>
                                <li>5本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊</li>
                                <li>6本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊本店辣椒棒啊</li>
                            </ul>
                            <div className="reply-phrase-opration">
                                <Button className="w90h40" type="primary"><App /></Button>
                                <Button className="w90h40 b1c">修改</Button>
                                <Button className="w90h40 b1c">删除</Button>
                            </div>
                        </div>
                        <div className="reply-auto-right flexclo">
                            <h2 className="reply-auto-title">设置自动回复</h2>
                            <div className="auto-replay-setting-item">
                                <p className="auto-replay-setting-item-title">当天第一次收到买家消息时候：</p>
                                <div className="select-box">
                                    <Select defaultValue="bb"
                                        style={{ width: 500, height: 40, backgroundColor: "#f9f9f9" }}
                                        onChange={handleChange}
                                        autoFocus={false}
                                        bordered={false}
                                        virtual={false}
                                    >
                                        <Option value="aa">本店辣椒棒啊</Option>
                                        <Option value="bb">本店辣椒棒啊</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className="auto-replay-setting-item">
                                <p className="auto-replay-setting-item-title">当我离开的时候：</p>
                                <div className="select-box">
                                    <Select defaultValue="aa"
                                        style={{ width: 500, height: 40, backgroundColor: "#f9f9f9" }}
                                        onChange={handleChange}
                                        autoFocus={false}
                                        bordered={false}
                                        virtual={false}
                                    >
                                        <Option value="aa">本店辣椒棒啊</Option>
                                        <Option value="bb">本店辣椒棒啊</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className="auto-replay-setting-item">
                                <p className="auto-replay-setting-item-title">当联系人数多时：</p>
                                <div className="select-box">
                                    <Select defaultValue="aa"
                                        style={{ width: 500, height: 40, backgroundColor: "#f9f9f9" }}
                                        onChange={handleChange}
                                        autoFocus={false}
                                        bordered={false}
                                        virtual={false}
                                    >
                                        <Option value="aa">本店辣椒棒啊</Option>
                                        <Option value="bb">本店辣椒棒啊</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
                {/* 标签页3   tabs3 caidan3///////////////////////////////// */}

                <TabPane tab="订单自动回复" key="3">
                    <div className="reply-setting-contain">
                        <div className="reply-setting h110">
                            <div className="reply-setting-left">
                                <div > 下单未支付</div>
                                <div className="fs16c90">
                                    <span>自动催付时机：针对下单超过 </span>
                                    <Select defaultValue="cc"
                                        style={{ width: 150, height: 39, backgroundColor: "rgba(26, 105, 224, 0.05)", margin: '0 5px', border: "solid 1px #1A69E0", color: "#1A69E0" }}
                                        onChange={handleChange}
                                        autoFocus={false}
                                        bordered={false}
                                        virtual={false}
                                    >
                                        <Option value="aa">1小时</Option>
                                        <Option value="bb">2小时</Option>
                                        <Option value="cc">3小时</Option>
                                    </Select>
                                    <span>未付款的订单进行自动催付</span>
                                </div>
                            </div>
                            <div className="reply-setting-right">
                                <Switch defaultChecked onChange={onChange2} />
                            </div>
                        </div>
                        <div className="reply-setting h70">
                            <span>
                                对有过咨询行为的订单自动催付
                            </span>
                            <Switch defaultChecked onChange={onChange3} />
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        );
        ////////////tabs标签页//////////↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

        return (
            <div className="main-show">
                <div className="full">
                    <Demo />
                </div>
            </div>
        )
    }
}
