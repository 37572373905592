import React, { useState, Component } from 'react';
import { Button, message, Modal, Radio } from 'antd';
import request from '../../../../../../js/http';
import cookie from 'react-cookies';

import "./editor.css"
let selectStatus , note = '我不想退就不给你退, 打我呀' ;
export default class Logistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logistics: {},
            data: {},
            disabled:'',
            selectStatus:0
        }
    }
    componentDidMount() {
        // console.log(this.props.data[0]);
        this.setState({ data: this.props.data[0] })
        if(this.props.data[0].status === 0){
            this.setState({disabled:false, selectStatus: this.props.data[0].status})
            // console.log('开');
        }else{
            this.setState({disabled: true, selectStatus: this.props.data[0].status})
            selectStatus = this.props.data[0].status
            // console.log('关');
            // console.log(this.props.data[0].status);
        }
        
    }
    selectStatus = e => {
        // console.log(e.target.value);
        let value = e.target.value
        switch (value) {
            case 1:
                selectStatus = value
                break;
            case 2:
                selectStatus = value
                break;

            default:
                break;
        }
    }
    note = e => {
        note = e.target.value
        // console.log(note);
    }
    render() {

        const Apps = () => (
            <Radio.Group name="radiogroup" defaultValue={this.state.selectStatus} disabled={this.state.disabled} onChange={e => this.selectStatus(e)}>
                <Radio value={1} defaultChecked={false  }>同意退款</Radio>
                <Radio value={2} defaultChecked={true} >拒绝退款</Radio>
            </Radio.Group>
        );
        const App = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
              //console.log(666);
                if(selectStatus && note){
                    // console.log(selectStatus, note);
                    let shopId=cookie.load("shopId") , backId = this.state.data.backId ;
                    switch (selectStatus) {
                        case 1:
                            // console.log('同意了');
                            if(shopId && backId){
                                request("post", "/mall/orderOrder/refundAgree",{
                                    shopId: shopId,
                                    backId: backId
                                }).then(res=>{
                                  //console.log("同意退款=>", res);
                                    if(res.code ===200){
                                        message.success('已成功同意退款')
                                        window.location.reload()
                                    }
                                })
                            }
                            break;
                        case 2:
                            // console.log('拒绝了');
                            if(shopId && backId ){
                              //console.log(shopId, backId, note);
                                request("post", "/mall/orderOrder/refundRefuse",{
                                    shopId: shopId,
                                    backId: backId,
                                    reasonF: note,
                                }).then(res=>{
                                  //console.log("拒绝退款=>", res);
                                    if(res.code ===200){
                                        message.success('已成功拒绝退款')
                                        window.location.reload()
                                    }
                                })
                            }
                            
                            break;
                    
                        default:
                            break;
                    }
                }

            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <div className="pointer link" type="primary" onClick={showModal}>编辑</div>
                    <Modal className="content-right"
                        footer={null}
                        title="编辑"
                        visible={isModalVisible}
                        onOk={handleOk}
                        centered={true}
                        onCancel={handleCancel}
                        width={800}
                    >
                        <div className="modal-content">
                            <div className="detail-area">
                                <div className="scroll">
                                    <div className="shipping-info-add">
                                        <div className="div">
                                            <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>订单编号：</span><input className="input disabled" placeholder={this.state.data.orderSn} />
                                        </div>
                                        <div className="div">
                                            <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>退款编号：</span><input className="input" placeholder={this.state.data.backId} />
                                        </div>
                                        <div className="div">
                                            <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>申请人：</span><input className="input" placeholder={this.state.data.memberName} />
                                        </div>
                                        <div className="div">
                                            <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>退款原因：</span><input className="input" placeholder={this.state.data.reason} />
                                        </div>
                                        <div className="div">
                                            <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>退款金额：</span><input className="input" type="number" placeholder={'￥'+this.state.data.price} />
                                        </div>
                                        {/* <div className="div">
                                            <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>退款说明：</span><input className="input"   placeholder={'什么我没听见'} />
                                        </div> */}
                                        {/* <div className="div">
                                            <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>退款凭证：</span>
                                        </div> */}
                                        <div className="div">
                                            <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>状态：</span><Apps />

                                        </div>
                                        <div className="div">
                                            <span className="span modify-title" style={{ display: "inline-block", width: 70 }}>备注：</span><input onChange={e => this.note(e)} className="input"  placeholder={this.state.data.reasonF} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="detail-header">
                                <Button className="m020" type="primary" ghost onClick={handleCancel} >重置</Button>
                                {this.state.selectStatus === 0?<Button className="m020" type="primary" onClick={handleOk} >提交</Button>:<Button className="m020" type="primary" disabled onClick={handleOk} >提交</Button>}
                                

                            </div>
                        </div>
                    </Modal>
                </>
            );
        };
        return (
            <div className="content-right">
                <App />
            </div>
        )
    }
}
