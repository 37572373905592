import React, { Component } from 'react'
import "./index.scss";
import cookie from 'react-cookies'
import request from '../../../../../../js/http'
import { Menu, Dropdown, Button, message, Space, Modal, Pagination, Tabs, Radio, Upload, } from 'antd';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopCangku: [

      ],
      lvDropdown: '精品',
      pzDropdown: '子弹头',
      shoppzDropdown: '子弹头',
      jiebnyunfeiDropdown: '全球包邮',
      cangkuweizhiDropdown: '中国',
      chengbenDropdown: '成本',
      danweiDropdown: '斤',
      guigeDropdown: '规格',
      isModalVisible: false,
      shangjiabianji: false,
      pageNmb: 1,
      indexList: [],//当前渲染的页面数据
      checkAll: false,
      minValue: 0,
      maxValue: 10,
      value: 1,
      setValue: 1,

      goodsName: '',    //商品名称
      goodsTitle: '',    //商品简介（200个字以内）
      classId: '',       //分类id
      goodsImg: '',      //列表图片
      hasSpec: '',       //开启规格0-未开启，1-开启
      goodsParam: '',    //商品溯源信息
      bannerList: '',    //轮播图列表
      detailImgList: '', //详情图列表
      productList: '',   //产品列表
      goodsProp: '',     //辣椒等级
      productName: '',   //包
      goodsId:'',        //商品Id
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [
        {
          uid: '-1',
          name: 'image.jpg',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
      ],
    }
  }

  componentWillMount() {
    this.setState({indexList: this.state.shopCangku.slice(this.state.minValue, this.state.maxValue)})
    // this.state.indexList = this.state.shopCangku.slice(this.state.minValue, this.state.maxValue)
    this.cangkuList()
  }
  //仓库列表
  cangkuList() {
  //console.log(this.state.goodsName);
    // let userInfo = cookie.load('userInfo')
    request('post', '/mall/goodsGoods/list', {
      // shopId: cookie.load("shopId")
      total:100
    }).then((res) => {
    //console.log(res);
      this.setState({
        shopCangku: res.rows
      })
    })
  }

  //添加商品信息
  addGoods = () => {
    let userInfo = cookie.load('userInfo')
  //console.log(userInfo);
    let goodsName = this.state.goodsName
    let goodsTitle = this.state.goodsTitle

    request('post', '/mall/goodsGoods/add', {
      shopId: userInfo.shopId,
      goodsName,
      goodsTitle,

    }).then((res) => {
    //console.log(res);
      this.cangkuList()
    //console.log(res);
    })
  }



  goodsName = (e) => {
    this.setState({
      goodsName: e.target.value
    })
  }
  goodsTitle = (e) => {
    this.setState({
      goodsTitle: e.target.value
    })
  }
  //上传图片
  upLoadCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ file, fileList }) => {
    // console.log(JSON.stringify(file)); // file 是当前正在上传的 单个 img
    // console.log(JSON.stringify(fileList)); // fileList 是已上传的全部 img 列表
  //console.log(this.state.fileList);
    this.setState({ fileList })
  };



  //限制图片 格式、size、分辨率
  handleBeforeUpload = file => {
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      Modal.error({
        title: '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~',
      });
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Modal.error({
        title: '超过2M限制，不允许上传~',
      });
      return;
    }
    return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && this.checkImageWH(file);
  }

  //checkImageWH  返回一个promise  检测通过返回resolve  失败返回reject阻止图片上传
  checkImageWH(file, width, height) {
    // let self = this;
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = e => {
      //console.log(width);
      //console.log(height);
        let src = e.target.result;
        const image = new Image();
        image.onload = function () {
          if (width && this.width !== width) {
            Modal.error({
              title: '请上传宽为' + width + '的图片'
            })
            reject();
          } else if (height && this.height !== height) {
            Modal.error({
              title: '请上传高为' + height + '的图片',
            })
            reject();
          } else {
            resolve();
          }
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  }

  //等级下拉
  lvMenuClick = (e) => {
    message.info('Click on left button.');
  //console.log('click left button', e);
    this.setState({
      lvDropdown: e.item.props.children[1],
    });
  }
  //品种下拉
  pzMenuClick = (e) => {
    message.info('Click on left button.');
  //console.log('click left button', e);
    this.setState({
      pzDropdown: e.item.props.children[1],
    });
  }
  //基本商品品种下拉
  shoppzMenuClick = (e) => {
    message.info('Click on left button.');
  //console.log('click left button', e);
    this.setState({
      shoppzDropdown: e.item.props.children[1],
    });
  }
  //基本信息运费模板下拉
  jiebnyunfeiMenuClick = (e) => {
    message.info('Click on left button.');
  //console.log('click left button', e);
    this.setState({
      jiebnyunfeiDropdown: e.item.props.children[1],
    });
  }
  //基本信息成本价格下拉
  chengbenMenuClick = (e) => {
    message.info('Click on left button.');
  //console.log('click left button', e);
    this.setState({
      chengbenDropdown: e.item.props.children[1],
    });
  }
  //基本信息单位模板下拉
  danweiMenuClick = (e) => {
    message.info('Click on left button.');
  //console.log('click left button', e);
    this.setState({
      danweiDropdown: e.item.props.children[1],
    });
  }
  //基本信息商品规格模板下拉
  shopggMenuClick = (e) => {
    message.info('Click on left button.');
  //console.log('click left button', e);
    this.setState({
      guigeDropdown: e.item.props.children[1],
    });
  }

  //基本信息仓库位置
  cangkuweizhiMenuClick = (e) => {
    message.info('Click on left button.');
  //console.log('click left button', e);
    this.setState({
      cangkuweizhiDropdown: e.item.props.children[1],
    });
  }

  //添加
  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  //商家编辑
  shangjiabianji = (goodsId) => {
  //console.log(goodsId);
    this.setState({
      shangjiabianji: true,
      goodsId
    })
  }
  shangjiabianjiOk = () => {
    this.setState({
      shangjiabianji: false,
    });
  };

  shangjiabianjiCancel = () => {
    this.setState({
      shangjiabianji: false,
    });
  };
  //删除商品
  shopremove = () => {
    let goodsId = this.state.goodsId
    let userInfo = cookie.load('userInfo')
  //console.log(goodsId);
    request('post','mall/goodsGoods/remove',{
      shopId:userInfo.shopId,
      goodsId
    }).then(res=>{
      this.cangkuList()
    //console.log(res);
    })
  }
  //单选
  checkOne = (e, i) => {
    const arr = this.state.indexList;
    arr[i].checkOne = !arr[i].checkOne;
    let arr1 = arr.filter((item) => item.checkOne === true);
  //console.log(arr[i].checkOne);
  //console.log(arr1);
    if (arr1.length === arr.length) {
      this.setState({
        indexList: arr,
        checkAll: true,
      });
    } else {
      this.setState({
        indexList: arr,
        checkAll: false,
      });
    }
  //console.log(this.state.pageNmb);
  };

  //全选
  quan() {
    let flag = this.state.checkAll;
    let arr = this.state.indexList;
  //console.log(arr);
    flag = !flag;
    if (flag === true) {
      arr.map((item) => {
        item.checkOne = true;
        return item
      });
    } else {
      arr.map((item) => {
        item.checkOne = false;
        return item
      });
    }

    this.setState({
      checkAll: flag,
      indexList: arr,
    }, function () {
      this.setState({indexList:this.state.shopCangku.slice(this.state.minValue, this.state.maxValue)})
    });
  }


  //批量删除
  delAll = () => {
    let guige = [...this.state.shopCangku];
  //console.log(guige);
    let del = guige.filter(i => {
      return !i.checkOne
    });
    this.setState({
      shopCangku: del
    }, function () {
      this.setState({indexList:this.state.shopCangku.slice(this.state.minValue, this.state.maxValue)})
      this.state.indexList.map(item => {
        if (item.checkOne === true) {
          this.setState({
            checkAll: true
          })
        } else {
          this.setState({
            checkAll: false
          })
        }
        return item
      })
      if (this.state.indexList.length === 0) {
        this.setState({
          checkAll: false
        })
      }
    })
  }

  //分页
  onFenye = (pageNumber) => {
  //console.log(pageNumber);
    if (pageNumber <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 10
      });
    } else {
      this.setState({
        minValue: (pageNumber - 1) * 10,
        maxValue: (pageNumber - 1) * 10 + 10
      });
    }
    this.setState({
      pageNmb: pageNumber
    }, function () {
      this.setState({indexList: this.state.shopCangku.slice(this.state.minValue, this.state.maxValue)})
      this.state.indexList.map(item => {
        if (item.checkOne === true) {
          this.setState({
            checkAll: true
          })
        } else {
          this.setState({
            checkAll: false
          })
        }
        return item
      })
    })
  }
  //添加状态单选
  onChange = e => {
  //console.log(e);
    // console.log('radio checked', e.target.value);
    this.setState({
      // value: e.target.value
    })
  };
  render(text,record) {

    //上传图片
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );


    //等级下拉选项
    const lvmenu = (
      <Menu onClick={this.lvMenuClick}>
        <Menu.Item key="1">
          精品
              </Menu.Item>
        <Menu.Item key="2">
          史诗
              </Menu.Item>
        <Menu.Item key="3">
          普通
              </Menu.Item>
      </Menu>
    );
    //品种下拉选项
    const pzmenu = (
      <Menu onClick={this.pzMenuClick}>
        <Menu.Item key="1">
          子弹头
              </Menu.Item>
        <Menu.Item key="2">
          子弹壳
              </Menu.Item>
        <Menu.Item key="3">
          好吃的辣椒
              </Menu.Item>
      </Menu>
    );

    //基本信息商品品种
    const jibenshoppz = (
      <Menu onClick={this.shoppzMenuClick}>
        <Menu.Item key="1">
          子弹头
              </Menu.Item>
        <Menu.Item key="2">
          子弹壳
              </Menu.Item>
        <Menu.Item key="3">
          好吃的辣椒
              </Menu.Item>
      </Menu>
    );
    //基本信息运费模板
    const jiebnyunfei = (
      <Menu onClick={this.jiebnyunfeiMenuClick}>
        <Menu.Item key="1">
          全球包邮
              </Menu.Item>
        <Menu.Item key="2">
          不包邮
              </Menu.Item>
        <Menu.Item key="3">
          宇宙包邮
              </Menu.Item>
      </Menu>
    );
    //基本信息成本价格
    const chengbenjiage = (
      <Menu onClick={this.chengbenMenuClick}>
        <Menu.Item key="1">
          成本
              </Menu.Item>
        <Menu.Item key="2">
          无成本
              </Menu.Item>
        <Menu.Item key="3">
          有成本
              </Menu.Item>
      </Menu>
    );
    //基本信息单位
    const danwei = (
      <Menu onClick={this.danweiMenuClick}>
        <Menu.Item key="1">
          斤
              </Menu.Item>
        <Menu.Item key="2">
          包
              </Menu.Item>
        <Menu.Item key="3">
          吨
              </Menu.Item>
      </Menu>
    );
    //基本信息商品规格
    const shopgg = (
      <Menu onClick={this.shopggMenuClick}>
        <Menu.Item key="1">
          规格
              </Menu.Item>
        <Menu.Item key="2">
          有规格
              </Menu.Item>
        <Menu.Item key="3">
          无规格
              </Menu.Item>
      </Menu>
    );
    //商品介绍仓库位置
    const cangkuweizhi = (
      <Menu onClick={this.cangkuweizhiMenuClick}>
        <Menu.Item key="1">
          中国
              </Menu.Item>
        <Menu.Item key="2">
          外国
              </Menu.Item>
        <Menu.Item key="3">
          地球
              </Menu.Item>
      </Menu>
    );
    const { TabPane } = Tabs;

    return (
      <div>
        <div className="shopCangKu">
          <div className="shopCangKuTop">
            <div className="shopCangKuTopLf">
              <Space wrap >
                <span className="xialalv">等级</span>
                <Dropdown overlay={lvmenu} trigger={['click']}>
                  <Button className="xialacd">
                    {this.state.lvDropdown} <DownOutlined />
                  </Button>
                </Dropdown>
              </Space>
              <Space wrap>
                <span className="xialalv">品种</span>
                <Dropdown overlay={pzmenu} trigger={['click']}>
                  <Button className="xialacd" >
                    {this.state.pzDropdown} <DownOutlined />
                  </Button>
                </Dropdown>
              </Space>
            </div>
            <div className="shopCangKuTopRg">
              <Button type="primary" onClick={this.showModal}>
                添加商品
                            </Button>
              <div className="CangKuAddModal">

              </div>
              <Modal title="添加" footer={[
                <div className="modalFooter">
                  <Button type="primary" ghost>删除</Button>
                  <Button type="primary" ghost>取消</Button>
                  <Button type="primary" onClick={this.addGoods}>发布</Button>
                </div>
              ]} className="cangkuModal" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
                <Tabs onChange={this.callback} type="card">
                  <TabPane tab="基本信息" key="1">
                    <div className="jiben">
                      <div className="shopTitle">
                        <span className="shopTitleSpan" style={{ width: '70px' }}>商品标题：</span>
                        <input className="shopTitleInput" type="text" onChange={(e) => this.goodsName(e)} placeholder="请输入标题" />
                      </div>
                      <div className="shopFuTitle">
                        <span className="shopFuTitleSpan" style={{ width: '70px' }}>副标题：</span>
                        <input className="shopFuTitleInput" type="text" placeholder="请输入副标题" />
                      </div>
                      <div className="shoppz">
                        <span className="shoppzSpan" style={{ width: '70px' }}>商品品种：</span>
                        <Space wrap className="shoppzSpace">

                          <Dropdown overlay={jibenshoppz} trigger={['click']}>
                            <Button className="xialashoppz" >
                              {this.state.shoppzDropdown} <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Space>
                      </div>
                      <div className="qipiliang">
                        <span className="qipiliangSpan" style={{ width: '70px' }}>单位净重：</span>
                        <div className="qipiliangdiv">
                          <div className="duoshaodai">
                            <span className="duoshaodaispan">多少袋起批</span>
                            <input className="duoshaodaiinput" type="text" />
                          </div>
                          <div className="duoshaodai">
                            <span className="duoshaodaispan">单价(元/袋)</span>
                            <input className="duoshaodaiinput" type="text" />
                          </div>
                          <div className="yunfei">
                            <span className="yunfeispan">运费模板</span>
                            <div className="yunfeiyf">
                              <Space wrap className="yunfeiSpace">
                                <Dropdown overlay={jiebnyunfei} trigger={['click']}>
                                  <Button className="xialayf">
                                    {this.state.jiebnyunfeiDropdown} <DownOutlined />
                                  </Button>
                                </Dropdown>
                              </Space>
                              <div className="yunfeidel">
                                <Button type="text" danger>
                                  删除
                                                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="qipiadd">
                            <Button type="primary" ghost>
                              添加
                                                        </Button>
                          </div>
                        </div>
                      </div>
                      <div className="shoppz">
                        <span className="shoppzSpan" style={{ width: '70px' }}>成本价格：</span>
                        <Space wrap className="shoppzSpace">

                          <Dropdown overlay={chengbenjiage} trigger={['click']}>
                            <Button className="xialashoppz" >
                              {this.state.chengbenDropdown} <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Space>
                      </div>
                      <div className="shoppz">
                        <span className="shoppzSpan" style={{ width: '70px' }}>单位：</span>
                        <Space wrap className="shoppzSpace">

                          <Dropdown overlay={danwei} trigger={['click']}>
                            <Button className="xialashoppz" >
                              {this.state.danweiDropdown} <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Space>
                      </div>
                      <div className="shoppz">
                        <span className="shoppzSpan" style={{ width: '70px' }}>商品规格：</span>
                        <Space wrap className="shoppzSpace">

                          <Dropdown overlay={shopgg} trigger={['click']}>
                            <Button className="xialashoppz" >
                              {this.state.guigeDropdown} <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Space>
                      </div>
                      <div className="shopTitle">
                        <span className="shopTitleSpan" style={{ width: '70px' }}>实际库存：</span>
                        <input className="shopTitleInput" type="text" />
                      </div>
                      <div className="shopTitle">
                        <span className="shopTitleSpan" style={{ width: '70px' }}>虚拟库存：</span>
                        <input className="shopTitleInput" type="text" />
                      </div>
                      <div className="zhuangtai">
                        <span className="zhuangtaiSpan"> <i>*</i>状态：</span>
                        <div className="zhuangtaixuanze">
                          <Radio.Group onChange={this.onChange} value={this.state.value}>
                            <Radio value={1}>上架</Radio>
                            <Radio value={2}>下架</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className="zhuangtai">
                        <span className="zhuangtaiSpan"> <i>*</i>订单确认之后才能支付：</span>
                        <div className="zhuangtaixuanze">
                          <Radio.Group onChange={this.onChange} value={this.state.value} defaultValue={1}>
                            <Radio value={1}>是</Radio>
                            <Radio value={2}>下架</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="商品介绍" key="2">
                    <div className='shopjieshao'>
                      <div className="jieshao-lunbo">
                        <span className="lunspan">轮播图：</span>
                        <div className="chuanlun">
                          <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            beforeUpload={this.handleBeforeUpload}
                          >
                            {fileList.length >= 8 ? null : uploadButton}
                          </Upload>
                          <Modal
                            visible={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={this.upLoadCancel}
                          >
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                          </Modal>
                        </div>
                      </div>
                      <div className="jieshao-lunbo">
                        <span className="lunspan">详情图：</span>
                        <div className="chuanlun">
                          <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            beforeUpload={this.handleBeforeUpload}
                          >
                            {fileList.length >= 8 ? null : uploadButton}
                          </Upload>
                          <Modal
                            visible={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={this.upLoadCancel}
                          >
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                          </Modal>
                        </div>
                      </div>
                      <div className="jieshao-jieshao">
                        <span className="jieshaospan">商品介绍：</span>
                        <textarea className="jieshaoinput" type="text" onChange={(e) => this.goodsTitle(e)} />
                      </div>
                      <div className="jieshao-suyuan">
                        <span className="suyuanspan">溯源信息：</span>
                        <div className="suyuanbtn">
                          <Button type="primary" ghost>
                            点击查看
                                                        </Button>
                        </div>
                      </div>
                      <div className="jieshao-suyuan">
                        <span className="suyuanspan">实时图像：</span>
                        <div className="suyuanbtn">
                          <Button type="primary" ghost>
                            点击查看
                                                        </Button>
                        </div>
                      </div>
                      <div className="jieshao-suyuan">
                        <span className="suyuanspan">生长周期：</span>
                        <div className="suyuanbtn">
                          <Button type="primary" ghost>
                            点击查看
                                                        </Button>
                        </div>
                      </div>
                      <div className="jieshao-shouhou">
                        <span className="shouhouspan">售后须知：</span>
                        <textarea className="shouhouinput" type="text" placeholder="请输入文字描述" />
                      </div>
                      <div className="jieshao-weizhi">
                        <span className="weizhispan">仓库位置：</span>

                        <Space className="weizhiSpace">
                          <Dropdown overlay={cangkuweizhi} trigger={['click']}>
                            <Button className="xialaweizhi" >
                              {this.state.cangkuweizhiDropdown} <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Space>

                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </Modal>
            </div>
          </div>
          <div className="shopCangKuBody">
            <table className="shopCangKuTable" >
              <thead className="shopCangKuThead">
                <tr className="shopCangKuThTr">
                  <th>序号</th>
                  <th>商品ID</th>
                  <th>种类</th>
                  <th>等级</th>
                  <th>成本</th>
                  <th>规格</th>
                  <th>实际库存</th>
                  <th>状态</th>
                  <th>操作</th>
                </tr>
                {this.state.shopCangku &&
                  this.state.shopCangku.length > 0 &&
                  this.state.shopCangku.slice(this.state.minValue, this.state.maxValue).map((item, index) => (
                    <tr className="shopCangKuTdTr" key={item.goodsId}>
                      <td>
                        <input type="checkbox"
                          checked={item.checkOne}
                          onChange={(e) => {
                            this.checkOne(e, index);
                          }} />
                      </td>
                      <td >{item.goodsName}</td>
                      <td>{item.goodsName}</td>
                      <td>{item.goodsProp}</td>
                      <td>{item.chengben}</td>
                      <td>{item.goodsUnit}</td>
                      <td>{item.kucun}</td>
                      <td>{item.goodsLoading}</td>
                      <td>
                        <span className="sjbj" onClick={() => this.shangjiabianji(item.goodsId,text,record)}>商家编辑</span>
                        <span className="xiangqing">详情</span>
                      </td>
                    </tr>
                  ))
                }
              </thead>
              <tbody className="shopCangKuTbody">
              </tbody>
            </table>
          </div>
          <div className="commodityListFooter">
            <div className="listFooterInfo">
              <input
                type="checkbox"
                checked={this.state.checkAll}
                onChange={(e) => {
                  this.quan();
                }}
              />
              <span>全选</span>
              <span>批量上架</span>
              <span onClick={() => this.delAll()}>批量删除</span>
              <button>导出</button>
            </div>
            <div className="listFooterFenye">
              <Pagination showQuickJumper defaultCurrent={this.state.pageNmb} total={'this.state.shopCangku.length'} onChange={(e) => this.onFenye(e)} />
            </div>
          </div>
        </div>


        <Modal title="添加" footer={[
          <div className="modalFooter">
            <Button type="primary" ghost onClick={() => this.shopremove(this.props.goodsId)}>删除</Button>
            <Button type="primary" ghost>取消</Button>
            <Button type="primary" onClick={this.bianjiaddGoods}>发布</Button>
          </div>
        ]} className="cangkuModal" visible={this.state.shangjiabianji} onOk={this.shangjiabianjiOk} onCancel={this.shangjiabianjiCancel}>
          <Tabs onChange={this.callback} type="card">
            <TabPane tab="基本信息" key="1">
              <div className="jiben">
                <div className="shopTitle">
                  <span className="shopTitleSpan" style={{ width: '70px' }}>商品标题：</span>
                  <input className="shopTitleInput" type="text" onChange={(e) => this.goodsName(e)} placeholder="请输入标题" />
                </div>
                <div className="shopFuTitle">
                  <span className="shopFuTitleSpan" style={{ width: '70px' }}>副标题：</span>
                  <input className="shopFuTitleInput" type="text" placeholder="请输入副标题" />
                </div>
                <div className="shoppz">
                  <span className="shoppzSpan" style={{ width: '70px' }}>商品品种：</span>
                  <Space wrap className="shoppzSpace">

                    <Dropdown overlay={jibenshoppz} trigger={['click']}>
                      <Button className="xialashoppz" >
                        {this.state.shoppzDropdown} <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                </div>
                <div className="qipiliang">
                  <span className="qipiliangSpan" style={{ width: '70px' }}>单位净重：</span>
                  <div className="qipiliangdiv">
                    <div className="duoshaodai">
                      <span className="duoshaodaispan">多少袋起批</span>
                      <input className="duoshaodaiinput" type="text" />
                    </div>
                    <div className="duoshaodai">
                      <span className="duoshaodaispan">单价(元/袋)</span>
                      <input className="duoshaodaiinput" type="text" />
                    </div>
                    <div className="yunfei">
                      <span className="yunfeispan">运费模板</span>
                      <div className="yunfeiyf">
                        <Space wrap className="yunfeiSpace">
                          <Dropdown overlay={jiebnyunfei} trigger={['click']}>
                            <Button className="xialayf">
                              {this.state.jiebnyunfeiDropdown} <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Space>
                        <div className="yunfeidel">
                          <Button type="text" danger>
                            删除
                                                                </Button>
                        </div>
                      </div>
                    </div>
                    <div className="qipiadd">
                      <Button type="primary" ghost>
                        添加
                                                        </Button>
                    </div>
                  </div>
                </div>
                <div className="shoppz">
                  <span className="shoppzSpan" style={{ width: '70px' }}>成本价格：</span>
                  <Space wrap className="shoppzSpace">

                    <Dropdown overlay={chengbenjiage} trigger={['click']}>
                      <Button className="xialashoppz" >
                        {this.state.chengbenDropdown} <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                </div>
                <div className="shoppz">
                  <span className="shoppzSpan" style={{ width: '70px' }}>单位：</span>
                  <Space wrap className="shoppzSpace">

                    <Dropdown overlay={danwei} trigger={['click']}>
                      <Button className="xialashoppz" >
                        {this.state.danweiDropdown} <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                </div>
                <div className="shoppz">
                  <span className="shoppzSpan" style={{ width: '70px' }}>商品规格：</span>
                  <Space wrap className="shoppzSpace">

                    <Dropdown overlay={shopgg} trigger={['click']}>
                      <Button className="xialashoppz" >
                        {this.state.guigeDropdown} <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                </div>
                <div className="shopTitle">
                  <span className="shopTitleSpan" style={{ width: '70px' }}>实际库存：</span>
                  <input className="shopTitleInput" type="text" />
                </div>
                <div className="shopTitle">
                  <span className="shopTitleSpan" style={{ width: '70px' }}>虚拟库存：</span>
                  <input className="shopTitleInput" type="text" />
                </div>
                <div className="zhuangtai">
                  <span className="zhuangtaiSpan"> <i>*</i>状态：</span>
                  <div className="zhuangtaixuanze">
                    <Radio.Group onChange={this.onChange} value={this.state.value}>
                      <Radio value={1}>上架</Radio>
                      <Radio value={2}>下架</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="zhuangtai">
                  <span className="zhuangtaiSpan"> <i>*</i>订单确认之后才能支付：</span>
                  <div className="zhuangtaixuanze">
                    <Radio.Group onChange={this.onChange} value={this.state.value} defaultValue={1}>
                      <Radio value={1}>是</Radio>
                      <Radio value={2}>下架</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="商品介绍" key="2">
              <div className='shopjieshao'>
                <div className="jieshao-lunbo">
                  <span className="lunspan">轮播图：</span>
                  <div className="chuanlun">
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={this.handlePreview}
                      onChange={this.handleChange}
                      beforeUpload={this.handleBeforeUpload}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    <Modal
                      visible={previewVisible}
                      title={previewTitle}
                      footer={null}
                      onCancel={this.upLoadCancel}
                    >
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                </div>
                <div className="jieshao-lunbo">
                  <span className="lunspan">详情图：</span>
                  <div className="chuanlun">
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={this.handlePreview}
                      onChange={this.handleChange}
                      beforeUpload={this.handleBeforeUpload}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    <Modal
                      visible={previewVisible}
                      title={previewTitle}
                      footer={null}
                      onCancel={this.upLoadCancel}
                    >
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                </div>
                <div className="jieshao-jieshao">
                  <span className="jieshaospan">商品介绍：</span>
                  <textarea className="jieshaoinput" type="text" onChange={(e) => this.goodsTitle(e)} />
                </div>
                <div className="jieshao-suyuan">
                  <span className="suyuanspan">溯源信息：</span>
                  <div className="suyuanbtn">
                    <Button type="primary" ghost>
                      点击查看
                                                        </Button>
                  </div>
                </div>
                <div className="jieshao-suyuan">
                  <span className="suyuanspan">实时图像：</span>
                  <div className="suyuanbtn">
                    <Button type="primary" ghost>
                      点击查看
                                                        </Button>
                  </div>
                </div>
                <div className="jieshao-suyuan">
                  <span className="suyuanspan">生长周期：</span>
                  <div className="suyuanbtn">
                    <Button type="primary" ghost>
                      点击查看
                                                        </Button>
                  </div>
                </div>
                <div className="jieshao-shouhou">
                  <span className="shouhouspan">售后须知：</span>
                  <textarea className="shouhouinput" type="text" placeholder="请输入文字描述" />
                </div>
                <div className="jieshao-weizhi">
                  <span className="weizhispan">仓库位置：</span>
                  <Space className="weizhiSpace">
                    <Dropdown overlay={cangkuweizhi} trigger={['click']}>
                      <Button className="xialaweizhi" >
                        {this.state.cangkuweizhiDropdown} <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </Modal>
      </div>
    )
  }
}
