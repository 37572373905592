import React, { Component } from 'react'
import request from '../../../../../../js/http';
import cookie from 'react-cookies';
import { Button, Badge, message } from 'antd';
import "./index.css"

export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: "",
            userTips: {},
            phone: '',
            phoneTips: {},
            code: '',
            codeTips: {},
            codeStatus: 0,
            oldPassword: '',
            oldPasswordTips: {},
            password: '',
            passwordTips: {},
            passwords: "",
            passwordsTips: {},
        }
    }
  
    componentDidMount(){
        // console.log(this.props);
    }
    userInput = e => {
        let value = e.target.value
        if (/\s/.test(value)) {
            this.setState({ userTips: { color: "#f50", text: "包含空字符" } })
        } else {
            if (value.length === 11) {
                if (/^1[34578]\d{9}$/.test(value)) {
                    this.setState({ user: value, userTips: { color: "#87d068", text: "格式正确" } })
                } else {
                    if (/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
                        this.setState({ user: value, userTips: { color: "#87d068", text: "格式正确" } })
                    } else {
                        this.setState({ userTips: { color: "#f50", text: "请输入正确的邮箱地址或11位手机号" } })
                    }
                }
            } else {
                if ((/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) || (/^\w{1,}$/.test(value))) {
                    this.setState({ user: value, userTips: { color: "#87d068", text: "格式正确" } })
                } else {
                    this.setState({ userTips: { color: "#f50", text: "请输入正确的邮箱地址" } })
                }
            }
        }
    }
    // phoneInput = e => {
    //     let value = e.target.value
    //     if (/^1[34578]\d{9}$/.test(value)) {
    //         this.setState({ phone: value, phoneTips: { text: "格式正确", color: "#87d068" } })
    //     } else {
    //         if (!value) {
    //             this.setState({ phoneTips: { text: "手机号码为必填项", color: "#f50" } })
    //         } else {
    //             this.setState({ phoneTips: { text: "手机号码格式错误", color: "#f50" } })
    //         }
    //     }
    // }
    // codeInput = e => {
    //     let value = e.target.value
    //     if (/^\d{6}$/.test(value) && (value.length === 6)) {
    //         this.setState({ code: value, codeTips: { text: "格式正确", color: "#87d068" } })
    //     } else {
    //         this.setState({ codeTips: { text: "请输入 6 数字位验证码", color: "#f50" } })
    //     }
    // }
    oldPasswordInput = e => {
        let value = e.target.value
        if ((/^\w{6,20}$/.test(value)) && (value.length <= 20)) {
          //console.log("ok");
            this.setState({ oldPassword: value, oldPasswordTips: { text: "格式正确", color: "#87d068" } })
        } else {
            this.setState({ oldPasswordTips: { text: "密码格式为8-20位数字和字母组合", color: "#f50" } })
        }
    }
    passwordInput = e => {
        let value = e.target.value
        if ((/^\w{8,20}$/.test(value)) && (value.length <= 20)) {
          //console.log("ok");
            this.setState({ password: value, passwordTips: { text: "格式正确", color: "#87d068" } })
        } else {
            this.setState({ passwordTips: { text: "密码格式为8-20位数字和字母组合", color: "#f50" } })
        }
    }
    passwordsInput = e => {
        // console.log(e.target.value);
        let value = e.target.value
        if (value === this.state.password) {
            this.setState({ passwords: value, passwordsTips: { text: "格式正确", color: "#87d068" } })
        } else {
            this.setState({ passwordsTips: { text: "两次密码不一致", color: "#f50" } })
        }
    }
    // getCode = () => { ////获取验证码
    //     if (!this.state.phone) {
    //         this.setState({ phoneTips: { color: "#f50", text: "手机号码不能为空" } })
    //     } else {
    //         let time = 60;
    //         let timeDo = setInterval(() => {
    //             time--;
    //             this.setState({ codeStatus: time })
    //             if (time === 0) {
    //                 clearInterval(timeDo);
    //             }
    //         }, 100);
    //         if (this.state.phoneTips.color !== '#f50' && this.state.phone.length === 11) {

    //             request('post', '/mall/shopUser/send', {
    //                 phone: this.state.phone
    //             }).then((res) => {
    //                 console.log("获取验证码接口=>", res);
    //                 if (res.code === 0) {
    //                     console.log("验证码:", res.data);
    //                     this.setState({
    //                         codeData: res.data
    //                     })
    //                     //    console.log(res.data);
    //                     message.success('验证码发送成功');
    //                 } else {
    //                     message.warning("验证码发送失败,请稍后再次发送")
    //                 }
    //             })
    //         } else {
    //             message.warning("手机号码错误,请稍重新输入手机号码后再次发送")
    //         }
    //     }
    // }
    passwordModify = () => {///提交修改
      //console.log(this.state);
        let newPassword = this.state.passwords
        let oldPassword = this.state.oldPassword
        let shopId = cookie.load('shopId')
        
        if (newPassword && oldPassword && shopId ) {
           
            request("post", "/mall/shopUser/changePsd", {
                shopId,
                newPassword,
                oldPassword,
                
            }).then(res => {
              //console.log('修改密码=>', res);
                if (res.code === 200) {
                    message.success('密码修改成功, 即将重新登录...')
                    setTimeout(() => {
                        window.location.replace('/login')

                        // this.props.history.push("/login")
                    }, 2000);
                } else {
                    message.warning('密码修改失败, 请输入正确的信息再再试')
                }
            })
        } else {
            message.warning('请输入合法的账号和密码')
            if (!this.state.user) {
                this.setState({ userTips: { color: "#f50", text: "不能为空" } })
            }
            if (!this.state.password) {
                this.setState({ passwordTips: { color: "#f50", text: "不能为空" } })
            }
            if (!this.state.passwords) {
                this.setState({ passwordsTips: { color: "#f50", text: "不能为空" } })
            }
            if (!this.state.oldPassword) {
                this.setState({ oldPasswordTips: { color: "#f50", text: "不能为空" } })
            }
        }
    }
    toPasswordModify = () => {
        this.props.callback(true)

    }
    render() {
        return (
            <div className="main-show order-list">
                <div className="table-header">
                    <h1 className="table-name">密码修改</h1>
                    <div className="table-operation">
                        <Button onClick={this.toPasswordModify} ghost type='primary' >验证码修改</Button>
                    </div>
                </div>
                <div className="form">
                    <div className="form-item">
                        <div className="form-item-top">
                            <span className="form-item-title">操作员账号：</span>
                            <input className="form-item-input" placeholder="请输入操作员账号" onChange={(e) => this.userInput(e)} />
                        </div>
                        <div className="form-item-tips" >
                            <Badge color={this.state.userTips.color} text="" />
                            <span style={{ color: this.state.userTips.color }}>{this.state.userTips.text}</span>
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="form-item-top">
                            <span className="form-item-title">旧密码：</span>
                            <input className="form-item-input" placeholder="请输入您的旧密码" type="password" onChange={(e) => this.oldPasswordInput(e)} />
                        </div>
                        <div className="form-item-tips">
                            <Badge color={this.state.oldPasswordTips.color} text="" />
                            <span style={{ color: this.state.oldPasswordTips.color }}>{this.state.oldPasswordTips.text}</span>
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="form-item-top">
                            <span className="form-item-title">新密码：</span>
                            <input className="form-item-input" placeholder="请输入您要修改的新密码" type="password" onChange={(e) => this.passwordInput(e)} />
                        </div>
                        <div className="form-item-tips">
                            <Badge color={this.state.passwordTips.color} text="" />
                            <span style={{ color: this.state.passwordTips.color }}>{this.state.passwordTips.text}</span>
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="form-item-top">
                            <span className="form-item-title">确认新密码：</span>
                            <input className="form-item-input" placeholder="请再次输入您要修改的新密码" type="password" onChange={(e) => this.passwordsInput(e)} />
                        </div>
                        <div className="form-item-tips">
                            <Badge color={this.state.passwordsTips.color} text="" />
                            <span style={{ color: this.state.passwordsTips.color }}>{this.state.passwordsTips.text}</span>
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="form-item-top">
                            <span className="form-item-title"> </span>
                            <Button type="primary" className="passwordModify" onClick={this.passwordModify}>确认重置</Button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
