import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'


import SY from '../homepage'

import DDLB from '../pages/dingdan/dingdan'
import TKSQ from '../pages/dingdan/tuikuan'
import THSQ from '../pages/dingdan/tuihuo'
import PJLB from '../pages/dingdan/pingjia'

import SPFL from '../pages/shangpin/fenlei'
import SPGG from '../pages/shangpin/guige'
import CKSP from '../pages/shangpin/cangku'
import PTSP from '../pages/shangpin/pingtai'

import FH from '../pages/wuliu/fahuo'
import THGL from '../pages/wuliu/tuihuodizhi'

import YHHD from '../pages/cuxiao/huodong'
import YHLB from '../pages/cuxiao/youhui'

import SJFX from '../pages/shuju/shuju'

import DPJY from '../pages/zijin/dianpu'
import WDBZJ from '../pages/zijin/wode'

import XXSZ from '../pages/kefu/shezhi'
import KFGJ from '../pages/kefu/gongju'
import ZXKF from '../pages/kefu/kefu'

import DPXX from '../pages/dianpu/xinxi'
import RZZL from '../pages/dianpu/ziliao'

import KHGL from '../pages/kehu/kehu'

import WDCG from '../pages/cuohe/caigou'
import WDGY from '../pages/cuohe/gongying'
import LKCZ from '../pages/cuohe/chuzu'
import LKQZ from '../pages/cuohe/qiuzu'

import HQLB from '../pages/wode/hangqing'

import CCSP from '../pages/zhineng/cangchu'

import XTSZ from '../pages/shezhi/shezhi'

import "./index.css"

export default class MyRoute extends Component {
    constructor(props) {
        super(props)
        this.state = {
            path:'rrrrrr'
        }
    }
    componentDidMount(){
        // this.props.callback(this.state.path)
        // console.log(this.props);
        // this.getRoutePath(this.state.path)
    }
    getRoutePath=(value)=>{
        if(value){

            // console.log('路由页接收',value);
            // console.log(this.props);
            this.props.callback(value)
        }
        
        // console.log(this.callback(value));
    }
    render() {
        const Ddlb =()=> <DDLB callback={this.getRoutePath}/>
        const Tksq =()=> <TKSQ callback={this.getRoutePath}/>
        const Thsq =()=> <THSQ callback={this.getRoutePath}/>
        const Pjlb =()=> <PJLB callback={this.getRoutePath}/>

        const Cksp =()=> <CKSP callback={this.getRoutePath}/>
        
        const Ccsp =()=> <CCSP callback={this.getRoutePath}/>
        
        const Fh =()=> <FH callback={this.getRoutePath}/>
        
        const Sjfx =()=> <SJFX callback={this.getRoutePath}/>

        const Dpjy =()=> <DPJY callback={this.getRoutePath}/>
        
        const Zxkf =()=> <ZXKF callback={this.getRoutePath}/>
        
        const Dpxx =()=> <DPXX callback={this.getRoutePath}/>
        
        const Xtsz =()=> <XTSZ callback={this.getRoutePath}/>
        
        
        return (
            <div>
                <Switch>
                    <Route path="/index/homepage" component={SY} />

                    <Route path="/index/ddlb" component={Ddlb} />
                    <Route path="/index/tklb" component={Tksq} />
                    <Route path="/index/thlb" component={Thsq} />
                    <Route path="/index/pjgl" component={Pjlb} />

                    <Route path="/index/spfl" component={SPFL} />
                    <Route path="/index/spgg" component={SPGG} />
                    <Route path="/index/cksp" component={Cksp} />
                    <Route path="/index/ptsp" component={PTSP} />

                    <Route path="/index/fh" component={Fh} />
                    <Route path="/index/thdz" component={THGL} />

                    <Route path="/index/yhlb" component={YHLB} />
                    <Route path="/index/yhhd" component={YHHD} />

                    <Route path="/index/sjfx" component={Sjfx} />

                    <Route path="/index/dpjy" component={Dpjy} />
                    <Route path="/index/wdbzj" component={WDBZJ} />

                    <Route path="/index/xxsz" component={XXSZ} />
                    <Route path="/index/kfgj" component={KFGJ} />
                    <Route path="/index/zxkf" component={Zxkf} />

                    <Route path="/index/dpxx" component={Dpxx} />
                    <Route path="/index/rzzl" component={RZZL} />

                    <Route path="/index/khgl" component={KHGL} />

                    <Route path="/index/wdcg" component={WDCG} />
                    <Route path="/index/wdgy" component={WDGY} />
                    <Route path="/index/lkcz" component={LKCZ} />
                    <Route path="/index/lkqz" component={LKQZ} />

                    <Route path="/index/hqlb" component={HQLB} />

                    <Route path="/index/ccsp" component={Ccsp} />

                    <Route path="/index/xtsz" component={Xtsz} />

                    <Redirect to="/index/homepage" />
                </Switch>
                {/* <HomePage/> */}
            </div>
        )
    }
}
