import React, { Component, useState } from 'react'
import { Select, Button, Image, Radio, Table, Space, Modal, message, Tag } from 'antd';
import { Link, withRouter } from "react-router-dom";
import { RollbackOutlined } from "@ant-design/icons"
import request from '../../../../../../../../js/http';


import "./index.css"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            goodsId: '',
            goodsList: [],
            goodsDetail: {},
            imgList: []
        }
    }
    componentDidMount() {
        this.props.callback(this.props.location.pathname)

        if (this.props.location.state) {
            let id = this.props.location.state.goodsId;
            // let detail = list.filter(item => {
            //     return item.goodsId === id
            // })
            // this.setState({ goodsDetail: detail[0],imgList:[{ "src": detail[0].goodsImg }] })
            // console.log(detail[0]);
            // let image = []
            // image[0] = { "src": detail[0].goodsImg }
            // // image = image.map(item=>{
            // //     return item.key = item.src
            // // })

            // this.setState({ imgList: image })
            this.getGoodsDetail(id)

        }

        //     console.log(this.state.goodsList , '-------------/////////');
    }
    getGoodsDetail(goodsId) {
        request("get", `/mall/goodsGoods/${goodsId}`, {

        }).then(res => {
          //console.log('根据商品id获取商品详情信息=>', res);
            if (res.code === 200) {
                this.setState({ data: res.data })
            }
        })
    }
    back = () => {
        // this.props.history.goBack();
        window.location.replace('/index/cksp');

        // console.log(this.props);
    }

    render() {
        ///////////////删除商品对话框//////////
        const Apps = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
                // console.log('shopId:',this.state.goodsDetail.shopId, "goodsId:",this.state.goodsDetail.goodsId);
                request('post', 'mall/goodsGoods/remove', {
                    shopId: this.state.goodsDetail.shopId,
                    goodsId: this.state.goodsDetail.goodsId
                }).then(res => {
                    if (res.code === 200) {
                        message.success('删除成功')
                        setTimeout(() => {

                            window.location.replace('/index/cksp');
                        }, 1000);
                        // this.props.history.goBack();

                    } else {
                        message.worning('操作失败')
                    }
                })
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <Button className="w100" onClick={showModal} >删除</Button>
                    {/* <span >删除</span> */}
                    <Modal className="modal-confirm"
                        title="提示" cancelText="取消"
                        okText="确认"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <p className="confirm-text">确定要删除吗?</p>
                    </Modal>
                </>
            );
        };
        //////////////是否包邮组件
        const FreePast = () => {
            // const setDefult = (e) => {
            //     console.log(e.target.value);
            //     data.recomXq = e.target.value
            // }
            return (
                <Radio.Group name="radiogroup" disabled defaultValue={this.state.data.recomXq} >
                    <Radio value={0}>是</Radio>
                    <Radio value={1}>否</Radio>
                </Radio.Group>
            )
        };
        ///////////图片组件/////////
        const ImageDemo = ({ src, fallback }) => {
            return (
                <Image
                    width={100}
                    src={src}
                    fallback={fallback}
                />
            );
        }
        ///////////////时间格式转换函数//////////
        function newTime(ordTime) {
            var date = new Date(ordTime);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = date.getDate() + ' ';
            let h = date.getHours() + ':';
            let m = date.getMinutes() + ':';
            let s = date.getSeconds();
            return Y + M + D + h + m + s;
        }
        const { Option } = Select;
        const handleChange = value => {
          //console.log(`selected ${value}`);
        }

        const Table1 = () => {
            /////表 字段////////
            const columns = [
                { title: '单价(元)', dataIndex: 'productPrice', },
                { title: '单位', dataIndex: 'productUnit', },
                { title: '单位净重', dataIndex: 'productWeight', },
                { title: '库存', dataIndex: 'productStock', },
                { title: '销量', dataIndex: 'productSaleNum', },
                { title: '创建时间', dataIndex: 'createAt', },
                {
                    title: '默认', dataIndex: 'productDefault', render: (text, record) => {
                        return (
                            <Space size="middle">
                                {text === 0 ? <Tag color={'red'}>否</Tag> : <Tag color={'cyan'} >是</Tag>}
                            </Space>
                        )
                    },
                },
            ]
            //////表 数据////
            let datas = []
            let data
            if (this.state.data.goodsProduct) {
                datas.push(this.state.data.goodsProduct)
                data = datas.map(item => {
                    // item.key = num
                    // num ++
                    return item
                })
            }
            return (<Table dataSource={data} columns={columns} pagination={false} />)
        }
        // const Table2 = () => {
        //     /////表 字段////////
        //     const columns = [
        //         { title: '单价', dataIndex: 'productPrice', key: "key" },
        //         { title: '单位', dataIndex: 'productUnit', key: "key" },
        //         { title: '单位净重', dataIndex: 'productWeight', key: "key" },
        //         { title: '库存', dataIndex: 'productStock', key: "key" },
        //         { title: '销量', dataIndex: 'productSaleNum', key: "key" },
        //         { title: '创建时间', dataIndex: 'createAt', key: "key" },
        //         {
        //             title: '默认', dataIndex: 'productDefault', key: "productDefault", render: (text, record) => {
        //                 return (
        //                     <Space size="middle">
        //                         {text === 0 ? <Tag color={'red'}>否</Tag> : <Tag color={'cyan'} >是</Tag>}
        //                     </Space>
        //                 )
        //             },
        //         },
        //     ]
        //     //////表 数据////
        //     let datas, data;
        //     if (this.state.data.productList) {

        //         datas = this.state.data.productList
        //         data = datas.map(item => {
        //             item.key = item.productId
        //             return item
        //         })
        //     }
        //     return (<Table dataSource={data} columns={columns} pagination={false} />)
        // }
        // const App = () => (
        //     <Radio.Group name="radiogroup" disabled defaultValue={1}>
        //         <Radio value={1}>是</Radio>
        //         <Radio value={2}>否</Radio>
        //     </Radio.Group>
        // );
        const App2 = () => (
            <Radio.Group name="radiogroup" disabled defaultValue={this.state.data.goodsLoading}>
                <Radio value={0}>待编辑</Radio>
                <Radio value={1}>上架</Radio>
                <Radio value={2}>下架</Radio>
                <Radio value={3}>审核中</Radio>
                <Radio value={3}>已拒绝</Radio>
            </Radio.Group>
        );
        return (
            <div className="main-show order-list">
                <div className="table-header">
                    <h1 className="table-name">商品信息</h1>
                    <div className="table-operation">
                        <Button icon={<RollbackOutlined />} onClick={this.back} >返回</Button>
                    </div>
                </div>
                <div className="pd20">
                    <Button type="primary" ><Link to="/index/cksp/add">添加</Link></Button>
                    <Button className="ml50" onClick={this.back} >选择仓库商品</Button>

                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">标题 :</span>
                        <span className="goodsinfo-text">{this.state.data.goodsName}</span>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">副标题 :</span>
                        <span className="goodsinfo-text">{this.state.data.goodsTitle}</span>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">上架时间 :</span>
                        <span className="goodsinfo-text">{this.state.data.loadingAt ? newTime(this.state.data.loadingAt) : ''}</span>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品销量 :</span>
                        <span className="goodsinfo-text">{this.state.data.goodsSaleNum}</span>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品数量 :</span>
                        <span className="goodsinfo-text">{this.state.data.productNum}</span>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品轮播图 :</span>
                        <div className="goodsinfo-imgs-area">
                            {
                                this.state.data.bannerList ? (this.state.data.bannerList.map(item => {
                                    return (
                                        <div className="goodsinfo-imgs" key={item.imageId}>
                                            <ImageDemo src={item.imgurl} />
                                        </div>
                                    )
                                })) : (<div className="goodsinfo-imgs">

                                </div>)
                            }

                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品详情图 :</span>
                        <div className="goodsinfo-imgs-area">
                            {
                                this.state.data.detailImgList ? (this.state.data.detailImgList.map(item => {
                                    return (
                                        <div className="goodsinfo-imgs" key={item.imageId}>
                                            <ImageDemo src={item.imgurl} />
                                        </div>
                                    )
                                })) : (<div className="goodsinfo-imgs"> </div>)
                            }

                        </div>
                    </div>
                </div>
                {/* <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品详情 :</span>
                        <span className="goodsinfo-detail-text goodsinfo-text">
                            {this.state.goodsDetail.goodsTitle}
                        </span>
                    </div>
                </div> */}
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品品种 :</span>
                        <span className="goodsinfo-text">{this.state.data.className}</span>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品等级 :</span>
                        <Select defaultValue="a" style={{ width: 200, height: 35 }} onChange={handleChange}>
                            <Option value="a">{this.state.data.goodsProp}</Option>
                        </Select>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="">商品上架状态 :</span>
                        <div className="ml50">
                            <App2 />
                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="" style={{ width: 91, display: "inline-block" }}>是否包邮 :</span>
                        <div className="ml50">
                            <FreePast />
                        </div>
                    </div>
                </div>
                {/* <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">发货地 :</span>
                        <span className="goodsinfo-text">河南省 郑州市 二七区</span>
                    </div>
                </div> */}
                {/* <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">单位 :</span>
                        <Select defaultValue="a1" style={{ width: 200, height: 35 }} onChange={handleChange}>
                            <Option value="a1">斤</Option>
                            <Option value="b1">公斤</Option>
                            <Option value="c1">袋</Option>

                        </Select>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">单位(规格) :</span>
                        <Select defaultValue="a3" style={{ width: 200, height: 35 }} onChange={handleChange}>
                            <Option value="b3">20斤/袋</Option>
                            <Option value="a3">40斤/袋</Option>
                            <Option value="d3">20公斤/袋</Option>
                            <Option value="c3">40公斤/袋</Option>
                        </Select>
                    </div>
                </div> */}
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品 :</span>
                        <div className="past-model">
                            <Table1 />
                        </div>
                    </div>
                </div>
                {/* <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">更多 :</span>
                        <div className="past-model">
                            <Table2 />
                        </div>
                    </div>
                </div> */}
                {/* <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="">订单确认后才能支付 :</span>
                        <div className="ml50">
                            <App />
                        </div>
                    </div>
                </div> */}
                {/* <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">虚拟库存量 :</span>
                        <span className="goodsinfo-text">360</span>
                    </div>
                </div> */}
                {/* <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">售后须知 :</span>
                        <span className="goodsinfo-detail-text goodsinfo-text">
                            一经出售,概不退换
                        </span>
                    </div>
                </div> */}
                <div className="pd20 df-jcc">
                    <Apps />
                    {/* <Button type="primary" className="w100" onClick={this.back}>取消</Button> */}
                    <Button type="primary" className="w100" onClick={this.back}>确认</Button>
                </div>
            </div>
        )
    }
}
export default withRouter(Index)
