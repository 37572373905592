import React, { Component, useState } from 'react'
import { Modal,  Table, Button, Space, } from 'antd';
import request from '../../../../../../../../js/http';
import cookie from 'react-cookies';
// import moment from 'moment';
import Close from '../../../../dingdan/dingdan/components/detail/close.png'
import Warning from '../../../../dingdan/dingdan/components/detail/warning.png'
import "./index.css"

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            memberInfo: {},
            memberInfos: {},
            memberOrderList: [],
            selectedRowKeys: [],
            loading: true

        }
    }
    componentDidMount() {
        this.setState({ memberInfos: this.props.location.state.memberInfos })
        // console.log(this.props.location.state);
        this.getMemberInfo()
        this.getMemberOrderList()
    }

    getMemberInfo() {
        if (this.props.location.state.memberId && cookie.load('shopId')) {

            request("post", '/mall/orderOrder/member/memberInfo', {
                shopId: cookie.load('shopId'),
                toMemberId: this.props.location.state.memberId
            }).then(res => {
              //console.log('客户管理-客户详情-客户详情=>', res);
                if (res.code === 0) {
                    this.setState({ memberInfo: res.data })
                }
            })
        }
    }

    newTime(ordTime) {
        var date = new Date(ordTime);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = date.getDate() + ' ';
        let h = date.getHours() + ':';
        let m = date.getMinutes() + ':';
        let s = date.getSeconds();
        return Y + M + D + h + m + s;
    }
    getMemberOrderList() {
        let shopId = cookie.load('shopId'), toMemberId = this.props.location.state.memberId;
        if (shopId && toMemberId) {

            request("post", '/mall/orderOrder/member/orderList', {
                shopId,
                toMemberId,
                pageNum: 1,
                pageSize: 99,
            }).then(res => {
              //console.log('客户管理-客户详情-客户订单列表=>', res);
                if (res.code === 0) {
                   let newList =  res.data.list.map(item => {
                        item.createAt = this.newTime(item.createAt)
                        item.key = item.orderSn
                        return item
                    })
                    this.setState({ loading: false, memberOrderList: newList })
                }
            })
        }
    }
    //关闭当前页返回上一页 
    exit = () => {
        window.history.back(-1);
    }
    ///////表格勾选
    state = {
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
    };
    onSelectChange = selectedRowKeys => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    //--------------------------------------------------------------
    render() {
        // 标签tags 点击关闭回调
        // function log(e) {
        //   //console.log(e);
        // }
        ///////////////取消订单对话框//////////
        const App = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <span onClick={showModal}>删除</span>
                    <Modal className="modal-confirm"
                        title="提示" cancelText="取消"
                        okText="确认"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <p className="confirm-text">确定要删除吗?</p>
                    </Modal>
                </>
            );
        };

        /////表格 字段///
        const toOrderDetail = e =>{
            let orderId = e.orderId
            // console.log(orderId);
            this.props.history.push({ pathname: '/index/ddlb/detail', state: { orderId } })
        }
        const columns = [
            { title: '订单编号', dataIndex: 'orderSn', key: 'orderSn', },
            { title: '订单金额/元', dataIndex: 'orderPrice', key: 'orderPrice', },
            // { title: '订单状态', dataIndex: 'orderNum', key: 'orderNum', },
            { title: '提交时间', dataIndex: 'createAt', key: 'createAt', },
            {
                title: '操作', render: (text, record) => (
                    <Space size="middle " className="link pointer" onClick={()=>toOrderDetail(text)}>查看</Space>
                ),
            },
        ];
        /////表格   数据//////
        const data = this.state.memberOrderList

        // const data = [];
        // for (let i = 0; i < 6; i++) {
        //     data.push({
        //         key: i,
        //         memberName: `Edward King ${i}`,
        //         memberPhone: 32,
        //         orderNum: `London, Park Lane no. ${i}`,
        //         allMoney: "London"
        //     });
        // }
        /////表格勾选
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div className="main-show">
                <div className="detail-header " style={{ justifyContent: "flex-start" }} >
                    <img className="img-close" src={Close} onClick={this.exit} alt="关闭图片加载失败" />
                    <h1 className="detail-title">编辑</h1>
                </div>
                <div className="detail-main">
                    <div className="order-status dflex">
                        <div className="detail-item">
                            <p className="detail-item-titl">上次交易时间</p>
                            <p className="detail-item-value"></p>
                        </div>
                        <div className="detail-item">
                            <p className="detail-item-titl">订单总金额</p>
                            <p className="detail-item-value">￥{this.state.memberInfo.allNum}</p>
                        </div>
                        <div className="detail-item">
                            <p className="detail-item-titl">累计订单数</p>
                            <p className="detail-item-value">{this.state.memberInfo.num} 笔</p>
                        </div>
                        <div className="detail-item">
                            <p className="detail-item-titl">累计退款金额</p>
                            <p className="detail-item-value">￥{this.state.memberInfo.refundPrice}</p>
                        </div>
                        <div className="detail-item">
                            <p className="detail-item-titl">累计退款订单数</p>
                            <p className="detail-item-value">{this.state.memberInfo.refundNum}</p>
                        </div>
                    </div>
                    {/* { */}
                    {/* // this.state.dingdanInfo.map((item, index) => ( */}
                    <div className="scard order-info" key={index}>
                        <div className="scard-title">基本信息</div>
                        <div className="scard-content">
                            <div className="content-left">
                                <img className="head-img" style={{ borderRadius: "50%" }} src={this.state.memberInfo.memberAvator ? this.state.memberInfo.memberAvator : Warning} alt="客户头像" />
                                <div className="content-lleft">
                                    <span className="">会员名称：{this.state.memberInfo.memberName}{/*item.orderSn*/}</span>
                                    <span >性别：{/*item.detailList[0].goodsName*/}</span>
                                    <span >手机号：{this.state.memberInfo.memberPhone}</span>
                                    <span >微信号：{/*item.detailList[0].productWeight*/}</span>
                                </div>
                                <div className="content-lright content-lleft">
                                    <span className="">加入时间：{/*moment(item.createAt).format('YYYY-MM-DD HH:mm:ss')*/}</span>
                                    <span>上次登录时间：</span>
                                    <span>收货地址：{/*item.detailList[0].price}/{/*item.detailList[0].productUnit*/}</span>
                                    <span>来源：{/*item.orderPrice*/}</span>
                                </div>
                                <div className="content-lright content-lleft">
                                    <span className="">备注：{/*moment(item.createAt).format('YYYY-MM-DD HH:mm:ss')*/}</span>

                                </div>
                            </div>

                        </div>

                    </div>
                    {/* } */}
                    {/* <div className="scard border-none ">
                        <div className="scard-title">
                            标签信息
                        </div>
                        <div className="scard-content">
                            <div className="content-left">
                                <Tag closable color="blue" onClose={log}>子弹头</Tag>
                                <Tag closable color="blue" onClose={log}>子弹头</Tag>
                                <Tag closable color="blue" onClose={log}>子弹头</Tag>
                                <Tag closable color="blue" onClose={log}>子弹头</Tag>
                            </div>

                        </div>
                    </div> */}
                    <div className="scard  border-none">
                        <div className="scard-title ">
                            历史订单
                        </div>
                        <div className="scard-content fdcol">
                            <Table
                                style={{ width: "100%", borderTop: "1px solid #eee" }}
                                rowSelection={rowSelection}
                                columns={columns}
                                dataSource={data}
                                pagination={{
                                    showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                                    total: this.state.memberOrderList.length,
                                    showTotal: total => `共 ${total} 条记录`,
                                    defaultCurrent: 1,
                                    showSizeChanger: false
                                }}
                            />
                            <div className="table-foot ">
                                <div className="foot-left">
                                    {/* <Button className="table-import" type="text"><App/></Button> */}
                                    <Button className="table-export" ghost type="primary"><App /></Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
