import React, { Component } from 'react'
import { Tag, Space, Table, Select, Button, DatePicker, ConfigProvider, Tabs, message, Badge } from 'antd';
import { Link, withRouter } from "react-router-dom";

import cookie from 'react-cookies';
import request from '../../../../../../js/http';
// import { RedoOutlined, DownloadOutlined, PlusOutlined } from "@ant-design/icons"

import TotalIncome from '../img/totalIncome.png';
import TotalOfPayment from '../img/totalOfPayment.png';
import DayIncome from '../img/dayIncome.png';
import "./index.css"
import Code from './code.jpg'

let timeEnd = '', timeStart = '', startAt = '', endAt = '';

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            refundsList: [],
            goodsId: '',
            loading: true,
            loading2: true,
            data: {},
            defaultStatus: 0, ///筛选的类型/状态
            list: [],
            topData: {},
            timeStart: '',
            timeEnd: '',
            withdrawList: [],
            getMoney: 2,
            tabsKey: '1',

            showBuilding: false,//显示绑定/改绑支付宝
            money: 0,  ///提现的金额
            password: '', ///密码
            passwordTips: {},
            // disabled: true, ///禁用提交
            code: '',////验证码
            sendBtn: true, //////发送验证吗按钮的状态
            times: '', ///验证码倒计时




        }
    }


    componentDidMount() {
        this.props.callback(this.props.location.pathname)

        this.getTopData()
        this.getTradingList(1, 44, 0)
        this.getWithDrawList(1, 9999)
    }
    getMoney = () => {
        this.setState({ getMoney: 1 })
    }
    getWithDrawList(num, size) {
        let shopId = cookie.load("shopId")
        let pageNum = num
        let pageSize = size
        request('post', '/mall/shopWithdraw/queryList', {
            shopId, pageNum, pageSize
        }).then(res => {
          //console.log('获取店铺提现记录=>', res);
            if (res.code === 200) {
                // if(res.data.total)
                this.setState({ loading2: false, withdrawList: res.data.list })
            }

        })
    }
    getTopData(time1, time2) {
        let shopId = cookie.load("shopId")
        let option
        if (time1 && time2) {
            option = { shopId, startAt: time1, endAt: time2 }

        } else {
            option = { shopId }
        }
        if (shopId) {

            request('post', '/mall/orderOrder/order/chargeMoney', option).then(res => {
              //console.log('店铺交易上方金额=>', res);
                if (res.code === 0) {

                    this.setState({ topData: res.data })
                }
            })
        }
    }
    getTradingList = (pageNum, pageSize, type,) => {
      //console.log(type);
        this.setState({ loading: true })
        let shopId = cookie.load("shopId")
        if (shopId && pageNum && pageSize) {
            request("post", "/mall/orderOrder/order/chargeList", {
                shopId,
                pageNum: 1,
                pageSize: 10,
                type,
            }).then(res => {
              //console.log("店铺交易列表=>", res);
                if (res.code === 0) {
                    this.setState({ data: res.data, loading: false, list: res.data.list, defaultStatus: type })
                } else {
                    this.setState({ loading: false })

                }

            })
        }
    }
    newTime(ordTime) {
        var date = new Date(ordTime);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = date.getDate() + ' ';
        let h = date.getHours() + ':';
        let m = date.getMinutes() + ':';
        let s = date.getSeconds();
        return Y + M + D + h + m + s;
    }
    setKey = () => {
        this.setState({ getMoney: 2, showBuilding: false })
    }
    onChange = (date, dateString) => {
      //console.log(date, dateString, dateString[0], dateString[1]);
        timeStart = new Date(dateString[0]).getTime()
        timeEnd = new Date(dateString[1]).getTime()
        startAt = dateString[0]
        endAt = dateString[1]

    }
    select = () => {
        let list = this.state.data.list.filter(item => {
            return (item.payAt >= timeStart && item.payAt <= timeEnd)
        })
        this.setState({ list, })
      //console.log(timeStart, timeEnd);
        this.getTopData(startAt, endAt)
    }
    reset = () => {
        this.setState({ list: this.state.data.list })

    }
    ////控制绑定二维码的是否显示
    changeBuilding = () => {
        this.setState({ showBuilding: true })
    }
    ////提现金额的输入
    moneyInput = e => {
        let money = Number(e.target.value)
        if (money <= this.state.topData.money) {
            if (money >= 0) {

                this.setState({ money })
            } else {
                message.warning('请输入正确的提现金额')
            }
        } else {
            message.warning('提现金额不得超过可提现金额')
        }
    }
    ////密码输入
    passwordInput = e => {
        let password = e.target.value
        if (/^\d{6}$/.test(password)) {
            this.setState({ password, passwordTips: { text: "格式正确", color: "#87d068" } })
        } else {
            if (!password) {
                this.setState({ passwordTips: { text: "此为必填项", color: "#f50" } })
            } else {
                this.setState({ passwordTips: { text: "验证码格式错误", color: "#f50" } })
            }
        }
    }
    ///提现前检测
    check = () => {
        this.setState({ sendBtn: false, times: 60 })
        let time = 60;
        let timeDo = setInterval(() => {
            time--;
            this.setState({ times: time })
            if (time === 0) {
                clearInterval(timeDo);
                this.setState({ sendBtn: true, password: '', })
            }
        }, 1000);
        request('post', '/mall/shopUser/send', {
            phone: cookie.load('userInfo').phone
        }).then((res) => {
          //console.log('发送验证码=>', res);
            if (res.code === 0) {
                this.setState({
                    code: res.data

                })
                message.success('验证码发送成功');

            } else {
                message.warning("验证码发送失败,请稍后再次发送")
            }
        })
    }
    ///全部提现
    allmoney = () => {
        this.setState({ money: this.state.topData.money })
      //console.log(this.state.money);
    }
    ////忘记密码
    // passwordReset = () => {
    //     // console.log(this.props);
    //     this.props.history.push('/index/xtsz')

    // }
    ///提交提现
    submit = () => {

        let shopId = cookie.load('shopId')
        let money = this.state.money
        let type = 0
        // if(shopId && money){
        if ((this.state.password === this.state.code) && this.state.password.length === 6) {

            request('post', '/mall/shopWithdraw/shopAdd', {
                shopId, money, type,
            }).then(res => {
                this.setState({ code: '', password: '', passwordTips: {} })
                let codes = document.getElementById('code')
                codes.value = ''
              //console.log('店铺提现申请=>', res);
                if (res.code === 200) {
                    message.success('提现申请成功,请注意查收')
                } else {
                    message.warning(res.msg)
                }
            })
        } else {
            message.warning('请核对您的验证码')
        }
        // }else{
        //     message.warning('请输入正确的提现金额')
        // }
    }
    render() {
        ///////////// 标签页
        const { TabPane } = Tabs;
        const callback = (key) => {
            // console.log(typeof key);
            this.setState({ tabsKey: key })
        }
        //表格组件
        const TB = () => {
            /////表格 字段///
            const columns = [
                { title: '订单号', dataIndex: 'orderSn', },
                { title: '买家', dataIndex: 'memberName', },
                { title: '买家电话 ', dataIndex: 'memberPhone', },
                { title: '邮费', dataIndex: 'orderPost', },
                { title: '订单原价', dataIndex: 'orderPrice', },
                { title: '实付金额', dataIndex: 'payment', },
                {
                    title: '支付时间', dataIndex: 'payAt', render: (text, record) => {
                        return (text ? this.newTime(text) : '')
                    }
                },
                {
                    title: '状态', dataIndex: 'status', render: (text, record) => {
                        switch (text) {
                            // case 1:
                            //     return <Tag color="magenta">待发货</Tag>
                            case 2:
                                return <Tag color="volcano">待收货</Tag>
                            case 3:
                                return <Tag color="gold">待评价</Tag>
                            case 4:
                                return <Tag color="green">待退款</Tag>
                            case 5:
                                return <Tag color="orange">已退款</Tag>
                            case 6:
                                return <Tag color="cyan">已完成</Tag>
                            default:
                                break;
                        }

                    },
                },
                {
                    title: '操作', dataIndex: 'goodsId', render: (text, record) => (
                        <Space size="middle">
                            {/* <Button onClick={(e) => (this.passIdToView(record.goodsId))} ><Link to={{ pathname: "/index/ptsp/detail", state: { goodsList: this.state.goodsList, goodsId: record.goodsId } }}>查看</Link></Button> */}
                            <Link to={{ pathname: "/index/ddlb/detail", state: { orderId: record.orderId, from: "/index/dpjy" } }}>详情</Link>
                        </Space>
                    ),
                },
            ];
            const columns2 = [
                {
                    title: '提现时间', dataIndex: 'createAt', render: (text, record) => {
                        return (text ? this.newTime(text) : '')
                    }
                },
                { title: '提现单号', dataIndex: 'shopWithdrawId', },
                { title: '提现方式 ', dataIndex: 'withType', render: (text) => (<span>{text === 0 ? '支付宝' : '微信'}</span>) },
                { title: '支付宝账号 ', dataIndex: 'bankAccount', },
                { title: '提现金额', dataIndex: 'allMoney', render: (text) => (<span>￥{text}</span>) },
                { title: '手续费', dataIndex: 'moneyRatio', render: (text) => (<span>￥{text}</span>) },
                // {
                //     title: '支付时间', dataIndex: 'payAt', key: 'payAt', render: (text, record) => {
                //         return (text ? this.newTime(text) : '')
                //     }
                // },
                {
                    title: '提现状态', dataIndex: 'status', render: (text, record) => {
                        switch (text) {
                            case 2:
                                return <Tag color="volcano">已拒绝</Tag>
                            case 0:
                                return <Tag color="orange">审核中</Tag>
                            case 1:
                                return <Tag color="cyan">已成功</Tag>
                            default:
                                break;
                        }

                    },
                },
            ];
            /////表格   数据//////
            // let newList = this.state.goodsList.map(item => {
            //     item.key = item.goodsId;
            //     return item
            // })
            // const data = newList
            let data, datas;
            if (this.state.list) {
                datas = this.state.list;
                data = datas.map((item, index) => {
                    item.key = index
                    return item
                })
            }
            let data2, datas2;
            if (this.state.list) {
                datas2 = this.state.withdrawList;
                data2 = datas2.map((item, index) => {

                    item.key = index
                    return item
                })
            }
            // console.log(data2);

            /////表格勾选
            // const { selectedRowKeys } = this.state;
            // const rowSelection = {
            //     selectedRowKeys,
            //     onChange: this.onSelectChange,
            // };
            ////////下拉选择组件部分
            const { Option } = Select;
            // const checkStatus = value => {
            //     let list = this.state.data.list.filter(item => {
            //         return item.status === value
            //     })
            //     this.setState({ list, })
            // }
            const handleChange = value => {
              //console.log(`selected ${value}`);
                // this.setState({ defaultStatus: value })
                this.getTradingList(1, 44, value)
                // switch (value) {
                //     case 0:
                // this.getTradingList(1, 44, value)
                //     case 0:
                //         this.getTradingList(1, 44, value)

                //         break;

                //     default:
                //         break;
                // }
                // if (value === 0) {
                //     // this.setState({ list: this.state.data.list })
                //     this.getTradingList(1, 44, value)

                // } else {
                //     // checkStatus(value)
                //     this.getTradingList(1, 44, value)
                // }

            }
            const { RangePicker } = DatePicker;

            return (
                <div className="main-show order-list">
                    <div className="table-header">
                        <h1 className="table-name">交易明细</h1>

                        <div className="table-operation">

                            {this.state.tabsKey === '1' ? <div className="order-state ml10">
                                <span >状态 :</span>
                                <Select defaultValue={this.state.defaultStatus} style={{ width: 100, height: 35, marginBottom: -3 }} onChange={handleChange}>
                                    <Option value={0}>全部</Option>
                                    {/* <Option value={1}>待发货</Option> */}
                                    <Option value={2}>待收货</Option>
                                    <Option value={3}>待评价</Option>
                                    <Option value={4}>待退款</Option>
                                    <Option value={5}>已退款</Option>
                                    <Option value={6}>已完成</Option>

                                </Select>
                            </div> : ''}
                            <div className="order-state ml10">
                                <ConfigProvider >
                                    <RangePicker onChange={this.onChange} />
                                </ConfigProvider>
                            </div>
                            <Button onClick={this.select} type='primary' ghost >确定</Button>

                            <Button onClick={this.reset} type='primary' className='ml10' >重置</Button>
                        </div>
                    </div>
                    <div className="table-content" style={{ paddingTop: 0 }}>
                        <Tabs defaultActiveKey={this.state.tabsKey} onChange={callback} size={'large'}>
                            <TabPane tab="收入" key="1">
                                <Table columns={columns}
                                    dataSource={data}
                                    loading={this.state.loading}
                                    pagination={{
                                        showQuickJumper: { goButton: <Button className="ml10" type="primary" >GO</Button> },
                                        // total: data.length,
                                        showTotal: total => `共 ${total} 条记录`,
                                        defaultCurrent: 1,
                                        showSizeChanger: false
                                    }}
                                    // rowSelection={rowSelection}
                                    rowSelection={false}
                                />
                                <div className="table-foot">
                                    <div className="foot-left">
                                        {/* <Button className="table-import" type="text">删除</Button> */}
                                        {/* <Button className="table-export" ghost type="primary">导出</Button> */}
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tab="支出" key="2">
                                <Table columns={columns2}
                                    dataSource={data2}
                                    loading={this.state.loading2}
                                    pagination={{
                                        showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                                        // total: data.length,
                                        showTotal: total => `共 ${total} 条记录`,
                                        defaultCurrent: 1,
                                        showSizeChanger: false
                                    }}
                                    rowSelection={false}
                                />
                                <div className="table-foot">
                                    <div className="foot-left">
                                        {/* <Button className="table-import" type="text">删除</Button> */}
                                        {/* <Button className="table-export" ghost type="primary">导出</Button> */}
                                    </div>
                                </div>
                            </TabPane>

                        </Tabs>

                    </div>
                </div>
            )
        }
        ///////主要结构
        const Trade = () => {
            return (
                <div>
                    {/*/////三个卡片区域 */}
                    <div className="trade-statistical">
                        <div className="trade-item">
                            <div className="left-color color-blue"></div>
                            <div className="item-show">
                                <img className="item-show-img" src={TotalIncome} alt="累计收益" />
                                <p className="item-num" > {this.state.topData.allMoney ? this.state.topData.allMoney : 0}</p>
                                <p className="item-title-note">累计收益（截至今日0点）</p>
                            </div>
                        </div>
                        <div className="trade-item m0-5">
                            <div className="left-color color-orange"></div>
                            <div className="item-show">
                                <Button shape="round" type="primary" style={{ alignSelf: "flex-end", marginRight: 20, marginBottom: -20 }} onClick={this.getMoney}>提现</Button>
                                <img className="item-show-img" src={DayIncome} alt="今日收益" />
                                <p className="item-num" > {this.state.topData.money ? this.state.topData.money : 0}</p>
                                <p className="item-title-note">已结算金额(可提现)</p>
                            </div>
                        </div>
                        <div className="trade-item">
                            <div className="left-color color-red"></div>
                            <div className="item-show">
                                <img className="item-show-img" src={TotalOfPayment} alt="累计收益" />
                                <p className="item-num" > {this.state.topData.settlMoney ? this.state.topData.settlMoney : 0}</p>
                                <p className="item-title-note">待结算总额（截至今日0点）</p>
                            </div>
                        </div>
                    </div>
                    {/* ////表部分 */}
                    <TB />
                </div>
            )
        }
        return (
            <>
                {

                    this.state.getMoney === 1
                        ?
                        <div className="main-show order-list">
                            <div className="table-header">
                                <h1 className="table-name">提现申请</h1>
                                <div className="table-operation">
                                    <Button onClick={this.setKey} type='primary' ghost className='ml10' >返回</Button>
                                </div>

                            </div>
                            <div className="table-content" style={{ height: 700 }}>
                                {
                                    !this.state.showBuilding
                                        ?

                                        // /* 提现 */
                                        <div style={{ paddingLeft: 70, paddingRight: 70 }}>
                                            <p className="title-withdraw-info">您的可用金额：<span className="colors">{this.state.topData.money} </span> 元 <span className="pointer colorr" onClick={this.setKey}>查看收入明细</span></p>
                                            <p className="title-withdraw-info">姓名： <span></span></p>
                                            <p className="title-withdraw-info mb40">用户名：{cookie.load('userInfo').name}  <span></span></p>

                                            <p className="operation" > <span className="title-left">提现至：</span> <span className="num">  {this.state.topData.aliAccount ? this.state.topData.aliAccount : '未绑定支付宝'} </span>{this.state.topData.aliAccount ? '支付宝账号不正确?' : ''}  <span className="change pointer" onClick={this.changeBuilding}>{this.state.topData.aliAccount ? "点击换绑" : '去绑定'}</span> </p>
                                            <p className="operation" > <span className="title-left">提现金额：</span> <input className="money-input num" onBlur={this.moneyInput} type='number' defaultValue={this.state.money > 0 ? this.state.money : ''} />  <Button className="withdraw-all" type="primary" onClick={this.allmoney}>全部提现</Button> 满10元可提现，本次最多可提现 {this.state.topData.money} 元 </p>
                                            <p className="operation" > <span className="title-left">验证码：</span> <input id="code" className="num " onChange={this.passwordInput} type='' defaultValue={this.state.password} />     {this.state.sendBtn ? <Button className="get-code" type="primary " onClick={this.check}>发送验证码</Button> : <Button className="get-code" type="primary " disabled>{this.state.times}秒后再次发送</Button>}<span onClick={this.passwordReset} className="change pointer"></span>  </p>
                                            <p className="operation" > <span className="title-left"> </span>
                                                <span className="ml10" >
                                                    <Badge color={this.state.passwordTips.color} text="" />
                                                    <span style={{ color: this.state.passwordTips.color }}>{this.state.passwordTips.text}</span>
                                                </span></p>
                                            <p className="operation" > <span className="title-left"> </span> <span className="num submit"><Button className="withdraw-btn" onClick={this.submit} type="primary" >确认提交</Button></span></p>
                                        </div>
                                        :
                                        // 去app绑定支付宝 /换绑
                                        <div style={{ paddingLeft: 70, paddingRight: 70 }}>
                                            <p style={{ display: 'flex', alignItems: "center", justifyContent: "flex-start", marginBottom: 40 }}>
                                                提现至:
                                                <span
                                                    style={{ margin: "0 10px", width: 400, height: 40, backgroundColor: '#f9f9f9', border: '1px solid #eee', display: 'inline-block', fontSize: 16, lineHeight: 2.5, paddingLeft: 20 }}
                                                >
                                                    支付宝  {this.state.topData.aliAccount ? + this.state.topData.aliAccount : ''}
                                                </span>
                                                {this.state.topData.aliAccount ? '支付宝账号不正确?' : "未绑定支付宝"}
                                            </p>
                                            <div style={{ margin: '0 auto', width: 300 }}>
                                                <p style={{ textAlign: 'center', fontSize: 20, fontWeight: 600, color: '#000' }}>{this.state.topData.aliAccount ? '扫码前往App换绑' : '前往APP绑定收款账号'}</p>
                                                <img src={Code} alt='' />
                                            </div>

                                        </div>
                                }



                            </div>
                        </div>
                        :
                        <Trade />
                }
            </>
        )
    }
}
export default withRouter(Index)

