import React, { Component } from 'react'
import "./index.scss";
import { Button, Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                },
                {
                    uid: '-2',
                    name: 'image.png',
                    status: 'done',
                    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                },
                {
                    uid: '-3',
                    name: 'image.png',
                    status: 'done',
                    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                },
                {
                    uid: '-4',
                    name: 'image.png',
                    status: 'done',
                    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                },
                {
                    uid: '-xxx',
                    percent: 50,
                    name: 'image.png',
                    status: 'uploading',
                    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                },
                {
                    uid: '-5',
                    name: 'image.png',
                    status: 'error',
                },
            ],
        }
    }



    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChange = ({ fileList }) => this.setState({ fileList });
    render() {
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        return (
            <div>
                <div className="gerendianpu">
                    <div className="gerendianpuTop">
                        <div className="gerendianpuTop-lf">
                            <span>企业认证</span>
                        </div>
                    </div>
                    <div className="gerendianpuContent">
                        <div className="gerendianpu-item ">
                            <span style={{ width: '120px', display: 'inline-block' }}>
                                企业法人
                            </span>
                            <input className="dpinput" type="text" placeholder="请输入企业法人姓名" />
                        </div>

                        <div className="gerendianpu-item ">
                            <span style={{ width: '120px', display: 'inline-block' }}>
                                法人身份证
                            </span>
                            <input className="dpinput" type="text" placeholder="请输入法人身份证" />
                        </div>
                        <div className="gerendianpu-item ">
                            <span style={{ width: '120px', display: 'inline-block' }}>
                                企业/组织全称
                            </span>
                            <input className="dpinput" type="text" placeholder="请输入企业/组织全称" />
                        </div>
                        <div className="gerendianpu-item shenfenzheng">
                            <span style={{ width: '120px', display: 'inline-block' }}>
                                机构/社会信用代码
                            </span>
                            <input className="dpinput" type="text" placeholder="请输入机构/社会信用代码" />
                        </div>
                        <div className="gerendianpu-item " style={{ display: "flex" }}>
                            <div style={{ width: '120px', textAlign: "right", lineHeight: '90px' }}>
                                上传证件
                            </div>
                            <div className="upload-fr">
                                <div className="gerendianpu-upload" >
                                    <Upload
                                        style={{ textAlign: 'left' }}
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                    >
                                        {fileList.length >= 8 ? null : uploadButton}
                                    </Upload>
                                    <Modal
                                        visible={previewVisible}
                                        title={previewTitle}
                                        footer={null}
                                        onCancel={this.handleCancel}
                                    >
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </div>
                                <div className="upload-p">
                                    <p><span>*</span>社会团体上传登记证书，企业上传营业执照若办理过三证合一的企业，请上传最新的营业执照</p>
                                    <p><span>*</span>格式要求：原件照片、扫描件或复印件加盖企业公章后的扫描件支持jpg .jpeg .bmp .gif .png格式照片，大小不超过2M。</p>
                                </div>
                            </div>
                        </div>
                        <div className="gerendianpu-item ">
                            <span style={{ width: '120px', display: 'inline-block' }}>
                                联系方式
                            </span>
                            <input className="dpinput" type="text" placeholder="请输入联系方式" />
                        </div>
                    </div>
                    <div className="gerendianpuBottom">
                        <Button type="primary">
                            确认
                            </Button>
                    </div>
                </div>
            </div>
        )
    }
}
